import { createSlice, PayloadAction, } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from '../i18n';
import { api, getJwtToken, setAuthorization, storeJwtToken, StrapiImageData } from '../utils/api';
import { RestaurantType } from './restaurantSlice';



export declare type LocationType = {
    "businessFee": number;
    "city": string;
    "created_at": Date;
    "deliveryFee": number;
    "id": number;
    "lateFee": number;
    "latitude": number;
    "locationImage": StrapiImageData,
    "longDistance": number;
    "longDistanceBracket": number;
    "longDistanceFee": number;
    "longitude": number;
    "onTimeFee": number;
    "published_at": Date;
    "state": string;
    "updated_at": Date;
}


export type UserAddressType = {
    address: string;
    displayAddress: string;
    apartment: string;
    city: string;
    country: string;
    created_at: Date;
    district?: string;
    entrance: string;
    floor: string;
    id: number;
    latitude: string;
    location: LocationType;
    longitude: string;
    main: boolean;
    neighborhood?: string;
    notes: string;
    postal_code?: string;
    published_at: Date;
    state: string;
    street_number?: string;
    updated_at: Date;
    user: number;
}



export type StrapiUserCustomType = {
    "addresses": UserAddressType[];
    "avatar": StrapiImageData;
    "birthday": string;
    "blocked": boolean;
    "confirmed": boolean;
    "created_at": string;
    "email": string;
    "gender": string;
    "id": number;
    "phone": string;
    "provider": "local";
    restaurant_stories_manageds: RestaurantType[];
    "firstName": string;
    "lastName": string;
    "restaurants": RestaurantType[],
    "role": {
        "description": string;
        "id": number;
        "name": string;
        "type": string;
    },
    "updated_at": string;
    "username": string;
  }
  
export type LoginParamsType = {
    identifier: string;
    password: string;
}
export type MemberParamsType = {
    username: string;
    email: string;
    password: string;
    phone: string;
    firstName: string;
    lastName: string;
    gender: string;
    birthday: string;
}
export const login = createAsyncThunk(
    'auth/login',
    async (params: LoginParamsType) => {
        console.log("authSlice login baseURL", api.defaults.baseURL + '/auth/local');
        console.log("authSlice login params", params);
        const response = await api.post(
            '/auth/local',
            params
        );
        console.log('login auth response', response.data);
        return response.data;
    }
);
export const getMe = createAsyncThunk(
    'auth/getMe',
    async () => {
        const response = await api.get(
            '/users/me'
        );
        return response.data;
    }
);

export type LoginResponseType = {

    "avatar": {
        "alternativeText": "",
        "caption": "",
        "created_at": "2021-02-06T07:54:55.902Z",
        "ext": ".jpeg",
        "formats": {
            "large": {
                "ext": ".jpeg",
                "hash": "large_IMG_6034_bb4a2bd93d",
                "height": 763,
                "mime": "image/jpeg",
                "name": "large_IMG_6034.jpeg",
                "path": null,
                "size": 185.2,
                "url": "/uploads/large_IMG_6034_bb4a2bd93d.jpeg",
                "width": 1000,
            },
            "medium": {
                "ext": ".jpeg",
                "hash": "medium_IMG_6034_bb4a2bd93d",
                "height": 573,
                "mime": "image/jpeg",
                "name": "medium_IMG_6034.jpeg",
                "path": null,
                "size": 115.44,
                "url": "/uploads/medium_IMG_6034_bb4a2bd93d.jpeg",
                "width": 750,
            },
            "small": {
                "ext": ".jpeg",
                "hash": "small_IMG_6034_bb4a2bd93d",
                "height": 382,
                "mime": "image/jpeg",
                "name": "small_IMG_6034.jpeg",
                "path": null,
                "size": 54.4,
                "url": "/uploads/small_IMG_6034_bb4a2bd93d.jpeg",
                "width": 500,
            },
            "thumbnail": {
                "ext": ".jpeg",
                "hash": "thumbnail_IMG_6034_bb4a2bd93d",
                "height": 156,
                "mime": "image/jpeg",
                "name": "thumbnail_IMG_6034.jpeg",
                "path": null,
                "size": 9.19,
                "url": "/uploads/thumbnail_IMG_6034_bb4a2bd93d.jpeg",
                "width": 204,
            },
        },
        "hash": "IMG_6034_bb4a2bd93d",
        "height": 1078,
        "id": 26,
        "mime": "image/jpeg",
        "name": "IMG_6034.jpeg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "size": 322.3,
        "updated_at": "2021-02-06T07:54:55.944Z",
        "url": "/uploads/IMG_6034_bb4a2bd93d.jpeg",
        "width": 1412,
    },
    "birthday": "1991-01-06",
    "blocked": null,
    "confirmed": true,
    "created_at": "2021-02-09T01:16:44.225Z",
    "email": "cokilopez@hotmail.com",
    "gender": "male",
    "id": number,
    "phone": "+523121558080",
    "provider": "local",
    "firstName": string;
    "lastName": string;
    "restaurants": RestaurantType[],
    "role": {
        "description": "Default role given to authenticated user.",
        "id": 1,
        "name": "Authenticated",
        "type": "authenticated",
    },
    "updated_at": "2021-02-09T01:16:44.256Z",
    "username": "cokilopez@hotmail.com",
}

export const createMember = createAsyncThunk(
    'auth/create',
    async (params: MemberParamsType) => {
        console.log("authSlice login baseURL", api.defaults.baseURL + '/auth/local/register');
        console.log("authSlice login params", params);
        const response = await api.post(
            '/auth/local/register',
            params
        );
        console.log('create auth response', response.data);
        return response.data;
    }
);

type InitialType = {
    jwt: string | null | undefined,
    code : number;
    authenticating: boolean;
    signUp: {
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        Birthday: "",
        Gender: "",
        Password: ""
    },
    creating: boolean;
    user?: LoginResponseType | null;
    loginError: string;
    allowNotifications: boolean;
}

const jwtToken = getJwtToken();

if(jwtToken){
    setAuthorization(jwtToken)
}

const initialState: InitialType = {
    jwt: jwtToken,
    authenticating: false,
    code : 200,
    signUp: {
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        Birthday: "",
        Gender: "",
        Password: ""
    },
    creating: false,
    user: null,
    loginError: "",
    allowNotifications: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setSignUpFirstName: (state, action) => {
            state.signUp.FirstName = action.payload
        },
        setSignUpLastName: (state, action) => {
            state.signUp.LastName = action.payload
        },
        setSignUpEmail: (state, action) => {
            state.signUp.Email = action.payload
        },
        setSignUpPhone: (state, action) => {
            state.signUp.Phone = action.payload
        },
        setSignUpBirthday: (state, action) => {
            state.signUp.Birthday = action.payload
        },
        setSignUpGender: (state, action) => {
            state.signUp.Gender = action.payload
        },
        setSignUpPassword: (state, action) => {
            state.signUp.Password = action.payload
        },
        setUserData: (state, action: PayloadAction<{ jwt?: string, user?: LoginResponseType | null }>) => {
            state.jwt = action.payload.jwt
            state.user = action.payload.user
        },
        clearLoginError: (state) => {
            state.loginError = ""
        },
        allowNotifications: (state) => {
            state.allowNotifications = true;
        }
    },
    extraReducers: (builder) => {
        //Fecth device info
        builder.addCase(login.pending, (state) => {
            state.authenticating = true;
            state.loginError = ""
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.authenticating = false;
            state.jwt = action.payload.jwt
            state.user = action.payload.user
            storeJwtToken(action.payload.jwt)
            setAuthorization(action.payload.jwt)

        });
        builder.addCase(login.rejected, (state, action) => {
            state.authenticating = false;
            console.log("invalid login", action.error.message)
            state.loginError = i18n.t("invalid-login")

        });
        //Get Me
        builder.addCase(getMe.pending, (state) => {
            state.authenticating = true;
            state.loginError = ""
            state.code = 200
        });
        builder.addCase(getMe.fulfilled, (state, action) => {
            state.authenticating = false;
            state.user = action.payload
            state.code = 200

        });
        builder.addCase(getMe.rejected, (state, action) => {
            state.authenticating = false;
            state.code = 401
            console.log("invalid login", action.error.message)
            state.loginError = i18n.t("invalid-login")

        });
        //Fecth device info
        builder.addCase(createMember.pending, (state) => {
            state.creating = true;
        });
        builder.addCase(createMember.fulfilled, (state, action) => {
            state.creating = false;
            state.jwt = action.payload.jwt;
            state.user = action.payload.user
            storeJwtToken(action.payload.jwt)
            setAuthorization(action.payload.jwt)

        });
        builder.addCase(createMember.rejected, (state, action) => {
            state.creating = false;

        });
    }
})

export const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const {
    setSignUpBirthday,
    setSignUpEmail,
    setSignUpFirstName,
    setSignUpLastName,
    setSignUpPhone,
    setSignUpGender,
    setUserData,
    clearLoginError,
    allowNotifications
} = authSlice.actions

export {
    setSignUpBirthday,
    setSignUpEmail,
    setSignUpFirstName,
    setSignUpLastName,
    setSignUpPhone,
    setSignUpGender,
    setUserData,
    clearLoginError,
    allowNotifications
}

export default authSlice
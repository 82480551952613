import React, { useEffect, useRef } from 'react'
import i18n from '../../i18n'
import { ChatMessage, ChatType } from '../../redux/orderSlice'
import { Color } from '../../utils/Colors'
import ChatBubble from './ChatBubble'

let scrollTimeout: any = null

const ChatBody = ({ messages, userId }: { messages: ChatMessage[] | undefined, userId?:number }) => {

    const messagesEndRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {

        if (scrollTimeout) {
            clearTimeout(scrollTimeout)
        }

        scrollTimeout = setTimeout(() => {
            
            messagesEndRef.current?.scrollIntoView({
                behavior: 'smooth'
            });
        }, 500);

    }, [messages?.length])

    if (!messages || !messages.length) {
        return <div style={{
            flex: 1,
            width: "100%",
            display: 'flex',
            flexDirection : 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <h1 style={{
                color: Color.darkGray
            }}>
                {i18n.t("no-messages")}
            </h1>
        </div>
    }




    return (
        <div style={{
            marginTop: 0,
            borderRadius: 5,
            display: 'flex',
            flex : 1,
            paddingBottom: 20,
            alignItems: 'flex-start',
            flexDirection: 'column',
            overflowY: 'scroll',
            padding : 5
        }}>
            {messages.map((message: ChatMessage, key: number) => <ChatBubble userId={userId} key={key} message={message} />)}
            <div ref={messagesEndRef} />
            <style>
                {`
                .bubble-img:hover {
                    width:500px;
                }
            `}
            </style>
        </div>
    )
}

export default ChatBody
import React from 'react'
import { MabaliColors } from '../../utils/Colors';

const PrintButton = ({ text, onPress, }: { text: string; onPress: () => void; }) => {
  return (
    <div onClick={onPress} style={{
      display: 'flex',
      cursor: 'pointer',
      border: `1px solid ${MabaliColors.pink}`,
      padding: 5,
      borderRadius: 10,
      marginLeft: 30,
      marginRight: 30,
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 51,
      marginBottom: 14
    }}>
      <span style={{
        textAlign: 'center',
        fontSize: 14
      }}>{text}</span>
    </div>
  )
}

export default PrintButton

// @ts-nocheck
import i18n from 'i18n-js';
import en from './en.json'
import he from './he.json'

// Set the key-value pairs for the different languages you want to support.
i18n.translations = {
    "en-MX": he,
    "en-US": he,
    "he-IL": he,
    he
};

const defaultLocale = "he"
i18n.defaultLocale = defaultLocale;
i18n.locale = "he"
// i18n.fallbacks = true
// Set the locale once at the beginning of your app.
// i18n.locale = navigator.language || navigator.userLanguage//defaultLocale//Localization.locale;
// i18n.locale = defaultLocale//Localization.locale;

i18n.isRTL = () => {
    return i18n.locale.indexOf("he") >= 0
}

i18n.getLang = () => {
    return 'he'
}
export default i18n
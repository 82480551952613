import React from 'react'
import { ImageType } from '../../../redux/restaurantSlice'
import { api, StrapiImage } from '../../../utils/api'

const ImageCarousel = ({id, images} : {id: string, images : StrapiImage[]}) => {
    
    if(!images || images.length == 0){
        return <img src={"/images/undraw_not_found_60pq.svg"} style={{
            height : 200,
            width : 300
        }}/>
    }

    return (
        <div>
            <div id={id} className="carousel slide" data-bs-ride="carousel" style={{
                width : 500
            }}>
                <div className="carousel-inner" style={{
                    width : 500,
                    height : 400
                }}>
                    {images.map((image, index) => {
                        return <div key={index} className={"carousel-item " + (index == 0 ? "active" : "")} style={{
                           
                        }}>
                        <img src={api.getMedia!(image)} className="d-block w-100" alt="..." style={{
                            objectFit : 'cover',
                            height : 400
                        }}/>
                    </div>
                    })}
                    
                    
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target={"#" + id} data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target={"#" + id} data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
}

export default ImageCarousel

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../configureStore'
import i18n from '../../../i18n'
import { MenuItemType } from '../../../redux/orderSlice'
import { LocationType, RestaurantType } from '../../../redux/restaurantSlice'
import { api } from '../../../utils/api'
import { Color } from '../../../utils/Colors'
import ImageCarousel from './ImageCarousel'

export type FormFileType = {
    lastModified: number;
    lastModifiedDate: string;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
}
const RestaurantConfig = () => {

    const restaurant: RestaurantType = useSelector((state: RootState) => state.restaurant)
    const [Form, setForm] = useState(restaurant)
    const [ImageFile, setImageFile] = useState<Blob | null>(null)
    const [ImageFiles, setImageFiles] = useState<Array<Blob> | Array<null>>([])
    const [RestaurantDetails, setRestaurantDetails] = useState<RestaurantType>()
    const [ChangedRestaurantDetails, setChangedRestaurantDetails] = useState()
    const [Locations, setLocations] = useState([])
    const [SelectedLocation, setSelectedLocation] = useState<any>(restaurant.location ? restaurant.location : "-1")

    useEffect(() => {
        console.log("restaurant --", restaurant)
        if (restaurant.id) {
            getRestaurantDetails()

        }
        getLocations()

    }, [restaurant.id])

    const getRestaurantDetails = () => {
        console.log("getRestaurantDetails")
        api.get("/restaurants/" + restaurant.id)
            .then(({ data }) => {
                setRestaurantDetails(data)
            })
            .catch((e) => {
                console.warn("could not get restaurant details", e)
            })
    }
    const getLocations = () => {
        console.log("getLocations")
        api.get("/locations/")
            .then(({ data }) => {
                setLocations(data)


            })
            .catch((e) => {
                console.warn("could not get locations details", e)
            })
    }


    const handleImage = (e: any, restaurant: RestaurantType) => {
        let event = e
        const file = (e.target.files[0])
        setImageFile(file)
        if (restaurant.id == -1) {
            return
        }
        const formData = new FormData()


        formData.append("files.logo", file)
        formData.append("data", JSON.stringify(restaurant))


        api.put(`/restaurants/${restaurant.id}`, formData)
            .then(({ data }) => {
                console.log("update imagge", data)
                //setDoUpdate((prevVal:number) => prevVal + 1)
                alert("Image Updated")
            }).catch((e) => {
                console.warn("failed image", e)
                setImageFile(null)
                event.target.value = null
                alert("Error updating image")
            })
    }
    const handleMultiImage = (e: any, restaurant: RestaurantType | any, name: string) => {

        //let event = e
        console.log("RestaurantDetails", name)
        restaurant[name] = []
        console.log("handleMultiImage", e.target.files)
        let files: Array<Blob> = e.target.files
        //setImageFiles(files)
        if (restaurant.id == -1) {
            return
        }


        const formData = new FormData()

        for (let index = 0; index < files.length; index++) {
            const file: any = files[index];
            console.log("fileName", file.name)
            formData.append(`files.${name}`, file)

        }
        //formData.append("files.logo", file)
        formData.append("data", JSON.stringify(restaurant))


        api.put(`/restaurants/${restaurant.id}`, formData)
            .then(({ data }) => {
                console.log("update image", data)
                //setDoUpdate((prevVal:number) => prevVal + 1)'
                getRestaurantDetails();
                e.target.value = null;
                alert("Image Updated")
            }).catch((e) => {
                console.warn("failed image", e)

                setImageFiles([])
                // event.target.value = null
                alert("Error updating image")
            })
    }

    const updateRestaurant = () => {
        delete RestaurantDetails?.featured
        delete RestaurantDetails?.photos

        let restaurantObj = Object.assign({}, RestaurantDetails, { location: SelectedLocation });

        api.put(`/restaurants/${restaurant.id}`, restaurantObj)
            .then(({ data }) => {
                console.log("update imagge", data)
                //setDoUpdate((prevVal:number) => prevVal + 1)'
                getRestaurantDetails();
                alert("Restaurant Data Changed")
            }).catch((e) => {
                console.warn("failed image", e)

                setImageFiles([])
                //event.target.value = null
                alert("Error updating restaurant")
            })
    }


    const onFormChange = (e: any) => {
        let tempDetails: any = Object.assign({}, RestaurantDetails);
        console.log("onFormchange", e.target.name, e.target.value, e.target.checked, e.target.type)
        tempDetails[e.target.name] = e.target.type == "checkbox" ? e.target.checked ? true : false : e.target.value
        setRestaurantDetails(tempDetails)
    }
    const onLocationChange = (e: any) => {
        setSelectedLocation(e.target.value)
    }


    return (

        <div className="col-12">
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <img className="img-fluid shadow-lg" style={{
                    height: 200,
                    width: 300,
                    // borderRadius : 10,
                    objectFit: "cover",
                    border: "1px solid #33333330",
                    borderRadius: 4,
                    backgroundColor: Color.lightGray
                }} src={ImageFile ? URL.createObjectURL(ImageFile) : api.getMedia!(RestaurantDetails?.logo)} />
                <br />

                <input type='file' id='single' onChange={(e) => handleImage(e, RestaurantDetails!)} />
            </div>
            <br />
            <form className="row g-3">
                <div className="col-md-6">
                    <label htmlFor="restaurantName" className="form-label">{i18n.t("restaurant-name-label")}</label>
                    <input type="text" name="name" onChange={onFormChange} defaultValue={RestaurantDetails?.name} className="form-control" id="restaurantName" />
                </div>
                <div className="col-md-6">
                    <label htmlFor="instagram" className="form-label">Instagram</label>
                    <input name="instagram" type="text" onChange={onFormChange} defaultValue={RestaurantDetails?.instagram} className="form-control" id="instagram" />
                </div>
                <div className="col-md-6">
                    <label htmlFor="facebook" className="form-label">Facebook</label>
                    <input name="facebook" type="text" onChange={onFormChange} defaultValue={RestaurantDetails?.facebook} className="form-control" id="facebook" />
                </div>
                <div className="col-md-6">
                    <label htmlFor="averageTime" className="form-label">{i18n.t("restaurant-avarage-time-label")}</label>
                    <input name="averageTime" type="text" onChange={onFormChange} defaultValue={RestaurantDetails?.averageTime} className="form-control" id="averageTime" />
                </div>
                <div className="col-md-6">
                    <label htmlFor="address" className="form-label">{i18n.t("address")}</label>
                    <input name="address" type="text" onChange={onFormChange} defaultValue={RestaurantDetails?.address} className="form-control" id="address" />
                </div>
                <div className="col-md-6">
                    <label htmlFor="description" className="form-label">{i18n.t("restaurant-description-label")}</label>
                    <input name={'description'} type="text" onChange={onFormChange} defaultValue={RestaurantDetails?.description} className="form-control" id="description" />
                </div>


                <div className="col-md-6">
                    <label htmlFor="telephone" className="form-label">{i18n.t("phone-number")}</label>
                    <input name={'telephone'} type="text" onChange={onFormChange} defaultValue={RestaurantDetails?.telephone} className="form-control" id="description" />
                </div>

                <div className="col-md-4">
                    <label htmlFor="inputState" className="form-label">{i18n.t("restaurant-citystate-label")}</label>
                    <select id="inputState" name="location" className="form-select" value={SelectedLocation} onChange={onLocationChange}>
                        <option disabled value={"-1"} >{i18n.t("choose-one")}</option>
                        {Locations.map((location: LocationType, key) => {
                            return <option key={key} value={location.id}>{location.city},{location.state}</option>
                        })}
                    </select>
                </div>
                <div className="col-md-2">
                    <label htmlFor="inputZip" className="form-label">{i18n.t("restaurant-zipcode-label")}</label>
                    <input type="text" className="form-control" name="zipcode" onChange={onFormChange} defaultValue={RestaurantDetails?.zipcode} id="inputZip" />
                </div>
                <div className="col-12">
                    <div className="form-check">
                        <input name="kosher" className="form-check-input" type="checkbox" onChange={onFormChange} id="kosher" defaultChecked={RestaurantDetails?.kosher} />
                        <label className="form-check-label" htmlFor="gridCheck">
                            {i18n.t("kosher")}
                        </label>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-check">
                        <label htmlFor="driverNotes" className="form-label">{i18n.t("restaurant-drivernotes-label")}</label>
                        <textarea name={'driverNotes'} onChange={onFormChange} defaultValue={RestaurantDetails?.driverNotes} className="form-control" id="driverNotes" />

                    </div>
                </div>


                <div className="row">
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <br />
                        <h4>{i18n.t("featured-images")}</h4>
                        <ImageCarousel {...{ id: "uploadPictures", images: RestaurantDetails?.featured! }} />
                        <br />

                        <input type='file' multiple id='multi' onChange={(e) => handleMultiImage(e, RestaurantDetails!, "featured")} />
                    </div>
                </div>
                <div className="row">
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <br />
                        <h4>{i18n.t("restaurant-photos")}</h4>
                        <ImageCarousel {...{ id: "uploadPhotos", images: RestaurantDetails?.photos! }} />
                        <br />

                        <input type='file' multiple id='multiPhotos' onChange={(e) => handleMultiImage(e, RestaurantDetails!, "photos")} />
                    </div>
                </div>
                <div className="col-12">
                    <button type="submit" onClick={(e) => {
                        console.log("submitting")
                        e.preventDefault();
                        updateRestaurant();
                    }} className="btn btn-primary">{i18n.t("update-restaurant")}</button>
                </div>
            </form>
        </div>
    )
}

export default RestaurantConfig

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../configureStore'
import i18n from '../../../i18n'
import { RestaurantType } from '../../../redux/restaurantSlice'
import { api } from '../../../utils/api'
import HoursOfOperationModal from '../containers/HoursOfOperationModal'

const TakeoutHoursConfig = () => {

    const restaurant: RestaurantType = useSelector((state: RootState) => state.restaurant)
    const [TakeoutHours, setTakeoutHours] = useState([])
    const [Takeout, setTakeout] = useState(null)

    const apiRoute = "/takeout-hours-of-operations"

    const getTakeoutHours = () => {
        setTakeout(null)
        api.get(apiRoute + "?restaurant=" + restaurant.id)
            .then(({ data }) => {
                setTakeoutHours(data)
            }).catch((e) => {
                console.warn("takeout-hours-operations", e)
            })
    }

    useEffect(() => {
        getTakeoutHours();
        
    }, [])

    const onDelete = (takeout: any) => {
        if(window.confirm("Are you sure you want to delete?")){
            api.delete(apiRoute + "/" + takeout.id)
            .then(({data}) => {
                getTakeoutHours()
            })
            .catch((e) => {
                console.warn("could not delete", e)
            })
        }
    }

    const onEdit = (takeout : any) => {
        console.log("onEdit", takeout)
        setTakeout(takeout)
    }

    const onClose = () => {
        console.log("onClose", Takeout)
        setTakeout(null)
    }

    return (
        <div className="container">

            <table className="table">
                <thead>
                <tr>
                        <th scope="col">{i18n.t("day")}</th>
                        <th scope="col">{i18n.t("start-hour")}</th>
                        <th scope="col">{i18n.t("start-minute")}</th>
                        <th scope="col">{i18n.t("end-hour")}</th>
                        <th scope="col">{i18n.t("end-minute")}</th>
                        <th scope="col">{i18n.t("enabled")}</th>
                        <th scope="col">{i18n.t("options")}</th>
                    </tr>
                </thead>
                <tbody>
                    {TakeoutHours.map((takeout: any, key) => {
                        return <tr key={key}>
                            <td scope="row">{takeout.Day}</td>
                            <td>{takeout.StartHour}</td>
                            <td>{takeout.StartMinute}</td>
                            <td>{takeout.EndHour}</td>
                            <td>{takeout.EndMinute}</td>
                            <td>
                                {takeout.enabled ? "Yes" : "No"}
                            </td>
                            <td>
                                <a href="#" className="btn btn-danger" onClick={(e) => {
                                    e.preventDefault()
                                    onDelete(takeout)
                                    }}>
                                    <img src={"/images/trash-2.svg"} />
                                </a>
                                <a href="#" className="btn btn-primary" style={{
                                    marginLeft : 10,
                                    marginRight : 10
                                }} onClick={(e) => {
                                    e.preventDefault()
                                    onEdit(takeout)
                                    }}>
                                    <img src={"/images/edit-2.svg"} style={{
                                        color : "#fff"
                                    }} />
                                </a>
                            </td>
                        </tr>
                    })}

                </tbody>
            </table>

            <button data-bs-toggle="modal"  data-bs-target="#takeoutModal" className="btn btn-primary btn-lg" style={{
                        
                    }}>
                        {i18n.t("new-hour")}
                    </button>
                    <HoursOfOperationModal {...{id: "takeoutModal", apiRoute,Takeout, onUpdated : getTakeoutHours, onClose}}/>
        </div>
    )
}

export default TakeoutHoursConfig

import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setTimeout } from 'timers'
import { RootState } from '../../configureStore'
import i18n from '../../i18n'
import { checkBusinessClosed, RestaurantType, selectRestaurant } from '../../redux/restaurantSlice'
import { api } from '../../utils/api'
import { MabaliColors } from '../../utils/Colors'
import qs from 'qs'
import { allowNotifications } from '../../redux/authSlice'
import ExpressOrder from '../ExpressOrder'
import { OrdersContext } from '../../providers/OrdersProvider'

let audio = new Audio("/sounds/incoming_notification.mp3")
let timerTimeout: any = null

function isIOSDevice() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}


export const OrdersCounter = () => {
    const orderContext = useContext(OrdersContext);

    const restaurant = useSelector((state: RootState) => state.restaurant)
    const [Timer, setTimer] = useState(0)
    const auth = useSelector((state: RootState) => state.auth)
    useEffect(() => {
        if (timerTimeout) {
            clearTimeout(timerTimeout)
        }
        timerTimeout = setTimeout(() => setTimer(prevVal => prevVal + 1), 10000)
        if (restaurant) {
            orderContext.fetchOrdersCount(restaurant);
        }
        return () => {
            clearTimeout(timerTimeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Timer, restaurant.id])



    if (orderContext.orderCount === 0) {
        return null
    }
    return <span className={"badge bg-danger"}>{orderContext.orderCount}</span>
}

const TopBar = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const restaurant = useSelector((state: RootState) => state.restaurant)
    const auth = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        if (restaurant && restaurant.id)
            dispatch(checkBusinessClosed(restaurant))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurant.id, dispatch])

    useEffect(() => {

        if (!restaurant) {
            const possibleRestaurant = auth.user?.restaurants.shift();
            dispatch(selectRestaurant(possibleRestaurant))
        }
    }, [restaurant]);

    // useEffect(() => {

    //     if (auth.user && auth.user?.restaurants && auth.user?.restaurants.length > 0) {
    //         console.log("auth.user?.restaurants.length", auth.user?.restaurants)
    //         //@ts-ignore
    //         let foundRestaurant: any = auth.user.restaurants[0]
    //         if (foundRestaurant)
    //             dispatch(selectRestaurant(foundRestaurant))
    //     }

    // }, [auth.user?.restaurants])

    return (
        <nav className="navbar navbar-expand-lg navbar-dark" style={{
            backgroundColor: MabaliColors.pink,
            // zIndex: 99999
        }}>
            <div className="container-fluid">
                <div style={{
                    display: 'flex',
                    flex: 1
                }}>
                    <img alt="bolepo-icon" src="/images/appstore.png" style={{
                        height: 30,
                        // width: 50,
                        display: 'flex',
                        alignSelf: 'center',
                        borderRadius: 6

                    }} />
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto  mb-2 mb-lg-0">
                        <li className='nav-item'>

                            <ExpressOrder onCreate={() => {
                                window.location.reload()
                            }} />
                        </li>
                        <li className="nav-item" style={{
                            cursor: 'pointer'
                        }}>
                            <a href="/new-orders" className={"nav-link " + (history.location.pathname.indexOf("/new-orders") >= 0 ? "active" : "")} aria-current="page" >
                                הזמנות חדשות
                                <OrdersCounter />
                            </a>

                        </li>
                        <li className="nav-item" style={{
                            cursor: 'pointer'
                        }}>
                            <a href="/orders-history" className={"nav-link " + (history.location.pathname == "/orders-history" ? "active" : "")} aria-current="page" >
                                הסטורית הזמנות
                            </a>

                        </li>
                        <li className="nav-item" style={{
                            cursor: 'pointer'
                        }}>
                            <a href="/orders" className={"nav-link " + (history.location.pathname == "/orders" ? "active" : "")} aria-current="page">
                                {i18n.t("menu-orders") + " "}
                            </a>

                        </li>
                        {/* <li className="nav-item" style={{
                            cursor : 'pointer'
                        }}>
                            <a className={"nav-link " + (history.location.pathname.indexOf("/menu") >= 0 ? "active" : "")} aria-current="page" onClick={() => history.push("/menu")}>
                                {i18n.t("menu-management")} 
                            </a>
                            
                        </li> */}



                        {/* <li className="nav-item dropdown" style={{
                            cursor : 'pointer'
                        }}>
                            <a className={"nav-link dropdown-toggle " + (history.location.pathname.indexOf("/menu") >= 0 ? "active" : "")}  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {i18n.t("menu-management")}
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className={"dropdown-item " + (history.location.pathname == ("/menu") ? "active" : "")} onClick={() => history.push("/menu")}>{i18n.t("menu-items")}</a></li>
                                <li><a className={"dropdown-item " + (history.location.pathname == ("/menu/categories") ? "active" : "")} onClick={() => history.push("/menu/categories")}>{i18n.t("menu-item-categories")}</a></li>
                            </ul>
                        </li> */}

                        <li className="nav-item" style={{
                            cursor: 'pointer'
                        }}>
                            <a className={"nav-link " + (history.location.pathname.indexOf("/menu") >= 0 ? "active" : "")} aria-current="page" onClick={() => history.push("/menu")}>
                                {i18n.t("menu-management") + " "}
                            </a>

                        </li>

                        <li className="nav-item" style={{
                            cursor: 'pointer'
                        }}>
                            <a className={"nav-link " + (history.location.pathname.indexOf("/config") >= 0 ? "active" : "")} aria-current="page" onClick={() => history.push("/config")}>
                                {i18n.t("menu-config") + " "}
                            </a>

                        </li>
                        {!auth.allowNotifications && isIOSDevice() ? <li style={{

                        }}>
                            <div style={{
                                color: '#333',
                                backgroundColor: '#fff',
                                padding: 5,
                                borderRadius: 4,
                                cursor: 'pointer'
                            }}>
                                <div id="enableSound" onClickCapture={() => {
                                    audio.play();
                                    dispatch(allowNotifications())
                                }}> Enable Sound</div>
                            </div>


                        </li> : null}


                    </ul>
                    <div className="d-flex">

                        <div className="nav-item m-auto" style={{

                        }}>

                            <select value={restaurant ? restaurant.id : "0"} onChange={(e) => {
                                let restaurantId: number = parseInt(e.target.value)
                                let foundRestaurant: RestaurantType | undefined = auth.user?.restaurants.find((res: RestaurantType) => {
                                    return res.id === restaurantId
                                })

                                if (foundRestaurant)
                                    dispatch(selectRestaurant(foundRestaurant))

                            }} className="form-select" aria-label="Default select example">
                                <option disabled value="0">{i18n.t("select-business")}</option>
                                {auth.user?.restaurants?.map((res: RestaurantType, key) => {
                                    return <option key={key} value={res.id}>{res.name}</option>
                                })}
                            </select>
                        </div>
                        <div className=" dropdown" style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className="nav-link dropdown-toggle" style={{
                                margin: 0,
                                padding: 0,
                                color: "#fff",
                                marginLeft: 10,
                                marginRight: 10
                            }} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className="" alt="Avatar" style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 15,
                                    margin: 0,
                                    padding: 0
                                }} src={api.getMedia!(auth.user?.avatar)} />
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/logout">{i18n.t("logout")}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {<style>{`
                    li > .active {
                        font-weight : 800
                    }
                `}</style>}
        </nav>
    )
}

export default TopBar

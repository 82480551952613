import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../configureStore'
import i18n from '../../../i18n'
import { RestaurantType } from '../../../redux/restaurantSlice'
import { api } from '../../../utils/api'
import { Color } from '../../../utils/Colors'
import { MenuParentCategoryType } from '../../MenuCategories/MenuCategories'

const InputField = ({text, onChange, defaultValue} : {text :string, onChange: (e:any) => void, defaultValue : string}) => {

    return <div>
        <span>{text}</span>
        <input className="form-control" onChange={onChange} defaultValue={defaultValue} />
    </div>
}

const InputCheckBox = ({text, onChange, defaultChecked, id} : {text :string, onChange: (e:any) => void, defaultChecked : boolean, id : string}) => {

    return <div className="form-check">
            <input onChange={onChange} defaultChecked={defaultChecked} className="form-check-input" type="checkbox" value="" id={id} />
            <label className="form-check-label" htmlFor={id}>
                {text}
            </label>
        </div>
}

const ParentCategoryForm = ({parentCategory, restaurant, ParentCategories, setDoUpdate} : {parentCategory : MenuParentCategoryType | any, restaurant : RestaurantType, ParentCategories : Array<MenuParentCategoryType>, setDoUpdate : (value:any) => void}) => {
      const [Form, setForm] = useState(parentCategory)
      const [ImageFile, setImageFile] = useState<Blob | null>(null)

    const handleImage = (e : any, parentCategory : MenuParentCategoryType) => {
        const file = (e.target.files[0])

        setImageFile(file)
        if(parentCategory.id == -1){
            return
        }
        const formData = new FormData()

        
        formData.append("files.photo", file)
        formData.append("data", JSON.stringify(parentCategory))
        

        api.put(`/menu-parent-categories/${parentCategory.id}`, formData)
        .then(({data}) => {
            console.log("update imagge", data)
            setDoUpdate((prevVal:number) => prevVal + 1)
            alert("Image Updated")
        }).catch((e) => {
            console.warn("failed image", e)
            alert("Error updating image")
        })
    }

    const saveParentCategory = (parentCategory : any) => {
        delete parentCategory.photo
        api.put(`/menu-parent-categories/${parentCategory.id}`, Form)
        .then(({data}) => {
            console.log("updated Parent Category", data)
            setDoUpdate((prevVal : number) => prevVal + 1)
            
        }).catch((e) => {
            console.warn("failed updating Parent Category", e)
            // alert("Error updating image")
        })
    }

    const createParentCategory = (item : any) => {

        delete item.photo

        const formData = new FormData()
        if(ImageFile){
            formData.append("files.photo", ImageFile)
            
        }

        formData.append("data", JSON.stringify({
            "name": Form.name,
            "description": Form.description,
            "restaurant": restaurant.id,
            "name_he": Form["name_he"],
            "description_he": Form["description_he"]
        }))

        api.post(`/menu-parent-categories`, formData)
        .then(({data}) => {
            console.log("createParentCategory ", data)
            setDoUpdate((prevVal : number) => prevVal + 1)
            
        }).catch((e) => {
            console.warn("failed createParentCategory", e)
            // alert("Error updating image")
        })
        
        
    }

    const onChangeText = (value :string, name:string) => {
        let tempForm : any = Object.assign({}, Form)
        tempForm[name] = value
        setForm(tempForm)
    }

    const deleteParentCategory = (parentCategory :MenuParentCategoryType) => {
        if(window.confirm(i18n.t("confirm-delete"))){
            api.delete(`/menu-parent-categories/${parentCategory.id}`)
            .then(({data}) => {
                console.log("deleteParentCategory ", data)
                setDoUpdate((prevVal : number) => prevVal + 1)
                
            }).catch((e) => {
                console.warn("failed deleteParentCategory", e)
                // alert("Error updating image")
            })
        }
        
        
    }


      return <div style={{
          display : 'flex',
          flexDirection : 'column'
      }}>
          <img className="img-fluid" style={{
                height : 100,
                width : 100,
                backgroundColor : Color.darkGray
            }} src={ImageFile ? URL.createObjectURL(ImageFile) : api.getMedia!(parentCategory.photo)} />
        <input type='file' id='multi' onChange={(e) => handleImage(e, parentCategory)} />
        <br />
        <span>{i18n.t("parent-category-form-name")}</span>
        <input className="form-control" onChange={(e) => onChangeText(e.target.value, "name")} defaultValue={parentCategory.name} />
        <span>{i18n.t("parent-category-form-name-he")}</span>
        <input className="form-control" onChange={(e) => onChangeText(e.target.value, "name_he")} defaultValue={parentCategory.name_he} />
        <span>{i18n.t("parent-category-form-order")}</span>
        <input className="form-control" onChange={(e) => onChangeText(e.target.value, "order")} defaultValue={parentCategory.order} />
        <span>{i18n.t("parent-category-form-description")}</span>
        <textarea className="form-control" onChange={(e) => onChangeText(e.target.value, "description")} defaultValue={parentCategory.description} />
        <span>{i18n.t("parent-category-form-description-he")}</span>
        <textarea className="form-control" onChange={(e) => onChangeText(e.target.value, "description_he")} defaultValue={parentCategory.description_he} />

        <button style={{
            marginTop : 20
        }} onClick={() => parentCategory.id == -1 ? createParentCategory(parentCategory) : saveParentCategory(parentCategory)} className="btn btn-primary">{i18n.t("save")}</button>

        {parentCategory.id != -1 && <button style={{
            marginTop : 20,
            marginLeft : 20,
            marginRight : 20
        }} onClick={() => deleteParentCategory(parentCategory)} className="btn btn-danger">{i18n.t("delete")}</button>}
      </div>
  }

  export default ParentCategoryForm
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { RootState } from '../../configureStore'
import i18n from '../../i18n'
import { OrderType } from '../../redux/orderSlice'
import { Color, MabaliColors } from '../../utils/Colors'

const ChatTopOrder = ({ order }: { order: OrderType | undefined }) => {
  const restaurant = useSelector((state: RootState) => state.restaurant);
  const history = useHistory();

 

  
  return (
    <div className='shadow' style={{
      height: 70,
      width: "100%",
      padding: 10,
      display: 'flex',
      flexDirection: 'row',
      position : 'relative',
      backgroundColor: MabaliColors.pink
    }}>
      {restaurant?.id ? <Button style={{
        height: 50,
        position : 'absolute',
        left : 10,
        backgroundColor: Color.green
      }} onClick={() => {
        history.push("/orders/" + order?.id)
      }}>
        {i18n.t('view-order')}
      </Button> : null}

      <div style={{
        flex: 1,
        display : 'flex',
        alignItems: 'center',
        justifyContent : 'center'
      }}>
        <span style={{
          fontWeight: 800,
          fontSize: 20,
          textAlign: 'center',
          color : "#fff"

        }}>
          {order?.name}
        </span>
      </div>

    </div>
  )
}

export default ChatTopOrder
import React, { useState } from 'react'
import { MabaliColors } from '../../utils/Colors'

const MinuteSelection = ({ onPress }: { onPress: (value: number) => void }) => {
  const minutes = [5, 10, 15, 20, 25, 30, 35, 40];
  const [SelectedMinute, setSelectedMinute] = useState<number>();


  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      width: "100%",
      justifyContent: 'space-between',
      paddingTop: 5
    }}>
      {minutes.map((minute, index) => {
        const Selected = SelectedMinute === minute;
        return <div
          key={index + 1000}
          onClick={() => {
            setSelectedMinute(minute)
            onPress(minute)
          }}
          style={{
            height: 65,
            width: 65,
            marginLeft: 1,
            marginRight: 1,
            display: 'flex',
            marginBottom: 2,
            border: `1px solid ${MabaliColors.pink}`,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Selected ? MabaliColors.pink : 'transparent'
          }}>
          <span style={{
            fontFamily: Selected ? 'Bold' : 'Regular',
            fontSize: 20
          }}>{minute}</span>
        </div>
      })}
    </div>
  )
}

export default MinuteSelection
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import i18n from '../../../i18n'
import { api } from '../../../utils/api'
import OptionalItem from './OptionalItem'


const Optional = ({ optional, item, refresh }: { optional: any, item: any, refresh: any }) => {
    const [Name, setName] = useState(optional.name || "")
    const [NameHe, setNameHe] = useState(optional.name_he || "")
    const [Order, setOrder] = useState(optional.order || "0")
    const [Min, setMin] = useState(optional.minimum || "0")
    const [Max, setMax] = useState(optional.maximum || "1")
    const [PosID, setPosID] = useState(optional.pos_id || "")
    const [Mandatory, setMandatory] = useState(optional.isMandatory || false)
    const [Multichoice, setMultichoice] = useState(optional.isMultichoice || false)
    const [OptionalItems, setOptionalItems] = useState<any[]>([])
    const [Loading, setLoading] = useState(false)
    const history = useHistory()

    const getOptionalItems = () => {
        if (optional.id)
            api.get("/menu-optional-items?menu_optional=" + optional.id + "")
                .then(({ data }) => {
                    console.log("got menu-extras", data)
                    let tempExtras: any[] = []
                    tempExtras.push({

                    })
                    tempExtras = data.concat(tempExtras);
                    // console.log("concatendated data", data)
                    setOptionalItems(tempExtras)
                })
                .catch(e => {
                    console.warn("could not getExtras", e);

                })
    }



    const createOptional = () => {
        setLoading(true)
        api.post("/menu-optionals", {
            name: Name,
            name_he: NameHe,
            order: parseInt(Order),
            minimum: Min,
            maximum: Max,
            pos_id: PosID,
            isMandatory: Mandatory,
            isMultichoice: Multichoice,
            restaurant: item.restaurant.id,
            menu_item: item.id
        }).then(({ data }) => {
            resetState()
            refresh && refresh()
            alert(i18n.t("successfully"))
            //history.goBack()
        })
            .catch((e) => {
                console.log("e", e.response.data)
                if (e.response.data.statusCode == 500)
                    alert(i18n.t("duplicated-entry"))
                else
                    alert(i18n.t("successfully"))
            })
            .then(() => {
                setLoading(false)
            })
    }

    const updatOptional = () => {
        setLoading(true)
        api.put("/menu-optionals/" + optional.id, {
            name: Name,
            name_he: NameHe,
            order: parseInt(Order),
            minimum: Min,
            maximum: Max,
            pos_id: PosID,
            isMultichoice: Multichoice,
            isMandatory: Mandatory,
        }).then(({ data }) => {
            refresh && refresh()
            alert(i18n.t("successfully"))
            //history.goBack()
        })
            .catch((e) => {

            })
            .then(() => {
                setLoading(false)
            })
    }

    const resetState = () => {
        setName("")
        setNameHe("")
        setOrder("")
    }
    const deleteOptional = () => {
        api.delete("/menu-optionals/" + optional.id).then(({ data }) => {

            refresh && refresh()
            resetState()
            //history.goBack()
        })
    }

    useEffect(() => {
        if (optional.id)
            getOptionalItems()
        else
            setOptionalItems([])

    }, [optional.id])

    useEffect(() => {

        if (!Mandatory) {
            setMin("0")
        } else if (Min == "0") {
            setMin("1")
        }
    }, [Mandatory])

    const fieldStyle: any = {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 10,
        marginRight: 10
    }

    return <div style={{
        display: 'flex',
        flexDirection: 'column'
    }}>
        <div className="" style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: "100%"
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flex: 1
            }}>
                <div style={fieldStyle}>
                    <span>{i18n.t("optional-name")}</span>
                    <input type="text" defaultValue={Name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div style={fieldStyle}>
                    <span>{i18n.t("optional-name-he")}</span>
                    <input type="text" defaultValue={NameHe} onChange={(e) => setNameHe(e.target.value)} />
                </div>
                <div style={fieldStyle}>
                    <span>{i18n.t("optional-order")}</span>
                    <input type="text" style={{
                        width: 40
                    }} defaultValue={Order} onChange={(e) => setOrder(e.target.value)} />
                </div>
                <div style={fieldStyle}>
                    <span>{i18n.t("optional-minimum")}</span>
                    <input disabled={!Mandatory} type="text" style={{
                        width: 40
                    }} value={Min} onChange={(e) => setMin(e.target.value)} />
                </div>
                <div style={fieldStyle}>
                    <span>{i18n.t("optional-maximum")}</span>
                    <input type="text" style={{
                        width: 40
                    }} defaultValue={Max} onChange={(e) => setMax(e.target.value)} />
                </div>
                <div style={fieldStyle}>
                    <span>{i18n.t("pos-id")}</span>
                    <input type="text" style={{
                        width: 50
                    }} defaultValue={PosID} onChange={(e) => setPosID(e.target.value)} />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 10,
                    marginRight: 10
                }}>
                    <span>{i18n.t("optional-mandatory")}</span>
                    <input type="checkbox" defaultChecked={Mandatory} onChange={(e) => setMandatory(e.target.checked)} />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 10,
                    marginRight: 10
                }}>
                    <span>{i18n.t("optional-multichoice")}</span>
                    <input type="checkbox" defaultChecked={Multichoice} onChange={(e) => setMultichoice(e.target.checked)} />
                </div>
            </div>

            {!Loading ? <div style={{
            }}>
                {optional.id && <button className="btn btn-danger" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deleteOptional()}>{i18n.t("delete")}</button>}
                <button disabled={Loading} className="btn btn-primary" onClick={() => optional.id ? updatOptional() : createOptional()}>{i18n.t(optional.id ? "save" : "add")}</button>
            </div> : <div className="spinner-border" role="status">
                <span className="visually-hidden">...</span>
            </div>}



        </div>
        <div style={{
            marginLeft: 50,
            marginRight: 50,
            display: 'flex',
            flexDirection: 'column'
        }}>
            {OptionalItems.map((optionalItem, key) => {
                return <OptionalItem key={key} optionalItem={optionalItem} maxMin={Multichoice} optional={optional} item={item} refresh={getOptionalItems} />
            })}

        </div>
    </div>
}

export default Optional

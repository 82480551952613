
import React, { useEffect, useState } from 'react'
import SearchBarWithAutocomplete from '../../containers/SearchBarAutoComplete';
import i18n from '../../i18n'
import { LocationType } from '../../redux/restaurantSlice';
import { api } from '../../utils/api';
import LocationSelect from '../../containers/LocationSelect/LocationSelect';
import { GoogleAutoCompleteResult, GoogleAutoCompleteType } from '../../containers/SearchBarAutoComplete/SearchBarAutoComplete.type';
import { Truthy } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import { OrderType } from '../../redux/orderSlice';
import { MabaliColors } from '../../utils/Colors';

let modalView: any = null
const ExpressOrder = ({onCreate} : {onCreate : (order: OrderType) => void}) => {

    const [Notes, setNotes] = useState("");
    const [Location, setLocation] = useState<LocationType | null>(null)
    const [TextAddres, setTextAddres] = useState("")
    const [Locations, setLocations] = useState([])
    const [fetchingLocations, setFetchingLocations] = useState(true);
    const [GeoAddress, setGeoAddress] = useState({})
    const restaurant = useSelector((state: RootState) => state.restaurant);

    const [CreatingOrder, setCreatingOrder] = useState<boolean>(false);

    const [coordinates, setCoordinates] = useState({
        latitude: 0,
        longitude: 0
    })

    const buildAddressObj = (result: GoogleAutoCompleteResult) => {
        let tmpGeoAddress: any = {}
        result.address_components.forEach(component => {
            component.types.forEach(type => {
                console.log("buildAddressObj", type, component.long_name)
                switch (type) {
                    case "street_number":
                        tmpGeoAddress["street_number"] = component.long_name
                        break;
                    case "route":
                        tmpGeoAddress["address"] = component.long_name
                        break;
                    case "neighborhood":
                        tmpGeoAddress["neighborhood"] = component.long_name
                        break;
                    case "locality":
                        tmpGeoAddress["city"] = component.long_name
                        break;
                    case "administrative_area_level_1":
                        tmpGeoAddress["state"] = component.long_name
                        break;
                    case "country":
                        tmpGeoAddress["country"] = component.long_name
                        break;
                    case "postal_code":
                        tmpGeoAddress["postal_code"] = component.long_name
                        break;
                }
            })
        })
        setGeoAddress(tmpGeoAddress)
    }

    const createExpressOrder = () => {
        setCreatingOrder(true)
        api.post("/orders-express", {
            name: 'Express',
            addressObj: {
                ...GeoAddress,
                ...coordinates
            },
            restaurant: restaurant.id,
            location: Location?.id,
            expressNotes: Notes,
            preparationTime: 0
        })
            .then(({ data }) => {
                console.log('created express order', data)
                modalView.hide()
                onCreate && onCreate(data)

            })
            .catch((e) => {
                console.warn('could not create express order', e);

            })
            .then(() => {
                setCreatingOrder(false)
            });
    }


    const handleSetCoordinates = (lat: number, lng: number) => {
        console.log("handleSetCoordinates", lat, lng)
        setCoordinates({
            ...coordinates,
            latitude: lat,
            longitude: lng
        })
    }

    useEffect(() => {
        //@ts-ignore
        modalView = new bootstrap.Modal(document.getElementById('express-order'), {})
    }, [])


    let validForm = false


    validForm = coordinates.latitude && coordinates.longitude && Location && Truthy(GeoAddress) ? true : false




    return (
        <div style={{
            zIndex: 99999
        }}>
            <button onClick={() => modalView.show()} type="button" style={{
                border:'1px solid ' + MabaliColors.white,
                marginLeft : 10,
                marginRight : 10
            }} className="btn">{'הזמן שליח'}</button>
            <div className="modal" id="express-order" tabIndex={-1}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">משלוח אקספרס</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <LocationSelect onLocationChange={(location) => {
                                    setLocation(location)
                                }} />
                            </div>
                            {Location ? <form>

                                <div className="mb-3">
                                    <SearchBarWithAutocomplete
                                        location={Location?.latitude + "," + Location?.longitude}
                                        lang={"he"}
                                        radius={`20000`}
                                        publicMode={false}
                                        strictBounds={true}
                                        onHandleAddress={(address, detail) => {
                                            console.log("onHandleAddress", address, detail)
                                            buildAddressObj(detail.result)
                                            setTextAddres(address)

                                            const { geometry: { location } } = detail.result
                                            const { lat, lng } = location
                                            handleSetCoordinates(lat, lng)
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="driverNotes" className="form-label">הערות לשליח</label>
                                    <textarea onChange={({ target: {
                                        value: text
                                    } }) => setNotes(text)} className="form-control" id="driverNotes" aria-describedby="emailHelp" />
                                </div>


                            </form> : null}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => modalView.hide()}>סגור</button>
                            <button disabled={!validForm || CreatingOrder} onClick={createExpressOrder} type="button" className="btn btn-primary">{CreatingOrder ? <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> : 'שלח'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ExpressOrder
import React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import i18n from '../../../i18n'
import { api } from '../../../utils/api'


const ExtraItem = ({ extra, item, refresh }: { extra: any, item: any, refresh: any }) => {
    const [Name, setName] = useState(extra.name || "")
    const [NameHe, setNameHe] = useState(extra.name_he || "")
    const [PriceChange, setPriceChange] = useState(extra.priceChange || "0")
    const [Order, setOrder] = useState(extra.order || "0")
    const [PosID, setPosID] = useState(extra.pos_id || "")
    const [Optional, setOptional] = useState(extra.Optional != undefined ? extra.Optional : true)
    const history = useHistory()
    const [Loading, setLoading] = useState(false)


    const createExtra = () => {
        // if(PriceChange ==  "" && PriceChange == undefined && PriceChange == null){
        //     alert("")
        //     return
        // }
        setLoading(true)
        api.post("/menu-extras", {
            name: Name,
            name_he: NameHe,
            priceChange: PriceChange,
            order: parseInt(Order),
            Optional: Optional,
            pos_id: PosID,
            restaurant: item.restaurant.id,
            menu_item: item.id
        }).then(({ data }) => {
            refresh && refresh()
            setName("")
            setNameHe("")
            setPriceChange("0")
            setOrder("0")
            setOptional(false)
            alert(i18n.t("successfully"))
            //history.goBack()
        })
            .then(() => {
                setLoading(false)
            })
    }

    const updatExtra = () => {
        setLoading(true)
        api.put("/menu-extras/" + extra.id, {
            name: Name,
            name_he: NameHe,
            priceChange: PriceChange,
            order: parseInt(Order),
            Optional: Optional,
            pos_id: PosID,
        }).then(({ data }) => {
            refresh && refresh()
            alert(i18n.t("successfully"))
            //history.goBack()
        })
            .then(() => {
                setLoading(false)
            })
    }
    const deleteExtra = () => {
        setLoading(true)
        api.delete("/menu-extras/" + extra.id)
        .then(({ data }) => {
            refresh && refresh()
            //history.goBack()
        })
            .then(() => {
                setLoading(false)
            })
    }

    const fieldStyle: any = {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 10,
        marginRight: 10
    }

    return <div className="" style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: "100%"
    }}>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1
        }}>
            <div style={fieldStyle}>
                <span>{i18n.t("extra-name")}</span>
                <input type="text" defaultValue={Name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div style={fieldStyle}>
                <span>{i18n.t("extra-name-he")}</span>
                <input type="text" defaultValue={NameHe} onChange={(e) => setNameHe(e.target.value)} />
            </div>
            <div style={fieldStyle}>
                <span>{i18n.t("extra-price-change")}</span>
                <input type="text" style={{
                    width: 50
                }} defaultValue={PriceChange} onChange={(e) => setPriceChange(e.target.value)} />
            </div>
            <div style={fieldStyle}>
                <span>{i18n.t("pos-id")}</span>
                <input type="text" style={{
                    width: 50
                }} defaultValue={PosID} onChange={(e) => setPosID(e.target.value)} />
            </div>
            <div style={fieldStyle}>
                <span>{i18n.t("extra-order")}</span>
                <input type="text" style={{
                    width: 50
                }} defaultValue={Order} onChange={(e) => setOrder(e.target.value)} />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 10,
                marginRight: 10
            }}>
                <span>{i18n.t("extra-optional")}</span>
                <input type="checkbox" defaultChecked={Optional} onChange={(e) => setOptional(e.target.checked)} />
            </div>
        </div>

        {!Loading ? <div style={{
            // flex : 1
            // display: 'flex',
            // justifyContent: 'center'
        }}>
            {extra.id && <button className="btn btn-danger" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deleteExtra()}>{i18n.t("delete")}</button>}
            <button className="btn btn-primary" onClick={() => extra.id ? updatExtra() : createExtra()}>{i18n.t(extra.id ? "save" : "add")}</button>
        </div> :  <div className="spinner-border" role="status">
                <span className="visually-hidden">...</span>
            </div>}



    </div>
}

export default ExtraItem

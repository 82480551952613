import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../configureStore'
import i18n from '../../i18n'
import { api } from '../../utils/api'
import { Color } from '../../utils/Colors'
import ItemForm from './containers/ItemForm'
import { MenuItemType } from '../../redux/orderSlice'
import { MenuCategory, MenuParentCategoryType } from '../MenuCategories/MenuCategories'
import { useHistory } from 'react-router-dom'


export const ImageChanger = ({small, item, onUpdate, onImage }: { small? : any, item: any, onUpdate: any, onImage: any }) => {

    const [ImageFile, setImageFile] = useState<Blob | null>(null)
    const handleImage = (e: any, item: any) => {
        const file = (e.target.files[0])

        let tempItem = Object.assign({}, item);
        tempItem.restaurant = tempItem.restaurant.id
        setImageFile(file)
        if (item.id == -1) {
            return
        }
        const formData = new FormData()


        formData.append("files.photo", file)
        formData.append("data", JSON.stringify(tempItem))


        api.put(`/menu-items/${item.id}`, formData)
            .then(({ data }) => {
                console.log("update imagge", data)
                onUpdate((prevVal: number) => prevVal + 1)
                alert("Image Updated")
            }).catch((e) => {
                console.warn("failed image", e)
                alert("Error updating image")
            })
    }

    useEffect(() => {
        if (ImageFile)
            onImage && onImage(ImageFile)
    }, [ImageFile])

    return <div style={{
        flexDirection : 'row',
        display : 'flex',
        alignItems : 'center'
    }}>
        <img className="img-fluid" style={{
            height: small ? 50 : 100,
            width: small ? 50 : 100,
            backgroundColor: Color.darkGray
        }} src={ImageFile ? URL.createObjectURL(ImageFile) : api.getMedia!(item.photo)} />

        <input type='file' id='multi' style={{
            fontSize : 10
        }} onChange={(e) => handleImage(e, item)} />
        <br />
    </div>

}

const Menu = () => {


    const [Items, setItems] = useState<MenuItemType | any>([])
    const [Loading, setLoading] = useState(false)
    const [Categories, setCategories] = useState<MenuCategory | any>([])
    const [DoUpdate, setDoUpdate] = useState(0)
    const [AddItem, setAddItem] = useState(false)
    const restaurant = useSelector((state: RootState) => state.restaurant)
    const history = useHistory()

    useEffect(() => {
        if (restaurant) {
            setLoading(true)
            api.get("/menu-categories?restaurant=" + restaurant.id)
                .then(({ data }) => {
                    setCategories(data)
                }).catch((e) => {

                }).then(() => {
                    setLoading(false)
                })
        }

        return () => {

        }
    }, [restaurant, DoUpdate])

    useEffect(() => {

        if (restaurant) {
            setLoading(true)
            api.get("/menu-items?_sort=Order:ASC&_limit=300&restaurant=" + restaurant.id + "")
                .then(({ data }) => {
                    setItems(data)

                }).catch((e) => {

                }).then(() => {
                    setLoading(false)
                })
        }

        return () => {

        }
    }, [restaurant, DoUpdate])


    if (Loading) {
        return <div className="container" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 20
        }}>
            <div className="spinner-border" role="status">
                <span className="visually-hidden"></span>
            </div>
        </div>
    }


    return (<div className="container">
        <div className="row">
            <div className="col-12" style={{
                position: 'relative',
                height: 50,
                alignItems: 'center',
                display: 'flex'
            }}>
                <button onClick={() => setAddItem(!AddItem)} style={{
                    position: 'absolute',
                    right: 10,
                    // top : 0,
                }} className={"btn " + (AddItem ? "btn-danger" : "btn-primary")}>{i18n.t(AddItem ? "close" : "add")}</button>
            </div>
            {AddItem && <div className="col-12">
                <div className="shadow" style={{
                    padding: 20,
                    marginTop: 20,
                    borderRadius: 10
                }}>
                    <h3>{i18n.t("new-menu-item")}</h3>
                    <ItemForm {...{ item: { id: -1 }, restaurant, Categories, setDoUpdate }} />
                </div>

                {Loading && <div className="spinner-border" style={{ width: "3rem", height: "3rem", marginTop: 40, alignSelf: 'center' }} role="status">
                    <span className="visually-hidden">{i18n.t("loading-text")}</span>
                </div>}

            </div>}
            <div className="col-12">
                <h3>{i18n.t("menu-items")}</h3>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{i18n.t("menu-form-name")}</th>
                            <th scope="col">{i18n.t("menu-form-new-description")}</th>
                            <th scope="col">{i18n.t("menu-form-price")}</th>
                            {/* <th scope="col"></th> */}
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(Items).map((item: MenuItemType, key: number) => {
                            return <tr key={key}>
                                <th scope="row">{key + 1}</th>
                                {/*@ts-ignore*/}
                                <td>{item["name_" + i18n.getLang()] || item.name}</td>
                                {/*@ts-ignore*/}
                                <td>{item["description_" + i18n.getLang()] || item.description}</td>
                                <td>₪{item.price}</td>
                                {/* <td>
                                    <ImageChanger small item={item} onUpdate={() => console.log("onUpdate")} onImage={() => console.log("on image uploaded")} />
                                </td> */}
                                <td><button onClick={() => {
                                    history.push("/menu/" + item.id)
                                }} className="btn btn-success">{i18n.t("view")}</button></td>
                            </tr>
                        })}
                    </tbody>
                </table>
                {/* <div className="accordion accordion-flush" id="itemFlushExample">
                    {(Items).map((item: MenuItemType) => {

                        return <div key={`${item.id}`} className="accordion-item">
                            <h2 className="accordion-header" id={`${item.id}`}>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush${item.id}-collapseOne`} aria-expanded="false" aria-controls={`${item.id}-collapseOne`}>
                                    
                                    {item["name_" + i18n.getLang()] || item.name}
                                </button>
                            </h2>
                            <div id={`flush${item.id}-collapseOne`} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#itemFlushExample">
                                <div className="accordion-body">
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>

                                        <ItemForm {...{ item, restaurant, Categories, setDoUpdate }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}

                </div> */}

            </div>
        </div>
        <style>{`
        .accordion-button {
            padding : 0px;
        }
        `}</style>
    </div>

    )
}

export default Menu

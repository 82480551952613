import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "../../../../configureStore";
import i18n from "../../../../i18n";
import { getPendingOrders, OrderType } from "../../../../redux/orderSlice";
import { RestaurantType } from "../../../../redux/restaurantSlice";
import { api } from "../../../../utils/api";
import { OrdersCounter } from "../../../containers/TopBar";


let audio = new Audio("/sounds/incoming_notification.mp3")

let soundTimeout: any = null


function isIOSDevice() {
  const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  console.log('isIOS', isIOS)
  return isIOS
}

const PendingOrderAlert = () => {

  const dispatch = useDispatch();
  const restaurant: RestaurantType = useSelector((state: RootState) => state.restaurant)
  const newestPendingOrders = useSelector(
    (state: RootState) => state.order.newestPendingOrders
  );
  const auth = useSelector((state: RootState) => state.auth)
  const fetchingPending: boolean = useSelector(
    (state: RootState) => state.order.fetchingPending
  );

  const getPendingOrder = () => {
    if (restaurant) dispatch(getPendingOrders({ restaurant: restaurant.id }))
    soundTimeout = setTimeout(() => {
      getPendingOrder()
    }, 30000)
  }
  useEffect(() => {
    console.log("fetchingPending", fetchingPending, "newestPendingOrder", newestPendingOrders);
    getPendingOrder()

  }, [restaurant.id])

  useEffect(() => {
    console.log("newestPendingOrder", newestPendingOrders.length, auth.allowNotifications)
    if ((newestPendingOrders.length > 0)) {

      console.log("sending message postMessage")
      // @ts-ignore
      window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
        ...newestPendingOrders,
        restaurant: { ...restaurant }
      }));

      if (!auth.allowNotifications) {
        return;
      }
      try {
        audio.loop = true;
        audio.play();
        if (Notification.permission !== 'granted') //|| !isIOSDevice()
          Notification.requestPermission();
        else {
          var notification = new Notification(restaurant.name, {
            icon: api.getMedia!(restaurant.logo),
            body: i18n.t("new-order"),
          });
        }

      }
      catch (e) {
        console.warn('not working nice', e)
      }
    } else {
      audio.pause()
    }

  }, [newestPendingOrders, auth.allowNotifications])


  if (newestPendingOrders.length == 0) {
    clearTimeout(soundTimeout)
    audio.pause()
    return null
  }


  return (
    <div>

    </div>
  );
};

export default PendingOrderAlert;

import React from "react"
import { useLocation } from "react-router-dom"

export const Truthy =(obj: any) => {
    if (obj instanceof Boolean)
        return true
    else if (obj instanceof Object)
        return Object.keys(obj).length > 0
    else if (obj instanceof Array) {
        return obj.length > 0
    } else {
        return false
    }
}

export const DATE_FORMAT = 'DD/MM/YY'
export const HOUR_FORMAT = 'HH:mm'
export const FULL_DATE_FORMAT = DATE_FORMAT + ' ' + HOUR_FORMAT;

export const useQuery = () => {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
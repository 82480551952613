// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../configureStore'
import i18n from '../../../i18n'
import restaurantSlice from '../../../redux/restaurantSlice'
import { api } from '../../../utils/api'



let modalView : any = null

const getDefaultData = (restaurant) => {
    console.log("getDefaultData", restaurant.id)
    return Object.assign({}, {restaurant : restaurant.id, enabled : false, Day : "", StartHour : "", StartMinute: "", EndHour : "", EndMinute : ""})
}

const HoursOfOperationModal = ({id,  Takeout, apiRoute, onUpdated, onClose }: { id :string, Takeout: any | null, apiRoute : string, onUpdated: (order: OrderType) => void , onClose : () => void}) => {

    const restaurant = useSelector((state : RootState) => state.restaurant)
    const [TakeoutData, setTakeoutData] = useState({restaurant : restaurant.id, Day : "", StartHour : "", StartMinute: "", EndHour : "", EndMinute : "", enabled : false})
    const [Ready, setReady] = useState(false)

    useEffect(() => {
        console.log("id ==>", id)
        if(id){
            if(modalView){
                modalView.hide()
                modalView = null
            }
            modalView = new bootstrap.Modal(document.getElementById(id), {})
        }
    }, [id])

    useEffect(() => {
        // const checkBox = document.getElementById("enabled" + id)
        // console.log("removeounte")
        // checkBox?.addEventListener("change", onChange)
        // return () => {
        //     checkBox?.removeEventListener("change", onChange)
        // }
    }, [])

    // useEffect(() => {

    //     feather.replace()
    // }, [])

    useEffect(() => {
        
        if(Takeout != null){
            console.log("Takeout ==>", Takeout)
            if(modalView){
                modalView.hide()
                modalView = null
            }
            modalView = new bootstrap.Modal(document.getElementById(id), {})
            modalView.show()
            setTakeoutData(Takeout)
            
        }
        return () => {
            
        }
    }, [Takeout])

    

    const clearTakeoutData = () => {
        console.log("clearTakeoutData")
        setTakeoutData(Object.assign({}, getDefaultData(restaurant)))
        onClose()
    }

    const updateOrCreate = () => {
        let url = apiRoute + (Takeout ? "/" + TakeoutData.id : "")
        api[Takeout ? "put" : "post"](`${url}`, TakeoutData).then(({ data }) => {
            modalView.toggle()
            clearTakeoutData()
            onUpdated(data)
            
        }).catch((e) => {
            alert("Error creating takeout hour")
        })
    }

    useEffect(() => {
        // console.log("TakeoutData", TakeoutData)
   

        if(TakeoutData.Day !== "" && TakeoutData.StartHour !== "" && TakeoutData.StartMinute !== "" && TakeoutData.EndHour !== "" && TakeoutData.EndMinute !== ""){
            setReady(true)
        }else{
            setReady(false)
        }
        return () => {
            
        }
    }, [TakeoutData])

    const onChange = (e) => {
        let {value, checked, name, type} = e.target
        console.log("onChange", value, checked, name)
        // try{
        //     value = parseInt(value)
        // }catch(e){
        //     value = ""
        // }
        let tempData = Object.assign({}, TakeoutData)
        tempData[name] = type == "checkbox" ? checked ? true : false : (value)
        console.log("tempData", tempData)
        setTakeoutData(tempData)
    }

    return (
        <div id={id} className="modal" tabIndex={-1} style={{
            paddingTop : "5%"
        }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{i18n.t("create-hour")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="dropdown">
                            <form className="row g-3">
                                <div className="col-md-6">
                                    <label htmlFor="day" className="form-label">{i18n.t('day')}</label>
                                    <input type="text" name="Day" onChange={onChange} value={TakeoutData.Day} id={"Day"  + id} className="form-control" />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="startHour" className="form-label">{i18n.t('start-hour')}</label>
                                    <input type="text" name="StartHour" onChange={onChange} value={TakeoutData.StartHour} id={"StartHour" + id} className="form-control"  />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="startMinute" className="form-label">{i18n.t('start-minute')}</label>
                                    <input type="text" name="StartMinute" onChange={onChange} value={TakeoutData.StartMinute} id={"StartMinute" + id} className="form-control" />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="endHour" className="form-label">{i18n.t('end-hour')}</label>
                                    <input type="text" name="EndHour" onChange={onChange} value={TakeoutData.EndHour} id={"EndHour" + id} className="form-control"  />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="endMinute" className="form-label">{i18n.t('end-minute')}</label>
                                    <input type="text" name="EndMinute" onChange={onChange} className="form-control" id={"EndMinute" + id} value={TakeoutData.EndMinute} />
                                </div>


                                <div className="col-md-4">
                                    <label htmlFor="inputState" className="form-label">{i18n.t('enabled')}</label>
                                    <select id="inputState" name="enabled" className="form-select" value={TakeoutData?.enabled ? "true" : "false"} onChange={(e) => {
                                        onChange({
                                            target :{
                                                value : e.target.value == "true" ? true : false,
                                                name : e.target.name
                                            }
                                        })
                                    }}>
                                        <option disabled value={"null"} >{i18n.t('choose-one')}</option>
                                        
                                        <option  value={"true"}>{i18n.t('yes')}</option>
                                        <option  value={"false"}>{i18n.t('no')}</option>
                                        
                                    </select>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => clearTakeoutData()} data-bs-dismiss="modal">{i18n.t('close')}</button>
                        <button type="button" className="btn btn-primary" disabled={!Ready} onClick={updateOrCreate}>{Takeout ? i18n.t("edit") : i18n.t("save")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HoursOfOperationModal

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../configureStore'
import i18n from '../../../i18n'
import { MenuItemType } from '../../../redux/orderSlice'
import { RestaurantType } from '../../../redux/restaurantSlice'
import { api } from '../../../utils/api'
import { Color } from '../../../utils/Colors'
import { MenuCategory, MenuParentCategoryType } from '../../MenuCategories/MenuCategories'
import { ImageChanger } from '../Menu'
import ExtraItem from './ExtraItem'
import Optional from './Optional'
import OptionalItem from './OptionalItem'

const InputField = ({ text, onChange, defaultValue, type = "text" }: { text: string, onChange: (e: any) => void, defaultValue: string, type? : string }) => {

    return <div>
        <span>{text}</span>
        <input type={type} className="form-control" onChange={onChange} defaultValue={defaultValue} />
    </div>
}

const InputCheckBox = ({ text, onChange, defaultChecked, id }: { text: string, onChange: (e: any) => void, defaultChecked: boolean, id: string }) => {

    return <div className="form-check">
        <input onChange={onChange} defaultChecked={defaultChecked} className="form-check-input" type="checkbox" value="" id={id} />
        <label className="form-check-label" htmlFor={id}>
            {text}
        </label>
    </div>
}

const ItemForm = ({ item, restaurant, Categories, setDoUpdate }: { item: MenuItemType | any, restaurant: RestaurantType, Categories: Array<MenuCategory>, setDoUpdate: (value: any) => void }) => {
    const [Form, setForm] = useState(item)
    const [ImageFile, setImageFile] = useState<Blob | null>(null)
    const [Extras, setExtras] = useState<any[]>([])
    const [Optionals, setOptionals] = useState<any>([])

    const getExtras = () => {
        api.get("/menu-extras?menu_item=" + item.id)
            .then(({ data }) => {
                // console.log("got menu-extras", data)
                let tempExtras: any[] = []
                tempExtras.push({

                })
                tempExtras = data.concat(tempExtras);
                // console.log("concatendated data", data)
                setExtras(tempExtras)
            })
            .catch(e => {
                console.warn("could not getExtras", e);

            })
    }

    const getOptionals = () => {
        api.get("/menu-optionals?menu_item=" + item.id + "")
            .then(({ data }) => {
                console.log("got menu-extras", data)
                let tempExtras: any[] = []
                tempExtras.push({

                })
                tempExtras = data.concat(tempExtras);
                // console.log("concatendated data", data)
                setOptionals(tempExtras)
            })
            .catch(e => {
                console.warn("could not getExtras", e);

            })
    }

    console.log("ItemForm", item)

    

    const saveitem = (item: any) => {

        delete item.photo

        let tempForm = Object.assign({}, Form);
        tempForm.restaurant = tempForm.restaurant.id
        delete tempForm.menu

        api.put(`/menu-items/${item.id}`, tempForm)
            .then(({ data }) => {
                console.log("updated Parent item", data)
                setDoUpdate((prevVal: number) => prevVal + 1)
                alert(i18n.t("successfully"))

            }).catch((e) => {
                console.warn("failed updating Parent item", e)
                // alert("Error updating image")
            })
    }

    const createItem = (item: any) => {

        delete item.photo

        const formData = new FormData()
        if (ImageFile) {
            formData.append("files.photo", ImageFile)

        }

        formData.append("data", JSON.stringify({
            "name": Form.name,
            "description": Form.description,
            "price": Form.price,
            "restaurant": restaurant.id,
            "Takeout": Form.Takeout,
            "Delivery": Form.Delivery,
            "Vegetarian": Form.Vegetarian,
            "Active": true,
            "Dairy": false,
            hasNotes : Form.hasNotes,
            "NewProduct": Form.NewProduct,
            "Order": Form.Order,
            "pos_id": Form.pos_id,
            "name_he": Form["name_he"],
            "description_he": Form["description_he"],
            "menu_category": Form["menu_category"],
            "soldcount": 0,
            "preparationTime": null,
            "special": false
        }))

        api.post(`/menu-items`, formData)
            .then(({ data }) => {
                console.log("createItem ", data)
                setDoUpdate((prevVal: number) => prevVal + 1)

            }).catch((e) => {
                console.warn("failed createItem", e)
                // alert("Error updating image")
            })


    }

    const deleteItem = (item: MenuItemType) => {
        if (window.confirm(i18n.t("confirm-delete"))) {
            api.delete(`/menu-items/${item.id}`)
                .then(({ data }) => {
                    console.log("deleteItem ", data)
                    setDoUpdate((prevVal: number) => prevVal + 1)

                }).catch((e) => {
                    console.warn("failed deleteItem", e)
                    // alert("Error updating image")
                })
        }


    }

    const onChangeText = (value: string | boolean, name: string) => {
        let tempForm: any = Object.assign({}, Form)
        tempForm[name] = value
        setForm(tempForm)
    }

    useEffect(() => {
        getOptionals()
        getExtras();
    }, [])


    return <div>
        {/* <img className="img-fluid" style={{
            height: 100,
            width: 100,
            backgroundColor: Color.darkGray
        }} src={ImageFile ? URL.createObjectURL(ImageFile) : api.getMedia!(item.photo)} />

        <input type='file' id='multi' onChange={(e) => handleImage(e, item)} />
        <br /> */}

        {/* <ImageChanger item={item} onUpdate={setDoUpdate} onImage={setImageFile} /> */}
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <InputField text={i18n.t("menu-form-name")} onChange={(e: any) => onChangeText(e.target.value, "name")} defaultValue={item.name} />
            <InputField text={i18n.t("menu-form-name-he")} onChange={(e: any) => onChangeText(e.target.value, "name_he")} defaultValue={item.name_he} />
            <InputField text={i18n.t("menu-form-order")} onChange={(e: any) => onChangeText(e.target.value, "order")} defaultValue={item.Order} />
            <InputField text={i18n.t("menu-form-price")} type={"number"} onChange={(e: any) => onChangeText(e.target.value, "price")} defaultValue={item.price} />
            <InputField text={i18n.t("pos-id")} onChange={(e: any) => onChangeText(e.target.value, "pos_id")} defaultValue={item.pos_id} />
        </div>

        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        }}>
            <InputCheckBox text={i18n.t("takeout-delivery")} onChange={(e) => onChangeText(e.target.checked, "Takeout")} defaultChecked={item.Takeout ? true : false} id={"flexCheckDefault" + item.id} />
            {/* <InputCheckBox text={i18n.t("menu-form-delivery")} onChange={(e) => onChangeText(e.target.checked, "Delivery")} defaultChecked={item.Delivery ? true : false} id={"flexCheckDefault" + item.id} /> */}
            {/* <InputCheckBox text={"Vegetarian"} onChange={(e) => onChangeText(e.target.checked, "Vegetarian")} defaultChecked={item.Vegetarian ? true : false} id={"flexCheckDefault" + item.id}/> */}
            <InputCheckBox text={i18n.t("menu-form-active")} onChange={(e) => onChangeText(e.target.checked, "Active")} defaultChecked={item.Active ? true : false} id={"flexCheckDefault" + item.id} />

            <InputCheckBox text={i18n.t("menu-form-new-product")} onChange={(e) => onChangeText(e.target.checked, "NewProduct")} defaultChecked={item.NewProduct ? true : false} id={"flexCheckDefault" + item.id} />
            <InputCheckBox text={i18n.t("menu-form-new-has-notes")} onChange={(e) => onChangeText(e.target.checked, "hasNotes")} defaultChecked={item.hasNotes ? true : false} id={"flexCheckDefault" + item.id} />
        </div>


        <InputField text={i18n.t("menu-form-new-description")} onChange={(e: any) => onChangeText(e.target.value, "description")} defaultValue={item.description} />
        <InputField text={i18n.t("menu-form-new-description-he")} onChange={(e: any) => onChangeText(e.target.value, "description_he")} defaultValue={item.description_he} />
        {/* <span>Description Hebrew</span>
        <textarea className="form-control" onChange={(e) => onChangeText(e.target.value, "description_he")} defaultValue={item.description_he} /> */}
        <span>{i18n.t("categories-title")}</span>
        <select id={`select-${item.id}`} value={Form.menu_category ? Form.menu_category.id : -1} className="form-select" onChange={(e) => {
            onChangeText(e.target.value, "menu_category")
        }} aria-label=" select example">
            <option value={-1}>{i18n.t("select-one")}</option>
            {Categories.map((category: MenuCategory, key) => {

                {/* @ts-ignore */ }
                return <option key={key} value={category.id}>{category["name_" + i18n.getLang()] || category.name}</option>
            })}

        </select>

        <button style={{
            marginTop: 20
        }} onClick={() => item.id == -1 ? createItem(item) : saveitem(item)} className="btn btn-primary">{i18n.t("save")}</button>

        {item.id != -1 && <button style={{
            marginTop: 20,
            marginLeft: 20,
            marginRight: 20
        }} onClick={() => deleteItem(item)} className="btn btn-danger">{i18n.t("delete")}</button>}

        <br />
        <br />

        <span style={{
            fontWeight: 600
        }}>{i18n.t("extras-title")}</span>

        <div className="" style={{
            backgroundColor: "#F1F1F1",
            padding: 10,
            display: 'flex',
            flexDirection: "column",
            width: "100%"

        }}>
            {Extras.map((extra, key) => {
                console.log("render extra", extra)

                return <ExtraItem key={key} extra={extra} item={item} refresh={getExtras} />
            })}
        </div>
        <span style={{
            fontWeight: 600
        }}>{i18n.t("optionals-title")}</span>

        <div className="" style={{
            backgroundColor: "#F1F1F1",
            padding: 10,
            display: 'flex',
            flexDirection: "column",
            width: "100%"

        }}>
            {Optionals.map((optional: any, key: string) => {
                console.log("render optional", optional)

                return <Optional key={key} optional={optional} item={item} refresh={getOptionals} />
            })}
        </div>


    </div>
}

export default ItemForm

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import QueryString from 'qs';
import { api, StrapiImage } from '../utils/api';


export const getRestaurantOperationTimes = createAsyncThunk('restaurant/getRestaurantOperationTimes',
  async (params: { restaurant: number }) => {
    const query = QueryString.stringify({
      _where: [{ restaurant: params.restaurant }],
      _limit: 1
    });
    const response = await api.get(
      `operation-times?${query}`
    );
    console.log('Restaurant Op-Times:', response.data);
    return response.data[0];
  }
);


export const checkBusinessClosed = createAsyncThunk('restaurant/checkClose',
  async (restaurant : RestaurantType) => {

    const response = await api.get(`/closed-businesses?restaurant=${restaurant.id}&date=${dayjs().format("YYYY-MM-DD")}`)
    console.log('Restaurant closed:', response.data);
    return response.data;
  }
);

export const saveOperationTime = createAsyncThunk('restaurant/saveOperationTime',
  async (params: { operationTimesID: number | undefined, editedOPTimes: any }) => {
    let response = null
    if(params.operationTimesID){
      response = await api.put(
        `operation-times/${params.operationTimesID}`, params.editedOPTimes
      );
    }else{
      response = await api.post(
        `operation-times`, params.editedOPTimes
      );
    }
    
    console.log('Restaurant Op-Times:', response.data);
    return response.data;
  }
);

type TimesType = {
  "isClosed": boolean;
  "openTime": string;
  "closeTime": string;
  "order": number;
}

export type OperationTimesType = {
  "restaurant": number,
  id: number;
  "sunday": TimesType,
  "monday": TimesType,
  "tuesday": TimesType,
  "wednesday": TimesType,
  "thursday": TimesType,
  "friday": TimesType,
  "saturday": TimesType
}

export type ImageType = {
  "alternativeText": "",
  "caption": "",
  "created_at": "2020-12-14T02:43:25.654Z",
  "ext": ".jpg",
  "formats": {
    "large": {
      "ext": ".jpg",
      "hash": "large_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d",
      "height": 667,
      "mime": "image/jpeg",
      "name": "large_dan-gold-4_jhDO54BYg-unsplash.jpg",
      "path": null,
      "size": 209.19,
      "url": "/uploads/large_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d.jpg",
      "width": 1000,
    },
    "medium": {
      "ext": ".jpg",
      "hash": "medium_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d",
      "height": 500,
      "mime": "image/jpeg",
      "name": "medium_dan-gold-4_jhDO54BYg-unsplash.jpg",
      "path": null,
      "size": 129.06,
      "url": "/uploads/medium_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d.jpg",
      "width": 750,
    },
    "small": {
      "ext": ".jpg",
      "hash": "small_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d",
      "height": 333,
      "mime": "image/jpeg",
      "name": "small_dan-gold-4_jhDO54BYg-unsplash.jpg",
      "path": null,
      "size": 65.93,
      "url": "/uploads/small_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d.jpg",
      "width": 500,
    },
    "thumbnail": {
      "ext": ".jpg",
      "hash": "thumbnail_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d",
      "height": 156,
      "mime": "image/jpeg",
      "name": "thumbnail_dan-gold-4_jhDO54BYg-unsplash.jpg",
      "path": null,
      "size": 17.42,
      "url": "/uploads/thumbnail_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d.jpg",
      "width": 234,
    },
  },
  "hash": "dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d",
  "height": 2000,
  "id": 1,
  "mime": "image/jpeg",
  "name": "dan-gold-4_jhDO54BYg-unsplash.jpg",
  "previewUrl": null,
  "provider": "local",
  "provider_metadata": null,
  "size": 747.37,
  "updated_at": "2020-12-14T02:43:25.688Z",
  "url": "/uploads/dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d.jpg",
  "width": 3000,
}

export type LocationType = {
  "city": string;
  "created_at": string;
  "id": string;
  "latitude": number;
  "longitude": number;
  "published_at": "2020-12-14T01:52:40.336Z",
  "state": string;
  "updated_at": "2020-12-14T02:06:39.645Z",
}

export declare type RestaurantTypesType = {
  "created_at": Date;
  "id": number;
  "location": LocationType,
  "name": string;
  "published_at": Date;
  "updated_at": Date;
}

export type RestaurantType = {
  LocationID: string;
  address: string;
  averagePrice: number;
  averageTime: number;
  color: string;
  driverNotes : string;
  dateFormat: string;
  timeFormat: string;
  isClosed? : boolean;
  ClosedHour : any;
  colorText: string;
  created_at: Date;
  currency: string;
  description: string;
  facebook: string;
  featured?: StrapiImage[],
  id: number,
  instagram: string;
  kosher: boolean;
  lang: string;
  location: LocationType,
  logo: StrapiImage;
  name:  string;
  photos?: StrapiImage[],
  published_at: Date;
  rate: null,
  zipcode: string;
  operationTimes: OperationTimesType;
  restaurant_types: RestaurantTypesType[];
  tags: any[];
  telephone: string;
  updated_at: Date;
}




export const saveRestaurant = (data: string | null) => {
  try {
    if (data)
      localStorage.setItem('@restaurant', data)
  } catch (e) {
    // saving error
  }
}

export const getSavedRestaurant = () => {
  try {
    const value: string | null = localStorage.getItem('@restaurant')
    if (value !== null) {
      // value previously stored
      return JSON.parse(value)
    }
  } catch (e) {
    // error reading value
    return {}
  }
  return {}
}

let restaurantInitialState: RestaurantType = getSavedRestaurant();

const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState: restaurantInitialState,
  reducers: {
    selectRestaurant: (state, action: PayloadAction<RestaurantType | undefined>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //console.log("selectRestaurant", action.payload)
      saveRestaurant(JSON.stringify(action.payload))
      //@ts-ignore
      state = action.payload
      return state
    }
  },
  extraReducers: (builder) => {
    //Fecth device info
    builder.addCase(getRestaurantOperationTimes.pending, (state) => {
      //@ts-ignore
      state.fetchingOperationTimes = true;
    });
    builder.addCase(getRestaurantOperationTimes.fulfilled, (state, action) => {
      //@ts-ignore
      state.fetchingOperationTimes = false;
      state.operationTimes = action.payload
    });
    builder.addCase(getRestaurantOperationTimes.rejected, (state, action) => {
      //@ts-ignore
      state.fetchingOperationTimes = false;
    });
    builder.addCase(checkBusinessClosed.pending, (state) => {
      //state.fetchingOperationTimes = true;
      return state
    });
    builder.addCase(checkBusinessClosed.fulfilled, (state, action) => {
      console.log("checkNusinessClosed", action.payload)
      state.isClosed = action.payload.length === 1
      state.ClosedHour = action.payload[0]
    });
    builder.addCase(checkBusinessClosed.rejected, (state, action) => {
      //@ts-ignore
      state.fetchingOperationTimes = false;
    });
  }

})

export const {
  selectRestaurant,
} = restaurantSlice.actions

export default restaurantSlice

import React from 'react'
import i18n from '../../../i18n'
import ParentCategoryForm from '../../Menu/containers/ParentCategoryForm'
import { MenuParentCategoryType } from '../MenuCategories'



const ParentCategoriesView = ({ restaurant, setDoUpdate, ParentCategories, Loading }: { restaurant: any, setDoUpdate: any, ParentCategories: any, Loading: boolean }) => {
    return (
        <div className="row">
            <div className="col-12 col-md-5">

                <div className="shadow" style={{
                    padding: 20,
                    marginTop: 20,
                    borderRadius: 10
                }}>
                    <h3>{i18n.t("menu-parent-category-new")}</h3>
                    <ParentCategoryForm {...{ parentCategory: { id: -1 }, restaurant, ParentCategories, setDoUpdate }} />

                    {Loading && <div className="spinner-border" style={{ width: "3rem", height: "3rem" }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>}

                </div>
            </div>
            <div className="col-12 col-md-7">
                <h3>{i18n.t("parent-categories-title")}</h3>
                <div className="accordion accordion-flush" id="parentCategoryFlushExample">
                    {(ParentCategories).map((parentCategory: MenuParentCategoryType | any) => {

                        return <div key={`${parentCategory.id}`} className="accordion-item">
                            <h2 className="accordion-header" id={`${parentCategory.id}`}>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush${parentCategory.id}-collapseOne`} aria-expanded="false" aria-controls={`${parentCategory.id}-collapseOne`}>
                                    {/* @ts-ignore */}
                                    {parentCategory["name_" + i18n.getLang()] || parentCategory.name}
                                </button>
                            </h2>
                            <div id={`flush${parentCategory.id}-collapseOne`} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#parentCategoryFlushExample">
                                <div className="accordion-body">
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>

                                        <ParentCategoryForm {...{ parentCategory, restaurant, ParentCategories, setDoUpdate }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}

                </div>

            </div>
            <style>{`
        .accordion-button {
            padding : 0px;
        }
        `}</style>
        </div>
    )
}

export default ParentCategoriesView

// @ts-nocheck
import React, { useState, useEffect } from 'react'
import i18n from '../i18n'
import { OrderType } from '../redux/orderSlice'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory } from 'react-router-dom'
import { Color, MabaliColors } from '../utils/Colors'
const RejectModal = ({ Order, onReject, isActive, toggle, className }: { Order: OrderType | null, onReject: (message: string) => void, isActive: boolean, toggle: () => void }) => {
    const [DriverType, setDriverType] = useState("business")
    const history = useHistory()
    const [closingSoonMessage, setClosingSoonMessage] = useState(i18n.t("closing-soon"))
    const [itemsNotAvailableMessage, setItemsNotAvailable] = useState(i18n.t("items-not-available"))
    const [otheReasonMessage, setOtheReasonMessage] = useState("")
    const [selectedMessage, setSelectedMessage] = useState("closing")
    useEffect(() => {
        feather.replace()
    }, [])
    const rejectMessage = () => {
        switch (selectedMessage) {
            case 'closing':
                return closingSoonMessage
            case 'itemsNotAvailable':
                return itemsNotAvailableMessage
            case 'other':
                if (otheReasonMessage.length <= 0) return i18n.t("default-reject-message")
                else return otheReasonMessage
            default:
                return i18n.t("default-reject-message")
        }
    }
    return (
        <Modal
            style={{
                zIndex: 9999,
            }}
            centered
            isOpen={isActive}
            toggle={toggle}
            className={className}
        >
            <div className="modal-header">
                <h4 className="modal-title">{i18n.t("reject-reason")}</h4>
                <button type="button" className="btn-close"
                    onClick={toggle} aria-label="Close"></button>
            </div>
            <ModalBody>
                <div
                    onClick={(e) => {
                        setSelectedMessage('closing')
                    }}
                    className="d-flex text-center flex-column "
                    style={{
                        backgroundColor: selectedMessage == "closing" ? MabaliColors.pink : Color.lightGray,
                        color: selectedMessage == "closing" ? 'white' : 'black',
                        borderRadius: 10,
                        fontWeight: 'bold',
                        padding: 5,
                        marginBottom: 10
                    }}
                >
                    <h4>{i18n.t("no-items-available-label")}</h4>
                    <p>{i18n.t("customer-will-see")}</p>
                    <textarea
                        style={{
                            borderRadius: 10,
                            width: "100%",
                        }}
                        id="closing-soon-message"
                        name="closing-soon-msg"
                        placeholder={"closing soon message"}
                        value={closingSoonMessage}
                        onChange={(e) => {
                            setClosingSoonMessage(e.target.value)
                        }}
                    />
                </div>

                <div
                    onClick={(e) => {
                        setSelectedMessage('itemsNotAvailable')
                    }}
                    className="d-flex text-center flex-column "
                    style={{
                        backgroundColor: selectedMessage === 'itemsNotAvailable' ? MabaliColors.pink : Color.lightGray,
                        color: selectedMessage === 'itemsNotAvailable' ? 'white' : 'black',
                        borderRadius: 10,
                        fontWeight: 'bold',
                        padding: 5,
                        marginBottom: 10
                    }}
                >
                    <h4>{i18n.t("no-items-available-label")}</h4>
                    <p>{i18n.t("customer-will-see")}</p>
                    <textarea
                        style={{
                            borderRadius: 10,
                            width: "100%",
                        }}
                        id="closing-soon-message"
                        name="closing-soon-msg"
                        placeholder={i18n.t("missing-item")}
                        value={itemsNotAvailableMessage}
                        onChange={(e) => {
                            setItemsNotAvailableMessage(e.target.value)
                        }}
                    />
                </div>

                <div
                    onClick={(e) => {
                        setSelectedMessage('other')
                    }}
                    className="d-flex text-center flex-column "
                    style={{
                        backgroundColor: selectedMessage === 'other' ? MabaliColors.pink : Color.lightGray,
                        color: selectedMessage === 'other' ? 'white' : 'black',
                        borderRadius: 10,
                        fontWeight: 'bold',
                        padding: 5,
                        marginBottom: 10
                    }}
                >
                    <h4>{i18n.t("other-reason-label")}</h4>
                    <p>{i18n.t("customer-will-see")}</p>
                    <textarea
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            borderRadius: 10,
                            width: "100%",
                        }}
                        id="closing-soon-message"
                        name="closing-soon-msg"
                        placeholder={"Other reason"}
                        value={otheReasonMessage}
                        onChange={(e) => {
                            setOtheReasonMessage(e.target.value)
                        }}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>{i18n.t("close")}</Button>
                <Button disabled={DriverType == null} onClick={() => onReject(rejectMessage())} color="primary">{i18n.t("inform-customer")}</Button>
            </ModalFooter>
        </Modal >
    )
}

export default RejectModal

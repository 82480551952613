
import React, { useState } from 'react'
// import {
//   StyleSheet,
//   View,
//   TextInput,
//   ViewStyle,
//   Text,
//   TouchableOpacity,
//   FlatList

// } from 'react-native'
import { api } from '../../utils/api'
import { Color } from '../../utils/Colors'


// Change No.1
import { useDebounce } from './useDebounce'


// ==== Change No.3 ====
/**
 * Prediction's type returned from Google Places Autocomplete API
 * https://developers.google.com/places/web-service/autocomplete#place_autocomplete_results
*/
export type PredictionType = {
  description: string
  place_id: string
  reference: string
  matched_substrings: any[]
  tructured_formatting: Object
  terms: Object[]
  types: string[]
}



const SearchBarWithAutocomplete = ({
  onHandleAddress,
  style,
  location,
  lang = "he",
  radius = `20000`,
  strictBounds = false,
  publicMode = false,
  phone = "",
  code = "",
  ...props
}
  : {
    onHandleAddress: (address: any, geo: any) => void,
    style?: any,
    location?: string;
    lang?: string,
    radius?: string,
    strictBounds?: boolean,
    publicMode?: boolean,
    phone?: string,
    code?: string
  }) => {

  const {
    container,
    inputStyle
  } = styles
  const passedStyles = Array.isArray(style) ? Object.assign({}, ...style) : style
  const [inputSize, setInputSize] = useState({ width: 0, height: 0 })
  // === Change No.4 ====
  const [search, setSearch] = useState<{ term: string, fetchPredictions?: boolean, location?: any }>({ term: '', fetchPredictions: false, location })
  // ==== Change No.5 ====
  const [predictions, setPredictions] = useState<PredictionType[]>([])
  const [ShowPredictions, setShowPredictions] = useState(false)

  // ==== Change No.6 ====
  /**
   * Grab predictions on entering text
   *    by sending reqyest to Google Places API.
   * API details: https://developers.google.com/maps/documentation/places/web-service/autocomplete
  */

  const onChangeText = async () => {
    if (search.term.trim() === '') return
    if (!search.fetchPredictions) return
    let url = publicMode ? `/custom/place/public/autocomplete?phone=${phone.replace("+", "")}&code=${code}` : "/custom/place/autocomplete"//?search=" + search.term;
    // if (location) {
    //   url += "&location=" + location
    // }
    // if (lang) {
    //   url += "&lang=" + lang
    // }
    // if (radius) {
    //   url += "&radius=" + radius
    // }
    // if (strictBounds) {
    //   url += "&strictbounds=" + strictBounds
    // }
    try {
      const result = await api.post(encodeURI(url), {
        search: encodeURIComponent(search.term),
        location,
        lang,
        radius,
        strictbounds: strictBounds
      })
      if (result) {
        console.log("result", result.data)
        const { data: { predictions } } = result
        setSearch({ term: search.term, location, fetchPredictions: false })
        setPredictions(predictions)
        setShowPredictions(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  // Change No.2
  useDebounce(onChangeText, 1000, [search.term])
  // ==== Change No. 7====
  /**
   * Grab lattitude and longitude on prediction tapped
   *    by sending another reqyest using the place id.
   * You can check what kind of information you can get at:
   *    https://developers.google.com/maps/documentation/places/web-service/details#PlaceDetailsRequests
  */
  const onPredictionTapped = async (placeId: string, description: string) => {
    try {
      console.log("gotPredictionTapped", description)
      let url = publicMode ? `/custom/place/public/details?placeId=${placeId}&phone=${phone.replace("+", "")}&code=${code}` : `/custom/place/details?placeId=${placeId}`
      const result = await api.post(encodeURI(url))
      if (result) {
        const { data: { result: { geometry: { location } } } } = result

        if (location) {

          setShowPredictions(false)
          console.log("result", result.data)
          setSearch({ term: description, location, fetchPredictions: false })
          onHandleAddress(description, result.data)
        }

      }
    } catch (e) {
      console.log(e)
    }
  }

  // ==== Change No.6 ====
  const _renderPredictions = (predictions: PredictionType[]) => {
    const {
      predictionRow
    } = styles
    const calculatedStyle = {
      width: "100%",
      border: '1px solid #333'
    }

    return (
      <div style={calculatedStyle} >
        {predictions.map((item, key) => {

          return (
            <div
              key={key}
              className="prediction-row"
              style={predictionRow}
              onClick={() => onPredictionTapped(item.place_id, item.description)}
            >
              <span>
                {item.description}
              </span>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div style={{ ...container, ...passedStyles }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row'
      }}>
        <input
          style={inputStyle}
          placeholder='חיפוש כתובת'
          value={search.term}
          onChange={({ target: { value: text } }) => {
            setSearch({ term: text, fetchPredictions: true })
          }}

        />
        {search.fetchPredictions ? <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div> : null}
      </div>
      {ShowPredictions && _renderPredictions(predictions)}
      <style>
        {`
            .prediction-row {
              background-color : white;
              color : black;
            }
            .prediction-row:hover {
              background-color : black !important;
              cursor:pointer;
              color : white;
            }
          `}
      </style>
    </div>
  )
}
const styles = {
  container: {
    justifyContent: 'center'
  },
  inputStyle: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: '#fff',
    border: `1px solid ${Color.purple}`,
    color: 'black',
    fontSize: 16
  },
  predictionRow: {
    'WebkitBoxShadow': '5px 5px 15px 5px rgba(0,0,0,0.31)',
    'boxShadow': '5px 5px 15px 5px rgba(0,0,0,0.31)',
    shadowRadius: 3.84,
    elevation: 5,
    border: `0.2px solid ${'#333'}`,
    padding: 10
  }
}

export default SearchBarWithAutocomplete
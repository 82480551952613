import React from 'react'
import i18n from '../../../i18n'
import CategoryForm from '../../Menu/containers/CategoryForm'
import { MenuCategory } from '../MenuCategories'



const CategoriesViews = ({Categories, restaurant, ParentCategories, setDoUpdate, Loading} : {Categories : any, restaurant : any, ParentCategories : any, setDoUpdate : any, Loading : boolean}) => {
    return (
        <div className="row">
                       
                        <div className="col-12 col-md-7">
                            <h3>{i18n.t("categories-title")}</h3>
                            <div className="accordion accordion-flush" id="categoryFlushExample">
                                {(Categories).map((category: MenuCategory) => {
                                    
                                    return <div key={`${category.id}`} className="accordion-item">
                                                <h2 className="accordion-header" id={`${category.id}`}>
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush${category.id}-collapseOne`} aria-expanded="false" aria-controls={`${category.id}-collapseOne`}>
                                                     {/* @ts-ignore */}
                                                    {category["name_" + i18n.getLang()] || category.name}
                                                </button>
                                                </h2>
                                                <div id={`flush${category.id}-collapseOne`} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#categoryFlushExample">
                                                    <div className="accordion-body">
                                                            
                                                            <CategoryForm {...{category, restaurant, ParentCategories, setDoUpdate}} />
                                                    </div>
                                                </div>
                                            </div>
                                })}
                                
                            </div>
                        </div>
                         <div className="col-12 col-md-5">

                            <div className="shadow" style={{
                                padding : 20,
                                marginTop : 20,
                                borderRadius : 10
                            }}>
                                <h3>{i18n.t("menu-category-new")}</h3>
                                <CategoryForm {...{category : {id : -1}, restaurant, ParentCategories, setDoUpdate}}/>

                                
                                {Loading && <div className="spinner-border" style={{ width: "3rem", height: "3rem" }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>}
                            </div>
                        </div>
                        <style>{`
        .accordion-button {
            padding : 0px;
        }
        `}</style>
                </div>
    )
}

export default CategoriesViews

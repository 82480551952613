import React from 'react'
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import i18n from '../../i18n';
import { api } from '../../utils/api';
import ItemForm from '../Menu/containers/ItemForm';
import { MenuCategory } from '../MenuCategories/MenuCategories';

const MenuItemDetails = (props: any) => {

    let { itemId }: any = useParams();
    const history = useHistory()

    console.log("MenuItemDetails", itemId)
    const [Item, setItem] = useState<any>(null)
    const [Categories, setCategories] = useState<MenuCategory | any>([])
    const [DoUpdate, setDoUpdate] = useState(0)
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        if (Item && Item!.restaurant) {
            setLoading(true)
            api.get("/menu-categories?restaurant=" + Item!.restaurant.id)
                .then(({ data }) => {
                    console.log("got Menu Categories", data)
                    setCategories(data)
                }).catch((e) => {

                }).then(() => {
                    setLoading(false)
                })
        }

        return () => {

        }
    }, [Item, DoUpdate])

    const getMenuDetails = () => {
        api.get("/menu-items/" + itemId)
            .then(({ data }) => {
                console.log("menu-items data ===>", data)
                setItem(data)
            })
            .catch(e => {
                console.warn("could not get menu details", e)
            })
    }

    useEffect(() => {
        getMenuDetails()

        return () => {

        }
    }, [])

    if (!Item) {
        return null
    }

    return (
        <div className="container" style={{
            paddingTop: 20
        }}>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#" onClick={() => history.goBack()}>{i18n.t("menu-management")}</a></li>
                    {/*@ts-ignore */}
                    <li className="breadcrumb-item active" aria-current="page">{Item["name_" + i18n.getLang()] || Item.name}</li>
                </ol>
            </nav>
            <ItemForm {...{ item: Item, restaurant: Item.restaurant, Categories, setDoUpdate }} />
        </div>
    )
}

export default MenuItemDetails

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../configureStore'
import i18n from '../../i18n'
import { api } from '../../utils/api'
import { Color } from '../../utils/Colors'
import ParentCategoryForm from '../Menu/containers/ParentCategoryForm'
import CategoryForm from '../Menu/containers/CategoryForm'
import ParentCategoriesView from './containers/ParentCategoriesView'
import CategoriesViews from './containers/CategoriesViews'
import Menu from '../Menu/Menu'
import { useHistory, useParams, useLocation } from 'react-router-dom'

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  

export type MenuCategory = {
    "id": number;
    "restaurant": {
        "id": 38,
        "name": "Picante",
        "description": null,
        "LocationID": "ce85e10c-7486-40bf-b5b3-63b960142ee9",
        "created_at": "2020-12-22T06:04:05.923Z",
        "updated_at": "2021-03-01T03:17:32.894Z",
        "published_at": "2020-12-22T06:04:05.441Z",
        "address": "",
        "kosher": null,
        "facebook": null,
        "instagram": null,
        "lang": null,
        "telephone": "",
        "color": "#8b0000",
        "colorText": "#FFFFFF",
        "rate": null,
        "location": 1,
        "averageTime": null,
        "averagePrice": null,
        "currency": null,
        "tax": null,
        "dateFormat": "DD/MM/YYYY",
        "timeFormat": "hh:mm",
        "logo": null,
        "photos": [

        ],
        "featured": [

        ]
    },
    "name": "SIDE ORDERS",
    "description": "",
    "order": 14,
    "published_at": "2020-12-27T06:58:29.299Z",
    "created_at": "2020-12-27T06:58:29.306Z",
    "updated_at": "2020-12-27T06:58:29.350Z",
    "MenualID": "1800BCC8-2C44-4A74-B02E-FFC4D2DC12AE",
    "photo"?: null,
    "menu_parent_categories": Array<MenuParentCategoryType>
}

export type MenuParentCategoryType = {
    "id": number;
    "name": "Sides",
    "description": "",
    "order": 1,
    "restaurant": 38,
    "published_at": "2020-12-27T05:25:21.472Z",
    "created_at": "2020-12-27T05:25:22.878Z",
    "updated_at": "2020-12-27T05:25:22.939Z",
    "name_he": "",
    "icon": "",
    "description_he": "",
    "MenualID": "CF461E60-625D-44E4-AC9B-DD4E00666AFF",
    "photo": null,
    categories: []
}


const MenuCategories = () => {
    const query: any = useQuery();
    const history = useHistory()
    const ParentCategoriesMap: any = {}
    const [Categories, setCategories] = useState<MenuCategory | any>([])
    const [ParentCategories, setParentCategories] = useState<MenuParentCategoryType | any>([])
    const [Loading, setLoading] = useState(false)
    const [DoUpdate, setDoUpdate] = useState(0)
    const restaurant = useSelector((state: RootState) => state.restaurant)
    const [ActiveSection, setActiveSection] = useState("parent-categories")
    const section  = query.get("section")

    useEffect(() => {
        setLoading(true)
        api.get("/menu-categories?_limit=250&restaurant=" + restaurant.id)
            .then(({ data }) => {
                setCategories(data)
            }).catch((e) => {

            }).then(() => {
                setLoading(false)
            })
        return () => {

        }
    }, [restaurant, DoUpdate])


    useEffect(() => {
        setLoading(true)
        api.get("/menu-parent-categories?_limit=250&restaurant=" + restaurant.id)
            .then(({ data }) => {
                setParentCategories(data)
            }).catch((e) => {

            }).then(() => {
                setLoading(false)
            })
        return () => {

        }
    }, [restaurant, DoUpdate])


    console.log("section", section)


    return (
        <div className="container">
            <div className="row">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button onClick={() => history.push("/menu")} className={"nav-link" + (!section ? " active" : "")} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">{i18n.t("parent-categories-title")}</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => history.push("/menu?section=categories")} className={"nav-link" + (section == "categories" ? " active" : "")} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">{i18n.t("categories-title")}</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => history.push("/menu?section=menu")} className={"nav-link" + (section == "menu" ? " active" : "")} id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">{i18n.t("menu-items")}</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    {!section && <div className="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <ParentCategoriesView {...{ ParentCategories, restaurant, setDoUpdate, Loading }} />
                    </div>
                    }
                    {section == "categories" && <div className="tab-pane active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <CategoriesViews  {...{ Categories, restaurant, ParentCategories, setDoUpdate, Loading }} />
                    </div>
                    }
                    {section === "menu" && <div className="tab-pane active" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <Menu />
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default MenuCategories

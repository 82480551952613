import React from 'react';
import './App.css';
import { Provider } from 'react-redux'
import store from './configureStore'


import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Logout from './pages/Logout';
import Main from './pages/Main';
import dayjs from 'dayjs'
import DriverChat from './pages/OrderChat/DriverChat';

require('dayjs/locale/he')

dayjs.locale('he') // use locale globally

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot" component={Forgot} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/driver/chat/:id" component={DriverChat} />
          <Route path="/" component={Main} />


        </Switch>
      </Router>
    </Provider>
  );
}

export default App;

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../configureStore'
import { setUserData } from '../redux/authSlice'
import { clearJwtToken } from '../utils/api'

function Logout() {
    const dispatch = useDispatch()
    const history = useHistory();
    const auth = useSelector((state:RootState) => state.auth)

    useEffect(() => {
        dispatch(setUserData({
            jwt : "",
            user : null
        }))
        clearJwtToken()
        
    }, [dispatch])

    useEffect(() => {
        
        if(!auth.jwt){
            history.replace("/login")
        }
    }, [auth.jwt, history])

    return (
        <div>
            
        </div>
    )
}

export default Logout

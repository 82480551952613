import React from 'react'
import { useHistory } from 'react-router-dom';
import { OrderType } from '../../redux/orderSlice'
import { MabaliColors } from '../../utils/Colors'


const Button = ({ text, onClick, disabled }: 
  { text: string; 
    onClick: () => void; 
    disabled?: boolean;
  }) => {
  return <div onClick={() => !disabled && onClick()} style={{
    flex: 1,
    height: 65,
    display: 'flex',
    border: `1px solid ${MabaliColors.pink}`,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 2,
    marginRight: 2,
    cursor: disabled ? 'none' : 'pointer',
    opacity : disabled ? 0.5: 1
  }}>
    <span style={{
      fontSize: 20,
      textAlign: 'center',
      marginLeft: 3,
      marginRight: 3
    }}>{text}</span>
  </div>
}

const OrderOptions = ({ order, onCancel }: { order: OrderType; onCancel: () => void; }) => {
  const history = useHistory();
  return (
    <div style={{
      display: 'flex',

    }}>
      <Button text={'שלח הודעה'} disabled={!!!order.chat_agent} onClick={() => {
        history.push("/order/chat/" + order.chat_agent.id)
      }} />
      {/* <Button text={'צפיה בהזמנה'} onClick={() => {
        
      }} /> */}
      <Button text={'בטל הזמנה'} onClick={() => {
        // window.confirm('')
        onCancel()
      }} />
    </div>
  )
}

export default OrderOptions
import React, { useState, useEffect } from 'react'
import {
  useHistory,
  useParams
} from "react-router-dom";
import { ChatType } from '../../redux/orderSlice';
import { api } from '../../utils/api';
import { Color, MabaliColors } from '../../utils/Colors';
import ChatBody from './ChatBody';
import MessageInput from './MessageInput';
import {
  useLocation
} from "react-router-dom";

let refreshChatTimeout: NodeJS.Timeout | undefined;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}



export function DriverChat() {
  const [messages, setMessages] = useState([]);
  const [CurrentChat, setCurrentChat] = useState<ChatType | null>(null);
  const [CurrentOrder, setCurrentOrder] = useState<any>(null)
  const history = useHistory()

  let { id }: any = useParams();
  let query = useQuery();

  const driverId = (Number)(query.get('driverId'));
  const apiKey = (query.get('apiKey'));
  const showClose = (query.get('showClose'));

  const securityHeaders = apiKey ? {
    withCredentials: false,
    headers: {
      'x-clientsecret': apiKey
    }
  } : api.defaults.headers

  const getUserChat = () => {
    console.log('getUserChat', CurrentOrder?.chat_agent?.id)
    api.get(`/chat-agents/${CurrentOrder?.chat_agent?.id}?driverChat=true`, securityHeaders)
      .then(({ data }) => {
        console.log("data", data)
        const currentChat = data
        setCurrentChat(currentChat);
        setMessages(currentChat.chat || [])

      })
      .catch((e) => {
        console.warn("could not get messages", e.response);
      })
  }

  const loadMessages = () => {
    getUserChat();
    setTimeout(() => {

      loadMessages();
    }, 2000)
  }
  useEffect(() => {
    if (CurrentOrder?.chat_agent)
      loadMessages();

  }, [CurrentOrder])

  const getOrder = () => {
    api.get('/orders/' + id, securityHeaders)
      .then(({ data }) => {
        setCurrentOrder(data);
      })
  }

  useEffect(() => {
    getOrder()
  }, [id]);


  useEffect(() => {
    console.log('CurrentChat', CurrentChat)
    console.log('driverId', driverId)
  }, [CurrentChat]);

  return (
    <div className='container-fluid' style={{
      flex: 1,
      height: "100vh",
      width: "100%",
      overflowY: 'scroll',
      display: 'flex',
      padding: 0,
      flexDirection: 'column',
      //  height :"90vh",
      backgroundColor: Color.lightGray
    }}>
      <div style={{
        backgroundColor: MabaliColors.pink,
        width: "100%",
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'center',
        display: 'flex'
      }}>
        {showClose && <div onClick={() => {
          history.goBack();
        }} style={{
          color: '#FFFFFF',
          border: '1px solid white',
          padding: 10,
          paddingLeft: 15,
          paddingRight: 15,
          borderRadius: 8,
          marginLeft: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}>
          X
        </div>}
        <div style={{
          display: 'flex',
          flex: 1,

          justifyContent: 'center'
        }}>
          <span style={{
            color: 'white',
            fontSize: 20,
            fontWeight: 700
          }}>
            {CurrentOrder?.restaurant.name}
          </span>
        </div>
      </div>
      <ChatBody messages={CurrentChat?.chat} userId={driverId} />
      <MessageInput order={CurrentOrder}
        apiKey={apiKey}
        driverId={driverId} onRefresh={() => {
          if (!CurrentOrder?.chat_agent)
            getOrder()
          else
            getUserChat()

        }} />
    </div>
  )
}

export default DriverChat
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import i18n from '../../i18n';
import { RestaurantType, saveOperationTime } from '../../redux/restaurantSlice';
import { DayForm } from './components/DayForm';

const OperationTimeForm = ({
  restaurant
}: {
  restaurant: RestaurantType
}) => {
  const dispatch = useDispatch();
  const optDayObject = {
    isClosed: false,
    openTime: '00:00:00',
    closeTime: '20:00:00',
    order: 0
  }
  const operationTimes = useSelector((state: RootState) => state.restaurant.operationTimes)
  let operationTimesModified = {}
  const [sunday, setSunday] = useState(optDayObject)
  const [monday, setMonday] = useState(optDayObject)
  const [tuesday, setTuesday] = useState(optDayObject)
  const [wednesday, setWednesday] = useState(optDayObject)
  const [thursday, setThursday] = useState(optDayObject)
  const [friday, setFriday] = useState(optDayObject)
  const [saturday, setSaturday] = useState(optDayObject)

  const onFormChange = (e: any) => {
    let target = e.target
    let name = target.name
    let value = target.value
    let sundayObj = sunday
    switch (name) {
        case 'sundayOpenTime':
            sundayObj.openTime = value
            setSunday(sundayObj)
            break;
        case 'sundayCloseTime':
            sundayObj.closeTime = value
            setSunday(sundayObj)
            break;
    }
}

  useEffect(() => {
    console.log("operationTimes", operationTimes);
    if (operationTimes) {
      console.log("OP", operationTimes);
      operationTimesModified = operationTimes
      let opTime = operationTimes
      setSunday(opTime.sunday != null ? opTime.sunday : optDayObject)
      setMonday(opTime.monday != null ? opTime.monday : optDayObject)
      setTuesday(opTime.tuesday != null ? opTime.tuesday : optDayObject)
      setWednesday(opTime.wednesday != null ? opTime.wednesday : optDayObject)
      setThursday(opTime.thursday != null ? opTime.thursday : optDayObject)
      setFriday(opTime.friday != null ? opTime.friday : optDayObject)
      setSaturday(opTime.sunday != null ? opTime.saturday : optDayObject)
    }


  }, [operationTimes])
  return (
    <div className="col-12">
      {/* OPERATION TIME FORM */}
      <div className="row">
        <DayForm
          id='id-sunday'
          setDayData={(newDayData: any) => {
            console.log("newDayData", newDayData);
            setSunday(newDayData)
            console.log("NModified", operationTimesModified);
          }}
          dayData={sunday}
          dayLabel={i18n.t('sunday')}
        />
        <DayForm
          id='id-monday'
          setDayData={(newDayData: any) => {
            console.log("newDayData", newDayData);
            setMonday(newDayData)
            // Object.assign(operationTimesModified, { monday: newDayData, ...operationTimesModified })
            console.log("NModified", operationTimesModified);
          }}
          dayData={monday}
          dayLabel={i18n.t('monday')}
        />
        <DayForm
          id='id-tuesday'
          setDayData={(newDayData: any) => {
            console.log("newDayData", newDayData);
            setTuesday(newDayData)
            // Object.assign(operationTimesModified, { tuesday: newDayData, ...operationTimesModified })
            console.log("NModified", operationTimesModified);
          }}
          dayData={tuesday}
          dayLabel={i18n.t('tuesday')}
        />
        <DayForm
          id='id-wednesday'
          setDayData={(newDayData: any) => {
            console.log("newDayData", newDayData);
            setWednesday(newDayData)
            // Object.assign(operationTimesModified, { wednesday: newDayData, ...operationTimesModified })
            console.log("NModified", operationTimesModified);
          }}
          dayData={wednesday}
          dayLabel={i18n.t('wednesday')}
        />
        <DayForm
          id='id-thursday'
          setDayData={(newDayData: any) => {
            console.log("newDayData", newDayData);
            setThursday(newDayData)
            // Object.assign(operationTimesModified, { thursday: newDayData, ...operationTimesModified })
            console.log("NModified", operationTimesModified);
          }}
          dayData={thursday}
          dayLabel={i18n.t('thursday')}
        />
        <DayForm
          id='id-friday'
          setDayData={(newDayData: any) => {
            console.log("newDayData", newDayData);
            setFriday(newDayData)
            // Object.assign(operationTimesModified, { friday: newDayData, ...operationTimesModified })
            console.log("NModified", operationTimesModified);
          }}
          dayData={friday}
          dayLabel={i18n.t('friday')}
        />
        <DayForm
          id='id-saturday'
          setDayData={(newDayData: any) => {
            console.log("newDayData", newDayData);
            setSaturday(newDayData)
            // Object.assign(operationTimesModified, { saturday: newDayData, ...operationTimesModified })
            console.log("NModified", operationTimesModified);
          }}
          dayData={saturday}
          dayLabel={i18n.t('saturday')}
        />
        <button
          className="btn btn-primary mt-4"
          onClick={() => {
            let id = operationTimes ? operationTimes.id : undefined
            dispatch(saveOperationTime({
              operationTimesID: id, 
              editedOPTimes: {
                sunday,
                monday,
                tuesday,
                wednesday,
                thursday,
                friday,
                saturday,
                restaurant: restaurant.id
              }
            }))
          }}>{i18n.t("update-save-btn")}</button>
      </div>
      {/* OPERATION TIME FORM */}
    </div>
  )
}

export default OperationTimeForm

import i18n from '../../../i18n'

import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { api } from '../../../utils/api';
import { RootState } from '../../../configureStore'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs';
import { checkBusinessClosed } from '../../../redux/restaurantSlice';


const CloseNow = () => {
    const restaurant = useSelector((state: RootState) => state.restaurant)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch()

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const getBusinessClosed = () => {
        dispatch(checkBusinessClosed(restaurant))
    }
    useEffect(() => {
        
        getBusinessClosed()
    }, [])

    const openBusiness = () => {
        let action = i18n.t("confirm-action-open")
        //@ts-ignore
        if(window.confirm(i18n.t("confirm-close-business").format(action))){
            api.delete(`/closed-businesses/${restaurant.ClosedHour.id}`)
        .then(({data}) => {
            console.log("closed business", data)
            getBusinessClosed()
        })
        .catch((e) => {
            console.warn("could not get data", e)
        })
        }
        
    }
    const closeBusiness = (reason : string) => {
        api.post(`/closed-businesses`, {
            restaurant : restaurant.id,
            date : dayjs().format("YYYY-MM-DD"),
            reason

        })
        .then(({data}) => {
            console.log("closed business", data)
            getBusinessClosed()
        })
        .catch((e) => {
            console.warn("could not get data", e)
        })
    }

    const confirmCloseBusiness = (reason:string) => {
        let action = i18n.t("confirm-action-close");
        //@ts-ignore
        if(window.confirm(i18n.t("confirm-close-business").format(action))){
            closeBusiness(reason)
        }
    }

    return (
        <div>
            <h5>{i18n.t("close-now")}</h5>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle style={{
                    fontSize : 14
                }} caret>
                    {i18n.t("close-reason")}
                </DropdownToggle>
                <DropdownMenu style={{
                    fontSize : 14
                }}>
                    <DropdownItem onClick={() => confirmCloseBusiness(i18n.t("close-reason-technical"))}>{i18n.t("close-reason-technical")}</DropdownItem>
                    <DropdownItem onClick={() => confirmCloseBusiness(i18n.t("close-reason-overflow"))}>{i18n.t("close-reason-overflow")}</DropdownItem>
                    <DropdownItem onClick={() => confirmCloseBusiness(i18n.t("close-reason-other"))}>{i18n.t("close-reason-other")}</DropdownItem>
                </DropdownMenu>
            </Dropdown>

            {restaurant.isClosed ? <button onClick={openBusiness} style={{
                marginTop : 20
            }} 
                className="btn btn-success btn-lg">{restaurant.isClosed ? i18n.t("confirm-action-open-business") : i18n.t("confirm-action-closed")}</button> : null}
        </div>
    )
}

export default CloseNow

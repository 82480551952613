import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import i18n from '../../i18n'
import { RestaurantType } from '../../redux/restaurantSlice';
import { api } from '../../utils/api';
import { Color } from '../../utils/Colors';

type UserType = {
  appleLoginId: string;
  birthday: Date;
  blocked: boolean;
  businessManager: boolean
  confirmed: boolean
  created_at: Date;
  driverManager: boolean;
  email: string;
  firstName: string;
  gender: string;
  id: number;
  lastContribution: null
  lastName: null
  last_active: Date;
  mabaliOwner: boolean
  phone: string;
  provider: string;
  pushToken: string;
  restaurant_stories_manageds: RestaurantType[]
  restaurants: RestaurantType[];
  role: number;
  storiesManager: boolean;
  superAdmin: boolean;
  updated_at: Date;
  username: string;
}

const StoriesMember = () => {
  const [UserEmail, setUserEmail] = useState<string>("");
  const [FoundUser, setFoundUser] = useState<UserType | undefined>(undefined);
  const restaurant = useSelector((state: RootState) => state.restaurant)
  const [CurrentUsers, setCurrentUsers] = useState<UserType[]>([]);

  const getCurrentUsers = () => {
    api.get('/users?restaurant_stories_manageds.id=' + restaurant.id)
      .then(({ data }: { data: UserType[] }) => {
        setCurrentUsers(data)
      })
  }

  const searchForUser = () => {
    api.get('/users?email=' + UserEmail)
      .then(({ data }) => {
        const user = data.pop()
        console.log('found user', user)
        setFoundUser(user)
      })
  }

  const addUser = () => {
    let withNewRestaurant = FoundUser?.restaurant_stories_manageds?.map(r => r.id);
    if (!withNewRestaurant) {
      withNewRestaurant = []
    }
    withNewRestaurant.push(restaurant.id)
    console.log('withNewRestaurant', withNewRestaurant)
    api.put("/users/" + FoundUser?.id, {
      restaurant_stories_manageds: withNewRestaurant
    })
      .then(({ data }) => {
        alert(i18n.t("user-added"))
        setFoundUser(undefined)
        setUserEmail("")
        getCurrentUsers()
      })
  }

  const removeUser = (nonPopulutedUser: UserType) => {
    api.get("/users/" + nonPopulutedUser.id)
      .then(({ data: user } : {data : UserType}) => {
        console.warn('user---', user)
        let withRestaurantRemoved = user?.restaurant_stories_manageds?.filter(r => r.id != restaurant.id)
        console.log('user', user)
        console.log('withRestaurantRemoved', withRestaurantRemoved)
        api.put("/users/" + user?.id, {
          restaurant_stories_manageds: withRestaurantRemoved?.map(r => r.id)
        })
          .then(({ data }) => {
            alert(i18n.t("user-removed"))
            setFoundUser(undefined)
            setUserEmail("")
            getCurrentUsers()
          })
      })

  }

  const itsInMyRestaurant = (user: UserType) => {
    return !!user.restaurant_stories_manageds?.find((resta) => resta.id == restaurant.id)
  }

  useEffect(() => {

    getCurrentUsers();
  }, []);

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <h5>{i18n.t("find-user-by-exact-email")}</h5>
      <input value={UserEmail} placeholder={i18n.t("search-user")} onChange={(e) => {
        setUserEmail(e.target.value);
      }} style={{
        padding: 2,
        borderRadius: 5
      }} />
      <br />
      <button onClick={searchForUser} className='btn btn-primary'>{i18n.t("search-btn")}</button>

      {FoundUser ? <pre>
        <br />
        <h4>{i18n.t('found-user')}</h4>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: Color.purple,
          padding: 10,
          borderRadius: 5,
          alignItems: 'center'
        }}>
          <span style={{
            color: "#fff",
            marginLeft: 10,
            marginRight: 10
          }} >{FoundUser.firstName + ' ' + FoundUser.lastName} - {FoundUser.email}</span>
          <button onClick={addUser} className='btn btn-light'>{i18n.t('invite-user')}</button>
          {itsInMyRestaurant(FoundUser) ? <button onClick={() => removeUser(FoundUser)} className='btn btn-danger'>{i18n.t('remove-user')}</button> : null}
        </div>
      </pre> : null}
      <br />
      {CurrentUsers ? <pre>
        <br />
        <h4>{i18n.t('current-stories-manager-users')}</h4>
        {CurrentUsers.map(user => <div style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: Color.lighBlack,
          padding: 10,
          borderRadius: 5,
          alignItems: 'center'
        }}>
          <span style={{
            color: "#fff",
            marginLeft: 10,
            marginRight: 10
          }} >{user.firstName + ' ' + user.lastName} - {user.email}</span>
          <button onClick={() => removeUser(user)} className='btn btn-danger'>{i18n.t('remove-user')}</button>
        </div>)}
      </pre> : null}
    </div>
  )
}

export default StoriesMember
import dayjs from 'dayjs'
import numeral from 'numeral'
import React, { useState } from 'react'
import i18n from '../../i18n'
import { MenuItemType, OrderItemType, OrderType } from '../../redux/orderSlice'
import { Color } from '../../utils/Colors'
import BusinessCoin from '../../assets/images/business-coin.png'
import BolepoCoin from '../../assets/images/bolepo-coin.png'
const KitchenTicket = ({ orderItems, Order, OrderPayments }: { orderItems: Array<OrderItemType>, Order: OrderType | null, OrderPayments: any }) => {
    const getOrderType = (order: OrderType | any) => {
        if (order.delivery) {
            return i18n.t("order-type-delivery")
        } else if (order.delivery) {
            return i18n.t("order-type-takeout")
        } else if (order.scheduled) {
            return i18n.t("order-type-scheduled")
        }
    }
    const panelText = (string: any, ...args: any) => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }} className="fw-bold text-center">
                    {/*@ts-ignore*/}
                    {i18n.t(string).format("")}
                </span>
                {args}
            </span>)
    }
    return (
        <div style={{ fontSize: 10, direction: 'rtl', display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingTop: 30, paddingBottom: 20, height: 'auto' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }} className="text-center d-flex justify-content-center flex-column">
                {/*@ts-ignore*/}
                <span className="fw-bold text-center" style={{ fontWeight: 'bold' }}>{i18n.t("ticket-title").format(Order?.restaurant?.name)}</span>
                {/*@ts-ignore*/}
                <span className="fw-bold text-center" style={{ fontWeight: 'bold' }}>{i18n.t("ticket-approved-title").format(Order?.restaurant?.name, "KITCHEN")}</span>
                {/*@ts-ignore*/}
                <span><span className="fw-bold text-center" style={{ fontWeight: 'bold' }}>{i18n.t("date-and-time")}</span>{Order.created_at ? dayjs(Order?.created_at).format("DD/MM/YYYY HH:mm") : i18n.t("not-yet")}</span>
                {/*@ts-ignore*/}
                {panelText("estimated-preparation-time", Order?.preparationTime + " " + i18n.t("minutes"))}
                {/*@ts-ignore*/}
                <span><span className="fw-bold text-center" style={{ fontWeight: 'bold' }}>{i18n.t("order-details-order-id").format("")}</span>{Order?.id} <span className="fw-bold text-center" style={{ fontWeight: 'bold' }}>{i18n.t("order-details-order-type").format("")}</span>{getOrderType(Order)}</span>
                {/*@ts-ignore*/}
                {panelText("order-details-order-notes", (Order.notes || i18n.t("not-specified")))}

            </div>
            <table style={{ textAlign: 'center' }} className="table-ticket table table-bordered">
                <thead>
                    <tr>
                        <th>{i18n.t('item-label')}</th>
                        <th>{i18n.t('extras')}</th>
                        <th>{i18n.t('notes-and-selections')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        orderItems != null && orderItems.map((item: OrderItemType, index: any) => {
                            let menuItem: any = item.menu_item
                            return <tr key={index}>
                                {/*@ts-ignore*/}
                                <td>{menuItem["name_" + i18n.getLang()] || menuItem.name}</td>
                                <td>
                                    {
                                        <ul>
                                            {item.menu_extras.map((extra: any, key) => {
                                                {/*@ts-ignore*/ }
                                                return <li>{extra["name_" + i18n.getLang()] || extra.name} <span>{Order?.restaurant.currency || "$"}{numeral(extra.priceChange).format("0,0.0")}</span></li>
                                            })}
                                        </ul>
                                    }
                                </td>
                                <td>{
                                    <ul>
                                        {/*@ts-ignore*/}
                                        {Order?.cartObj.restaurant[Order.restaurant.id].map((itemCustom: any, key) => {

                                            // console.log("itemCustom.id", itemCustom)
                                            // console.log("item.id", item)

                                            if (itemCustom.id != item.menu_item.id) {
                                                return null
                                            }
                                            let Notes = []

                                            itemCustom?.Customized?.MultiOptions.forEach((multi: any) => {

                                                Notes.push(<li key={Notes.length}>
                                                    <span style={{
                                                        fontFamily: "Bold"
                                                    }}>({multi.qty})</span> - <span style={{
                                                        fontWeight: 700
                                                    }}>
                                                        {/*@ts-ignore*/}
                                                    </span>{multi["name_" + i18n.getLang()] || multi.name}
                                                </li>)
                                            })
                                            if (itemCustom.Customized?.Notes?.length > 0) {
                                                Notes?.push(<li key={Notes.length}>
                                                    <span>{itemCustom.Customized?.Notes}</span>
                                                </li>)
                                            }
                                            return Notes

                                        })}
                                    </ul>
                                }</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
            <style>
                {`
                    .table-ticket, th, td {
                        border: 1px solid black;
                        border-collapse: collapse;
                  }
                `}
            </style>
        </div>
    )
}

export default KitchenTicket

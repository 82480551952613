import React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import i18n from '../../../i18n'
import { api } from '../../../utils/api'


const OptionalItem = ({ optional, optionalItem, item, refresh, maxMin = false }: { optional: any, item: any, refresh: any, optionalItem: any, maxMin : boolean }) => {
    const [Name, setName] = useState(optionalItem.name || "")
    const [NameHe, setNameHe] = useState(optionalItem.name_he || "")
    const [Order, setOrder] = useState(optionalItem.order || "0")
    const [PosID, setPosID] = useState(optionalItem.pos_id || "0")
    const [PriceChange, setPriceChange] = useState(optionalItem.priceChange || 0)
    const history = useHistory()
    const [Loading, setLoading] = useState(false)
    const [Min, setMin] = useState(optionalItem.min || 0)
    const [Max, setMax] = useState(optionalItem.max || 0)


    const createOptional = () => {
        setLoading(true)
        api.post("/menu-optional-items", {
            name: Name,
            name_he: NameHe,
            order: parseInt(Order),
            priceChange: PriceChange,
            pos_id: PosID,
            max : Max,
            min : Min,
            restaurant: item.restaurant.id,
            menu_optional: optional.id,
            menu_item: item.id,
        }).then(({ data }) => {
            refresh && refresh()
            setLoading(false)
            //history.goBack()
        })
            .catch((e) => {
                console.log("e", e.response.data)
                if (e.response.data.statusCode == 500)
                    alert(i18n.t("duplicated-entry"))
                else
                    alert(i18n.t("successfully"))
            })
            .then(() => {
                setLoading(false)
            })
    }

    const updatOptional = () => {
        setLoading(true)
        api.put("/menu-optional-items/" + optionalItem.id, {
            name: Name,
            name_he: NameHe,
            order: parseInt(Order),
            pos_id: PosID,
            max : Max,
            min : Min,
            priceChange: PriceChange,
        }).then(({ data }) => {
            refresh && refresh()
            //history.goBack()
            alert(i18n.t("successfully"))
            setLoading(false)
            return null
        })
            .catch((e) => {
                console.error("error updating menu-optionals", e)
            })
            .then(() => {
                setLoading(false)
            })
    }
    const deleteOptional = () => {
        setLoading(true)
        api.delete("/menu-optional-items/" + optionalItem.id)
            .then(({ data }) => {
                refresh && refresh()
                //history.goBack()
                alert(i18n.t("successfully"))
                setLoading(false)
                return null
            })
            .catch((e) => {
                console.error("error deleting menu-optionals", e)
            })
            .then(() => {
                setLoading(false)
            })
    }

    const fieldStyle: any = {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 10,
        marginRight: 10
    }

    return <div className="" style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: "100%",
        backgroundColor: '#D3D3D3',
        padding: 10,
        marginTop: 10
    }}>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1
        }}>
            <div style={fieldStyle}>
                <span>{i18n.t("optional-name")}</span>
                <input type="text" defaultValue={Name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div style={fieldStyle}>
                <span>{i18n.t("optional-name-he")}</span>
                <input type="text" defaultValue={NameHe} onChange={(e) => setNameHe(e.target.value)} />
            </div>
            <div style={fieldStyle}>
                <span>{i18n.t("optional-price-change")}</span>
                <input type="text" style={{
                    width: 50
                }} defaultValue={PriceChange} onChange={(e) => setPriceChange(e.target.value)} />
            </div>
            {maxMin ? <> <div style={fieldStyle}>
                    <span>{i18n.t("optional-minimum")}</span>
                    <input type="number" style={{
                        width: 50
                    }} defaultValue={Min} onChange={(e) => setMin(parseFloat(e.target.value))} />
                </div>
                <div style={fieldStyle}>
                    <span>{i18n.t("optional-maximum")}</span>
                    <input type="number" style={{
                        width: 50
                    }} defaultValue={Max} onChange={(e) => setMax(parseFloat(e.target.value))} />
                </div>
            </> : null}
            <div style={fieldStyle}>
                <span>{i18n.t("optional-order")}</span>
                <input type="text" style={{
                    width: 50
                }} defaultValue={Order} onChange={(e) => setOrder(e.target.value)} />
            </div>
            <div style={fieldStyle}>
                <span>{i18n.t("pos-id")}</span>
                <input type="text" style={{
                    width: 50
                }} defaultValue={PosID} onChange={(e) => setPosID(e.target.value)} />
            </div>

        </div>

        {!Loading ? <div style={{
            // flex : 1
            // display: 'flex',
            // justifyContent: 'center'
        }}>
            {optionalItem.id && <button className="btn btn-danger" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deleteOptional()}>{i18n.t("delete")}</button>}
            <button className="btn btn-primary" onClick={() => optionalItem.id ? updatOptional() : createOptional()}>{i18n.t(optionalItem.id ? "save" : "add")}</button>
        </div> : <div className="spinner-border" role="status">
            <span className="visually-hidden">...</span>
        </div>}



    </div>
}

export default OptionalItem

//@ts-nocheck
import React, { useEffect, useState, useReducer } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import i18n from '../../i18n'
import { MenuItemType, OrderItemType, OrderStatus, OrderType } from '../../redux/orderSlice'
import { api, driverApi } from '../../utils/api'
import numeral from 'numeral'
import dayjs from 'dayjs'
import UpdateOrderModal from '../UpdateOrderModal'
import RejectModal from '../RejectModal'
import { Color, MabaliColors } from '../../utils/Colors'
import moment from 'moment'
import BusinessCoin from '../../assets/images/business-coin.png'
import BolepoCoin from '../../assets/images/bolepo-coin.png'

import ApproveModal from '../ApproveModal'
import io from 'socket.io-client'
import Constants from '../../Constants'
import relativeTime from 'dayjs/plugin/relativeTime'
import MainTicket from '../../containers/Tickets/MainTicket'
import KitchenTicket from '../../containers/Tickets/KitchenTicket'
import DriverTicket from '../../containers/Tickets/DriverTicket'
import ItemStickers from '../../containers/Tickets/ItemStickers'
import { Modal } from 'reactstrap'
import PrintTicketModal from '../../containers/Tickets/PrintTicketModal'
import MainTicketV2 from '../containers/Tickets/MainTicketV2'
import LabelSection from './LabelSection'
import { DATE_FORMAT, FULL_DATE_FORMAT, HOUR_FORMAT } from '../../utils/utils'
import { toMoney } from '../../currency'
import PrintButton from './PrintButton'
dayjs.extend(relativeTime)

const mapContainerStyle = {
    width: '100%',
    height: '100%'
}
const options = {
    disableDefaultUI: true,
    zoomControl: true
}

let driverOrderDetailsTimeout = null
let socket: any = null

const CardPayment = ({ text, value }) => {
    return <li className="list-group-item d-flex justify-content-between lh-condensed">
        <div className="d-flex flex-row align-items-center">
            <img src={"/images/24756.png"} alt="Credit card" style={{
                height: 20,
                color: Color.bolepoBlue,
                marginLeft: 10,
                marginRight: 10
            }} />
            <h6 className="my-0">****{text}</h6>
        </div>
        <span className="text-muted">{value}</span>
    </li>
}

const CoinPayment = ({ text, value, bolepo }) => {
    return <li className="list-group-item d-flex justify-content-between lh-condensed">
        <div className="d-flex flex-row align-items-center">
            <img style={{ width: 30, margin: 5 }} src={bolepo ? BolepoCoin : BusinessCoin} />
            <h6 className="my-0">{text}</h6>
        </div>
        <span className="text-muted">{value}</span>
    </li>
}

const ItemRow = ({ item, menuItem, Order }: { item: OrderItemType, menuItem: MenuItemType, Order: OrderType | null }) => {

    console.log("Order.cartObj ===>", Order.cartObj);
    console.log("Order.restaurant ===>", Order?.restaurant.id);

    return <li className="list-group-item" style={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: 18
    }}>
        <div style={{
            justifyContent: 'space-between',
            display: 'flex',
            direction: 'rtl'
        }}>
            {menuItem["name_" + i18n.getLang()] || menuItem.name}<span>{Order?.restaurant.currency || "₪"}{numeral(menuItem.price).format("0,0.0")}</span>
        </div>
        <div style={{
            fontSize: 14,
            display: 'flex',
            direction: 'rtl',
            flexDirection: 'column'
        }}>
            <span style={{ alignSelf: 'end' }}>{i18n.t("extras")}</span>
            <ul style={{
                display: 'flex',
                direction: 'rtl',
            }}>
                {item.menu_extras.map((extra, key) => {
                    return <li key={key}>{extra["name_" + i18n.getLang()] || extra.name} <span>{Order?.restaurant.currency || "$"}{numeral(extra.priceChange).format("0,0.0")}</span></li>
                })}
            </ul>
        </div>

        <div style={{
            fontSize: 14,
            display: 'flex',
            direction: 'rtl',
            flexDirection: 'column'
        }}>
            <span style={{ alignSelf: 'end' }}>{i18n.t("optionals-title")}</span>
            <ul style={{
                display: 'flex',
                direction: 'rtl',
            }}>
                {item.menu_optional_items.map((optional: any, key) => {
                    return <li key={key}><span style={{
                        fontWeight: 700,
                    }}>{
                            optional.menu_optional["name_" + i18n.getLang()] || optional.menu_optional.name} - </span>
                        {optional["name_" + i18n.getLang()] || optional.name}
                        <span>{Order?.restaurant.currency || "$"}{numeral(optional.priceChange).format("0,0.0")}</span>
                    </li>
                })}
            </ul>
        </div>
        <div style={{
            fontSize: 14,
            display: 'flex',
            direction: 'rtl',
            flexDirection: 'column'
        }}>
            <span style={{ alignSelf: 'end' }}>{i18n.t("notes")}</span>
            <ul style={{
                display: 'flex',
                direction: 'rtl',
                flexDirection: 'column'
            }}>
                {Order?.cartObj.restaurant[Order.restaurant.id].map((itemCustom: any, key) => {

                    // console.log("itemCustom.id", itemCustom)
                    // console.log("item.id", item)

                    if (itemCustom.id != item.menu_item.id) {
                        return null
                    }
                    let Notes = []

                    itemCustom?.Customized?.MultiOptions.forEach((multi) => {


                        Notes.push(<li key={Notes.length}>
                            <span style={{
                                fontFamily: "Bold"
                            }}>({multi.qty})</span> - <span style={{
                                fontWeight: 700
                            }}>
                            </span>{multi["name_" + i18n.getLang()] || multi.name}
                        </li>)
                    })

                    Notes?.push(<li key={Notes.length}>
                        <span>{itemCustom.Customized?.Notes}</span>
                    </li>)

                    return Notes

                })}
            </ul>
            <style>
                {`
                    ul{
                        padding-inline-start:1.3rem !important
                    }
                `}
            </style>
        </div>

    </li>
}

const ItemRowV2 = ({ item, menuItem, Order }: { item: OrderItemType, menuItem: MenuItemType, Order: OrderType | null }) => {

    // console.log("Order.cartObj ===>", Order.cartObj);
    // console.log("Order.restaurant ===>", Order?.restaurant.id);
    let Notes = []
    let Optional = []
    Order?.cartObj.restaurant[Order.restaurant.id].map((itemCustom: any, key) => {

        if (itemCustom.id != item.menu_item.id) {
            return null
        }

        console.log("itemCustom", itemCustom.name)
        console.log("Customized", itemCustom.Customized)

        itemCustom?.Customized?.MultiOptions.forEach((multi, index) => {

            Optional.push(
                <div key={index} className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
                    <span>{multi.qty}</span>
                    <span>{multi.menu_optional["name_" + i18n.getLang()] || multi.menu_optional.name} - {multi["name_" + i18n.getLang()] || multi.name}</span>
                    <span>{Order?.restaurant.currency || "$"}{numeral(multi.priceChange).format("0,0.0")}</span>
                </div>
            )
        })
        itemCustom?.Customized?.Options.forEach((multi, index) => {

            Optional.push(
                <div key={index} className="d-flex justify-content-between" style={{ marginBottom: 0 }}>

                    <span>{multi.menu_optional["name_" + i18n.getLang()] || multi.menu_optional.name} - {multi["name_" + i18n.getLang()] || multi.name}</span>
                    <span>{Order?.restaurant.currency || "$"}{numeral(multi.priceChange).format("0,0.0")}</span>
                </div>
            )
        })

        if (itemCustom.Customized?.Notes)
            Notes?.push(<div key={Notes.length} className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
                <span>{itemCustom.Customized?.Notes}</span>
            </div>)
    })
    return <div className="col mb-4">
        <div className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
            <strong style={{ fontSize: 18 }}>{menuItem["name_" + i18n.getLang()] || menuItem.name}</strong>
            <strong style={{ fontSize: 18 }}><span>{Order?.restaurant.currency || "₪"}{numeral(menuItem.price).format("0,0.0")}</span></strong>
        </div>
        {item.menu_extras.length ? [<div key={1001} className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
            <span>{i18n.t("extras-dots")}</span>
        </div>,
        item.menu_extras.map((extra, key) => {
            return <div key={key} className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
                <span>{extra["name_" + i18n.getLang()] || extra.name}</span>
                <span>{Order?.restaurant.currency || "$"}{numeral(extra.priceChange).format("0,0.0")}</span>
            </div>
        })]
            : null}
        {/* Aditionals Data */}

        {/* OptionalTitle Data */}
        {/* OptionalSelection Data */}
        {item.menu_optional_items.length ? [<div key={1003} className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
            <span>{i18n.t("optionals-title-dots")}</span>
        </div>,
            Optional]
            : null}



        {/* OptionalTitle Data */}
        {/* OptionalSelection Data */}

        {/* Notes Data */}
        {Notes.length > 0 ? <div>
            <div className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
                <span>{i18n.t("notes-dots")}</span>

            </div>
            {Notes} </div> : null}
        {/* Notes Data */}
    </div>
}
const statusTextStyle = (orderStatus: any) => {
    switch (orderStatus) {
        case "pending":
            return "btn-secondary"
        case "approved":
            return "btn-dark"
        case "making":
            return "btn-warning"
        case "ready":
            return "btn-info"
        case "delivered":
            return "btn-success"
        case "pickedup":
            return "btn-primary"
        default: return "btn-dark"
    }
}

type PrintType = 'print-all' | 'main-ticket' | 'kitchen-ticket' | 'driver-ticket' | 'item-stickers'

let getDetailsTimeout = null
const OrderDetails = () => {
    const history = useHistory()
    const params: { orderId: string } | undefined = useParams()
    const routeState: OrderType | any = history.location.state
    const [Order, setOrder] = useState<OrderType | null>(null)
    const [OrderItems, setOrderItems] = useState([])
    const [OrderPayments, setOrderPayments] = useState([])
    const [RejectModalFlag, setRejectModal] = useState(false)
    const [OrderStatus, setOrderStatus] = useState<OrderStatus>('making');

    const [DriverLocation, setDriverLocation] = useState(null)
    const [DriverOrderData, setDriverOrderData] = useState(null)

    const [Center, setCenter] = useState({
        lat: 32.085543403635036,
        lng: 34.780881135951304
    })

    const [{ driverReducer }, internalDispatch] = useReducer((state: any, action: any) => {
        if (action.type === 'USER_LOCATION') {

            let driverLocation = JSON.parse(action.message)
            console.log("gotDriverLocation", driverLocation)


            setDriverLocation(driverLocation)

            return state
        }
        return state
    }, { driverReducer: [] })

    useEffect(() => {

        socket = io(Constants.Socketio)
        return () => {
            socket.disconnect()
            // clearTimeout(timeoutOrders)
        }
    }, [])


    useEffect(() => {


        socket.on('/order/' + orderId + '/track', (data: any) => {


            internalDispatch({ type: 'USER_LOCATION', message: data })


        })

        return () => {
            socket.removeListener('/order/' + orderId + '/track')
            socket.disconnect()
        }
    }, [])


    // console.log("history.location.state", routeState)
    let orderId: any;
    if (params) {
        orderId = params.orderId
        // console.log("orderId", orderId)
    }

    // const cancelOrder = (message) => {
    //     console.log("Order cancel", message)
    //     api.put("/orders/" + Order.id + "/reject", { rejected_message: message })
    //         .then(({ data }) => {
    //             console.log("orderCanceled", data)
    //             setOrder(data)
    //             setRejectModal(false)
    //             history.replace("/orders")
    //         })
    //         .catch((e) => {
    //             console.warn("canceled order error", e)
    //         })
    // }

    // const onApprove = (data) => {
    //     console.log("orderAccepted", data)
    //     setOrder(data)

    // }

    useEffect(() => {
        if (getDetailsTimeout) {
            clearTimeout(getDetailsTimeout)
        }
        getDetailsTimeout = setTimeout(() => {
            // console.log("getDetails setTimeout");
            getDetails()
        }, 10000)
        return () => {
            if (getDetailsTimeout)
                clearTimeout(getDetailsTimeout)
        }
    }, [Order])

    const getDetails = () => {
        // console.log("getDetails");

        return api.get("/orders/" + orderId)
            .then(({ data }: { data: OrderType }) => {
                console.log("getting order details", data)
                setOrder(data)
                setOrderStatus(data.status)
                return data
            })
    }

    useEffect(() => {

        console.log("routeState is null")

        getDetails().then(data => {
            if (data.deliveryAddress)
                setCenter({
                    lat: parseFloat(data.deliveryAddress.latitude),
                    lng: parseFloat(data.deliveryAddress.longitude)
                })
        })
        api.get("/order-payments?order=" + orderId)
            .then(({ data }) => {
                console.log("getting order-payments details", data)
                setOrderPayments(data)
            })



        api.get("/order-items/performant?order=" + orderId)
            .then(({ data }) => {
                setOrderItems(data)
            })

    }, [])

    const onOrderStatusChange = (orderId: string | number, status: OrderStatus) => {
        console.log('orderId', orderId)
        console.log('status', status)
        if (window.confirm('עדכן מצב')) {
            setOrderStatus(status)
            api.put(`/orders/${orderId}/status`, {
                status
            })
                .then(({ data }) => {
                    console.log("updated Successfully", data)
                    fetchOrders();
                })
                .catch((e) => {
                    console.warn("error updating")
                })
        }
    }


    const onUpdated = (order: OrderType) => {
        setOrder(order)
    }
    const destinationTime = () => {
        return moment(Order?.created_at).add(moment.duration('00:45')).endOf('seconds').fromNow()
    }

    const printTicket = (printType: PrintType = 'item-stickers') => {
        console.log("printTicket", printType)
        // let content = document.getElementById(printType);
        // let pri = document.getElementById("ifmcontentstoprint").contentWindow;
        // pri.document.open();
        // pri.document.write(content.innerHTML);
        // pri.document.close();
        // pri.focus();
        // pri.print();

        var divContents = document.getElementById(printType).innerHTML;
        var printWindow = window.open('', '', 'height=200,width=400');
        printWindow.document.write('<html><head><title>' + printType + '</title>');
        printWindow.document.write('</head><body >');
        printWindow.document.write(divContents);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    }
    const getDriverDetails = () => {
        driverOrderDetailsTimeout = setTimeout(() => {
            getDriverDetails()
        }, 20000)

        if (Order && Order.bolepoDriver)
            driverApi.get("/orders/details/" + orderId)
                .then(({ data }) => {
                    // console.log("gotDriverDetails", data)
                    if (data.length)
                        setDriverOrderData(data[0])
                })
                .catch((e) => {
                    console.warn("could not got Driver details", e)
                })
    }

    useEffect(() => {

        if (DriverOrderData && DriverOrderData.driver) {
            setDriverLocation(DriverOrderData.driver.currentlocation)
        }
    }, [DriverOrderData])

    useEffect(() => {
        getDriverDetails()
        return () => {
            if (driverOrderDetailsTimeout) {
                clearTimeout(driverOrderDetailsTimeout)
            }
        }
    }, [])

    useEffect(() => {
        if (DriverLocation) {
            console.log("DriverLocation", DriverLocation)
            console.log(`/images/driver-marker-${Math.floor(DriverLocation.location.heading)}.png`)
        }


    }, [DriverLocation])

    const RejectButton = (<button
        disabled={RejectModalFlag}
        onClick={() => {
            setRejectModal(true)
        }}
        className={"btn btn-danger"}
        style={{
            display: 'flex',
            flex: 1,
            height: 60,
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 30,
            borderRadius: 0
        }}>
        {i18n.t("reject")}
    </button>)

    if (Order && Order.cancel) {
        return <div className="container-fluid">
            <div className="alert alert-danger" role="alert">
                {i18n.t("order-already-canceled")}
            </div>
        </div>
    }

    const getOrderType = (order) => {
        if (order.delivery) {
            return i18n.t("order-type-delivery")
        } else if (order.takeout) {
            return i18n.t("order-type-takeout")
        } else if (order.scheduled) {
            return i18n.t("order-type-scheduled")
        }
    }

    const panelText = (string, ...args) => {
        return <p style={{
            marginTop: 5,
            marginBottom: 5,
            fontSize: 18
        }}>{i18n.t(string).format(...args)}</p>
    }

    if (!Order) {
        return null
    }

    let DriverDetails = null

    let divider = <div style={{
        height: 1,
        width: "100%",
        backgroundColor: "#fff",
        marginTop: 10,
        marginBottom: 10
    }} />
    if (Order.yandexRsp) {
        let info = Order.yandexClaimInfo
        // "performer_info": {
        //     "car_color": "белый",
        //     "car_model": "Bike Courier",
        //     "car_number": "7899134",
        //     "legal_name": "Get Moto Finance LTD",
        //     "courier_name": "לוזון יהונתן",
        //     "car_color_hex": "FAFBFB",
        //     "transport_type": "car"
        //   }
        DriverDetails = <div>
            {panelText("order-details-order-driver-name", info.performer_info.courier_name)}
            {panelText("order-details-order-driver-id", info.performer_info.car_number)}
            {panelText("order-details-order-driver-phone", info.driver_contact ? info.driver_contact.phone : "")}
            {divider}
        </div>
    } else {
        DriverDetails = <div>
            {panelText("order-details-order-driver-name", (Order.bolepoDriver || Order.yangoDriver ? Order.driverName || i18n.t("not-yet") : i18n.t("business-driver")))}
            {panelText("order-details-order-driver-id", (Order.driverId) || i18n.t("not-yet"))}
            {panelText("order-details-order-driver-phone", (Order.driverPhone) || i18n.t("not-yet"))}
            {divider}
        </div>
    }

    let orderItems = Order.cartObj.restaurant[Order.restaurant.id]

    return <div style={{
        display: 'flex',
        flex: 1,
        paddingTop: 50,
    }}>
        <div className='container' style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <div style={{
                width: 377,
                backgroundColor: MabaliColors.lightGray,
                borderRadius: 10,
                direction: 'rtl'
            }}>

                <div style={{
                    padding: 14,
                    borderRadius: 12,
                }}>
                    <LabelSection label={'סוג משלוח'} value={Order.bolepoDriver ? 'משלוח מבאלי' : 'נהג עסקי'} />
                    <LabelSection label={'תאריך הזמנה'} value={dayjs(Order.created_at).format(DATE_FORMAT)} />
                    <LabelSection label={'שעת הזמנה'} value={dayjs(Order.created_at).format(HOUR_FORMAT)} />

                    <div style={{
                        display: 'flex',
                        direction: 'rtl',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            flex: 1
                        }}>
                            <span style={{
                                fontSize: 16,
                            }}>סטטוס ההזממנה</span>
                        </div>
                        <div style={{
                            flex: 1
                        }}>
                            <select onChange={(e) => {
                                onOrderStatusChange(Order.id, e.target.value)
                            }} style={{
                                padding: 4
                            }} value={OrderStatus}>

                                {["pending",
                                    "approved",
                                    "making",
                                    "ready",
                                    "delivered",
                                    "canceled",
                                    "rejected",
                                    "pickedup"].map((status) => {
                                        return <option key={status} value={status} >{i18n.t("status-" + status)}</option>
                                    })}
                            </select>
                        </div>

                    </div>
                    <LabelSection label={'Status timestamp'} value={dayjs(Order.updated_at).format(FULL_DATE_FORMAT)} />
                    {Order.bolepoDriver && Order.driverAccepted || true ? <div style={{
                        // backgroundColor : MabaliColors.darkGray,
                    }}>
                        <LabelSection label={'Driver ID'} value={Order.driverId ?? 'N/A'} />
                        <LabelSection label={'Driver Name'} value={(Order.driverName) ?? 'N/A'} />
                        <LabelSection label={'Driver Phone'} value={(Order.driverPhone ?? 'N/A ')} />
                    </div> : null}

                    <div style={{
                        marginTop: 5,
                        marginBottom: 5
                    }}>
                        <span style={{
                            fontFamily: 'Bold',
                            fontSize: 16,

                        }}>
                            {('סה״כ לתשלום {0}').format(toMoney(Order.total))}
                        </span>
                    </div>
                </div>


                <div style={{
                    height: 3,
                    width: "100%",
                    backgroundColor: 'white'
                }} />

                <div style={{
                    paddingBottom: 20,

                    marginTop: 10

                }}>
                    <span style={{
                        fontFamily: 'Bold',
                        fontSize: 20,
                        marginLeft: 30,
                        marginRight: 30
                    }}>הדפס</span>
                    <PrintButton text={'2x קבלות ראשיות 2x קבלות מטבח וקבלה של נהג משלוח'} onPress={() => printTicket('print-all')} />
                    <PrintButton text={'2x קבלות ראשיות (לעסק וללקוח)'} onPress={() => console.log('console print')} />
                    <PrintButton text={'2x קבלות מטבח'} onPress={() => console.log('console print')} />
                    <PrintButton text={'קבלה של נהג משלוח'} onPress={() => console.log('console print')} />
                    <PrintButton text={'להדפסת מדבקות עבור כל פריט בהזמנה'} onPress={() => console.log('console print')} />
                    <div style={{
                        marginLeft: 30,
                        marginRight: 30
                    }}>
                        <span>אנא ודא שאתה משתמש בנייר המדפסת/המדבקות הנכונים אם</span>
                        <span>תלחץ על אפשרות זו.</span>
                    </div>
                </div>

            </div>
            <div style={{
                flex: 1,
                display: 'flex',
                direction: 'rtl',
                zIndex: -1

            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,

                    height: '94vh',
                    // overflowY: 'scroll',
                    flexWrap: 'wrap',

                }}>
                    <span style={{
                        color: MabaliColors.pink,
                        fontFamily: 'Bold',
                        fontSize: 28
                    }}>הזמנה</span>

                    <span style={{
                        fontSize: 24,
                        fontFamily: 'Bold',
                    }}>{`מספר הזנה #${Order.id}`}</span>
                    <span style={{
                        fontSize: 24,
                        fontFamily: 'Bold',
                    }}>{Order.users_permissions_user.firstName + ' ' + Order.users_permissions_user.lastName}</span>

                    <span style={{
                        fontSize: 16,
                        fontFamily: 'SemiBold',
                    }}>{Order.deliveryAddressText}</span>
                    <span style={{
                        fontSize: 16,
                        fontFamily: 'SemiBold',
                    }}>{Order.phone}</span>


                    {orderItems.map((food, idx) => {

                        let foodInfo = [<div key={food.id + idx + 100} style={{
                            display: 'flex',
                            width: 170,
                            flexDirection: 'column',
                            // border: '1px solid black'
                        }}>
                            <span style={{
                                fontFamily: 'Bold',
                                fontSize: 24
                            }}>{food.menu_category.name}</span>
                            <span style={{
                                fontFamily: 'Bold',
                                fontSize: 20
                            }}>{food.name_he}</span>
                            <span style={{
                                fontFamily: 'Bold',
                                fontSize: 16
                            }}>{food.description_he}</span>
                            <span style={{
                                fontFamily: 'SemiBold',
                                fontSize: 16
                            }}>{food.Customized.Extras.map((extra) => extra.name_he).join(',')}</span>
                            <span style={{
                                fontSize: 20,
                                color: MabaliColors.pink,
                                fontFamily: 'Bold'
                            }}>
                                {toMoney(food.price)}
                            </span>


                        </div>]

                        foodInfo.push(food.Customized.Options.map((option, idx) => {
                            return <div key={option.id} style={{
                                display: 'flex',
                                width: 170,
                                flexDirection: 'column',
                            }}>
                                <span style={{
                                    fontSize: 20,
                                    fontFamily: 'Bold'
                                }}>{option.menu_optional.name_he}</span>
                                <span>{option.name_he}</span>
                            </div>
                        }))
                        return foodInfo
                    })}

                </div>


            </div>
        </div>
        <div className="col-12 w-50" style={{
            display: 'none'
        }} >
            <div id="print-all">
                <div id="main-ticket"><MainTicket OrderPayments={OrderPayments} Order={Order} orderItems={OrderItems} /></div>
                <br></br>
                <div id="kitchen-ticket"><KitchenTicket OrderPayments={OrderPayments} Order={Order} orderItems={OrderItems} /></div>
                <br></br>
                <div id="driver-ticket"><DriverTicket OrderPayments={OrderPayments} Order={Order} orderItems={OrderItems} /></div>
                <br></br>
            </div>
            <div id="item-stickers"><ItemStickers OrderPayments={OrderPayments} Order={Order} orderItems={OrderItems} /></div>
        </div>
    </div>

}

export default OrderDetails
import React, { useState } from 'react'
import i18n from '../../i18n'
import { OrderType } from '../../redux/orderSlice';
import { api } from '../../utils/api'
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import { RestaurantType } from '../../redux/restaurantSlice';
import { RootState } from '../../configureStore';
import { useSelector } from 'react-redux';
import { Color } from '../../utils/Colors';
dayjs.extend(utc)
dayjs.extend(timezone)

declare type renderFunction = (x: any) => JSX.Element;

export interface User {
    _id: string | number;
    name?: string;
    avatar?: string | renderFunction;
}
export interface Reply {
    title: string;
    value: string;
    messageId?: any;
}
export interface QuickReplies {
    type: 'radio' | 'checkbox';
    values: Reply[];
    keepIt?: boolean;
}

export interface IMessage {
    _id: string | number;
    text: string;
    createdAt: Date | number;
    user: User;
    image?: string;
    video?: string;
    audio?: string;
    system?: boolean;
    sent?: boolean;
    received?: boolean;
    pending?: boolean;
    quickReplies?: QuickReplies;
}

const generateChatMessage = (message: string, restaurant: RestaurantType | undefined, driverId: number | undefined) => {
    const iMessage: IMessage = {
        _id: uuidv4(),
        text: message,
        createdAt: dayjs().utc().toDate(),
        user: {
            _id: restaurant?.id || driverId!
        }
    };

    return iMessage
}

const MessageInput = ({ order, onRefresh, driverId, apiKey }:
    { order: OrderType | undefined, onRefresh: () => void, driverId?: number, apiKey?: string | null; }) => {
    const [Message, setMessage] = useState("")
    const restaurant = useSelector((state: RootState) => state.restaurant)

    const securityHeaders = apiKey ? {
        withCredentials: false,
        headers: {
            'x-clientsecret': apiKey
        }
    } : api.defaults.headers

    const sendOrderMessage = async () => {
        const message = generateChatMessage(Message, restaurant, driverId);
        console.log("sendOrderMessage", Message)
        if(!Message){
            return;
        }
        const params = {
            order: order?.id,
            client: order?.users_permissions_user.id,
            message,
            driverChat : driverId != undefined
        }

        console.log('sendOrderMessage', params);

        return api.post(`/chat-agents`, params, securityHeaders)
            .then(({ data }) => {
                setMessage("")
                console.log("message sent", data)
                onRefresh && onRefresh();
                return data
            })
    }
    const onSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            sendOrderMessage()
        }
    }
    return (
        <div className='shadow-sm' style={{
            width: "98%",
            // height : 60,
            display: 'flex',
            flexDirection: 'row',
            marginLeft: "1%",
            marginRight: "1%",
            paddingTop: 5,
            paddingBottom: 5,
            marginBottom: 10,
            border: '1px solid #333',
            borderRadius: 8,
            backgroundColor: "#fff",
        }}>
            <input value={Message} onChange={(e) => {
                setMessage(e.target.value)
            }} onKeyDown={onSubmit} style={{
                width: "100%",
                border: 'none',
                fontSize: 18,
                marginLeft: 5,
                marginRight: 2.5
            }} />
            <button className='btn' onClick={sendOrderMessage} style={{
                fontSize: 18,
                backgroundColor: Color.pink,
                color: "#fff",
                marginLeft: 2.5,
                marginRight: 5
            }}>
                {i18n.t('send')}
            </button>
        </div>
    )
}

export default MessageInput
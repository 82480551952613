import numeral from "numeral"
export const Shekel = "₪"

export enum CurrencyType {
  shekel = 'shekel',
  usd = 'usd',
  none = 'none'
}

export const toMoney = (value: number | string, options?: { currency?: CurrencyType, decimals?: boolean } ) => {
  let decimalFormat = options?.decimals ? '.00' : ''
  let moneySign = Shekel;
  switch (options?.currency) {
    case CurrencyType.shekel:
      moneySign = Shekel;
      break;
    case CurrencyType.usd:
      moneySign = '$'
      break;
    case CurrencyType.none:
      moneySign = '';
      break;
    default:
      moneySign = Shekel;
      break;
  }
  return `${moneySign}` + numeral(value).format(`0,0${decimalFormat}`)


}
import { debounce } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Progress, Spinner } from 'reactstrap'
import i18n from '../i18n'
import { OrderType } from '../redux/orderSlice'
import { api } from '../utils/api'
import { MabaliColors } from '../utils/Colors'
import { DriverType } from './NewOrders/DriverSelection'

//@ts-ignore
let modalView = null;
let approvedClicked = false
const ApproveModal = ({
    Order,
    onApprove,
    PreparationMinute,
    SelectedDriverType
}:
    {
        Order?: OrderType;
        onApprove: (order: OrderType) => void;
        PreparationMinute: number;
        SelectedDriverType: DriverType
    }) => {

    const history = useHistory()

    const [loading, setLoading] = useState<boolean>(false);

    //const initialType = Order?.restaurant.bolepoDriver ? "bolepo" : "business"
    // const [DriverType, setDriverType] = useState(!Order.takeout ? initialType : "business")

    useEffect(() => {
        //@ts-ignore
        modalView = new bootstrap.Modal(document.getElementById('approveModal'), {})
    }, [])

    useEffect(() => {
        //@ts-ignore
        feather.replace()
    }, [])


    const updateStatus = debounce(async () => {
        console.log("updateStatus", DriverType, approvedClicked)


        if (approvedClicked) {
            return;
        }
        setLoading(true);
        approvedClicked = true
        try {
            // await api.post("/orders/" + Order?.id + "/pay", {
            //     paymentInfo: Order?.paymentObj
            // })

            const { data } = await api.put("/orders/" + Order?.id + "/accept", {
                bolepoDriver: SelectedDriverType === "bolepo" ? true : false,
                yangoDriver: false,
                preparationTime: PreparationMinute,
                paymentInfo: Order?.paymentObj
            })
            history.push('/orders')
            onApprove(data)


            approvedClicked = false;
            //@ts-ignore
            modalView.toggle()
            setLoading(false);


        } catch (e: any) {
            approvedClicked = false;
            setLoading(false);
            console.log("e.response", e.response);
            if (e.response.status === 500) {
                //@ts-ignore
                modalView.toggle()
                return alert(i18n.t("error-updating-status"))
            }
            let message = e.response.data?.message || "error-updating-status"
            alert(i18n.t(message))

        }

    }, 500)

    console.log("PreparationMinute ----", PreparationMinute)

    return (
        <div id="approveModal" className="modal" tabIndex={-1}>
            <div className="modal-dialog" style={{
                marginTop: 100
            }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 style={{
                            color: MabaliColors.black
                        }} className="modal-title">{i18n.t("approve-order-driver")}</h4>
                        <button type="button" className="btn-close"
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            {Order && !Order.takeout ? <h4 style={{
                                backgroundColor: MabaliColors.pink,
                                padding: 10,
                                marginBottom: 0,
                                marginTop: 0,
                                color: MabaliColors.white,
                                border: '1px solid #333'
                            }}>
                                {SelectedDriverType == DriverType.bolepo ? i18n.t("bolepo-driver") : i18n.t("business-driver")}
                            </h4> : null}

                            <h4 style={{
                                backgroundColor: MabaliColors.pink,
                                padding: 10,
                                marginTop: 2,
                                color: MabaliColors.white,
                                border: '1px solid #333'
                            }}>
                                {`${i18n.t("preparation-time")} ${PreparationMinute}`}

                            </h4>


                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{i18n.t("close")}</button>
                        <button type="button" className="btn " style={{
                            backgroundColor: MabaliColors.pink,
                            color: MabaliColors.white
                        }} onClick={updateStatus}>
                            {loading ? <Spinner color='black' /> : i18n.t("save")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApproveModal

import Axios, { AxiosInstance } from 'axios'

declare interface CustomAxiosInstance extends AxiosInstance {
    getMedia?: (media: { url: string } | any) => string,
    serializeJSON? : (data : any) => string
}
const api:CustomAxiosInstance = Axios.create({
    baseURL : process.env.REACT_APP_API_URL
})
const driverApi:CustomAxiosInstance = Axios.create({
    baseURL : process.env.REACT_APP_DRIVER_URL
})


export const storeJwtToken = (token:string) => {
  try {
    localStorage.setItem('@jwtToken', token)
  } catch (e) {
    // saving error
  }
}

export const clearJwtToken = () => {
  try {
    localStorage.setItem('@jwtToken', "")
    delete api.defaults.headers["Authorization"]
  } catch (e) {
    // saving error
  }
}

export const getJwtToken = () => {
  try {
    const value :string | null = localStorage.getItem('@jwtToken')
    if(value !== null) {
      // value previously stored
      return value
    }
  } catch(e) {
    // error reading value
    return null
  }
}

export const setAuthorization = (jwtToken:string) => {
  console.log("setting Auth ", jwtToken)
  if(jwtToken)
    api.defaults.headers["Authorization"] = "Bearer " + jwtToken
}

//menual.getMedia = 
api.getMedia = (media: { url: string }) => {

  if (!media)
    return " "
  const url = api.defaults.baseURL + media.url
  //console.log("getMedia url = ", url)
  return url;
}


export enum StrapiImageFormatSize {
  large = 'large',
  small = 'small',
  medium = 'medium',
  thumbnail = 'thumbnail',
}

type StrapiImageFormats = {
  [key in StrapiImageFormatSize]: StrapiImageData
}

export enum StrapiImageMime {
  IMAGE_JPEG = '"image/jpeg"',
  VIDEO_MP4 = '"video/mp4"'
}

export type StrapiImageData = {
  "ext": string,
  "hash": string;
  "height": number;
  "mime": StrapiImageMime.IMAGE_JPEG;
  "name": string;
  "path"?: string;
  "size": number;
  "url": string;
  "width": number;
}

export type StrapiImage = {
  "alternativeText": string;
  "caption": string;
  "created_at": string;
  "ext": string;
  formats?: StrapiImageFormats,
  "hash": string;
  "height": number;
  "id": number;
  "mime": StrapiImageMime.IMAGE_JPEG | StrapiImageMime.VIDEO_MP4;
  "name": string;
  "previewUrl"?: string;
  "provider": string;
  "provider_metadata"?: string;
  "size": number;
  "updated_at": Date;
  "url": string;
  "width": number;
}


export type StrapiMedia = StrapiImage

export {
    api,
    driverApi
}
import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import {
  useParams
} from "react-router-dom";
import { RootState } from '../../configureStore';
import { ChatType } from '../../redux/orderSlice';
import { api } from '../../utils/api';
import { Color } from '../../utils/Colors';
import ChatBody from './ChatBody';
import ChatTopOrder from './ChatTopOrder';
import MessageInput from './MessageInput';

let refreshChatTimeout: NodeJS.Timeout | undefined;
export function OrderChat() {
  const [messages, setMessages] = useState([]);

  const [CurrentChat, setCurrentChat] = useState<ChatType | null>(null);
  const auth = useSelector((state: RootState) => state.auth)

  let { id }: any = useParams();

  const getUserChat = () => {
    api.get(`/chat-agents/${id}?driverChat=false`)
      .then(({ data }) => {
        console.log("data", data)
        const currentChat = data
        setCurrentChat(currentChat);
        setMessages(currentChat.chat || [])

      })
      .catch((e) => {
        console.warn("could not get messages", e.response);
      })
  }
  useEffect(() => {
    if (refreshChatTimeout) {
      clearInterval(refreshChatTimeout)
      refreshChatTimeout = undefined
    }

    refreshChatTimeout = setInterval(() => {
      getUserChat();
    }, 2000)

    getUserChat();

    return () => {
      if (refreshChatTimeout)
        clearInterval(refreshChatTimeout);
    }
  }, [])


  return (
    <div className='container-fluid' style={{
      flex: 1,
      width: "100%",
      overflowY: 'scroll',
      //  height :"90vh",
      backgroundColor: Color.lightGray
    }}>
      <ChatTopOrder order={CurrentChat?.order} />
      <ChatBody messages={CurrentChat?.chat} />
      <MessageInput order={CurrentChat?.order} onRefresh={getUserChat} />
    </div>
  )
}

export default OrderChat
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../configureStore'
import i18n from '../../../i18n'
import { RestaurantType } from '../../../redux/restaurantSlice'
import { api } from '../../../utils/api'
import { Color } from '../../../utils/Colors'
import { MenuCategory, MenuParentCategoryType } from '../../MenuCategories/MenuCategories'


const CategoryForm = ({category, restaurant, ParentCategories, setDoUpdate} : {category : MenuCategory | any, restaurant : RestaurantType, ParentCategories : Array<MenuParentCategoryType>, setDoUpdate : (value:any) => void}) => {
      const [Form, setForm] = useState(category)
      const [ImageFile, setImageFile] = useState<Blob | null>(null)

    const handleImage = (e : any, category : MenuCategory) => {
        const file = (e.target.files[0])

        setImageFile(file)
        if(category.id == -1){
            return
        }
        const formData = new FormData()

        
        formData.append("files.photo", file)
        formData.append("data", JSON.stringify(category))
        

        api.put(`/menu-categories/${category.id}`, formData)
        .then(({data}) => {
            console.log("update imagge", data)
            setDoUpdate((prevVal:number) => prevVal + 1)
            alert("Image Updated")
        }).catch((e) => {
            console.warn("failed image", e)
            alert("Error updating image")
        })
    }

    const saveCategory = (category : any) => {

        delete category.photo

        let parentCategoriesDOM = document.getElementById(`select-${category.id}`)?.childNodes;
        let menu_parent_categories : Array<number> = []
        parentCategoriesDOM?.forEach((node : any) => {
            
            if(node.selected){
                menu_parent_categories.push((node.value));
            }
        })
        category.menu_parent_categories = menu_parent_categories

        api.put(`/menu-categories/${category.id}`, Form)
        .then(({data}) => {
            console.log("updated Parent Category", data)
            setDoUpdate((prevVal : number) => prevVal + 1)
            
        }).catch((e) => {
            console.warn("failed updating Parent Category", e)
            // alert("Error updating image")
        })
    }

    const createCategory = (item : any) => {

        delete item.photo

        const formData = new FormData()
        if(ImageFile){
            formData.append("files.photo", ImageFile)
            
        }

        let parentCategoriesDOM = document.getElementById(`select-${category.id}`)?.childNodes;
        let menu_parent_categories : Array<number> = []
        parentCategoriesDOM?.forEach((node : any) => {
            
            if(node.selected){
                menu_parent_categories.push((node.value));
            }
        })

        formData.append("data", JSON.stringify({
            "name": Form.name,
            "description": Form.description,
            "restaurant": restaurant.id,
            "name_he": Form["name_he"],
            "description_he": Form["description_he"],
            menu_parent_categories
        }))

        api.post(`/menu-categories`, formData)
        .then(({data}) => {
            console.log("createCategory ", data)
            setDoUpdate((prevVal : number) => prevVal + 1)
            
        }).catch((e) => {
            console.warn("failed createCategory", e)
            // alert("Error updating image")
        })
        
        
    }

    const onChangeText = (value :string, name:string) => {
        let tempForm : any = Object.assign({}, Form)
        tempForm[name] = value
        setForm(tempForm)
    }

    const deleteCategory = (item :MenuCategory) => {
        if(window.confirm(i18n.t("confirm-delete"))){
            api.delete(`/menu-categories/${item.id}`)
            .then(({data}) => {
                console.log("deleteCategory ", data)
                setDoUpdate((prevVal : number) => prevVal + 1)
                
            }).catch((e) => {
                console.warn("failed deleteCategory", e)
                // alert("Error updating image")
            })
        }
        
        
    }


      return <div style={{
          display : 'flex',
          flexDirection : 'column',

      }}>
          {/* <img className="img-fluid" style={{
                height : 100,
                width : 100,
                backgroundColor : Color.darkGray
            }} src={ImageFile ? URL.createObjectURL(ImageFile) : api.getMedia!(category.photo)} />
        <input type='file' id='multi' onChange={(e) => handleImage(e, category)} /> */}
        <br />
        <span>{i18n.t("parent-category-form-name")}</span>
        <input className="form-control" onChange={(e) => onChangeText(e.target.value, "name")} defaultValue={category.name} />
        <span>{i18n.t("parent-category-form-name-he")}</span>
        <input className="form-control" onChange={(e) => onChangeText(e.target.value, "name_he")} defaultValue={category.name_he} />
        <span>{i18n.t("parent-category-form-order")}</span>
        <input className="form-control" onChange={(e) => onChangeText(e.target.value, "order")} defaultValue={category.order} />
        <span>{i18n.t("parent-category-form-description")}</span>
        <textarea className="form-control" onChange={(e) => onChangeText(e.target.value, "description")} defaultValue={category.description} />
        <span>{i18n.t("parent-category-form-description-he")}</span>
        <textarea className="form-control" onChange={(e) => onChangeText(e.target.value, "description_he")} defaultValue={category.description_he} />
        <span>{i18n.t("parent-category-form-parent-cat")}</span>
        <select id={`select-${category.id}`} className="form-select" onChange={(e) => {
            console.log("form-select", e.target)
        }} multiple aria-label="multiple select example">
            {ParentCategories.map((parentCategory : MenuParentCategoryType, key) => {
                let selected = false

                category.menu_parent_categories && category.menu_parent_categories.forEach((cat : any) => {
                    if(cat.id == parentCategory.id)
                        selected = true
                })
                 {/* @ts-ignore */}
                return <option selected={selected} key={key} value={parentCategory.id}>{parentCategory["name_" + i18n.getLang()] || parentCategory.name}</option>
            })}
            
        </select>
        <button style={{
            marginTop : 20
        }} onClick={() => category.id == -1 ? createCategory(category) : saveCategory(category)} className="btn btn-primary">{i18n.t("save")}</button>

        {category.id != -1 && <button style={{
            marginTop : 20,
            marginLeft : 20,
            marginRight : 20
        }} onClick={() => deleteCategory(category)} className="btn btn-danger">{i18n.t("delete")}</button>}
      </div>
  }

  export default CategoryForm
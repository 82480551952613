import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import QueryString from 'qs';
import { MenuCategory } from '../pages/MenuCategories/MenuCategories';
import { api, StrapiImage } from '../utils/api';
import { StrapiUserCustomType, UserAddressType } from './authSlice';
import { RestaurantType } from './restaurantSlice';

export const getOrders = createAsyncThunk(
  'order/fetch',
  async (url: string) => {
    const response = await api.get(url);
    return response.data;
  }
);

export const getPendingOrderAxios = async (params: { restaurant: number }) => {
  const query = QueryString.stringify({
    _where: [{ restaurant: params.restaurant }, { status: 'pending' }],
  });
  //let orderDate = dayjs().add(24,'hour').format("YYYY-MM-DD HH:mm:ss")
  const response = await api.get(
    `orders?${query}&cancel=false&_limit=1&_sort=created_at:ASC`
  );
  console.log('getPendingOrderAxios fetch response', response.data);
  return response.data;
}

export const getPendingOrders = createAsyncThunk('order/fetchPending',
  async (params: { restaurant: number }) => {
    return await getPendingOrderAxios(params)
  }
);

export type ChatMessage = {
  "_id": string;
  "text": string;
  "user": {
    "_id": number;
  },
  "image"?: string;
  "createdAt": Date
}

export type ChatType = {
  id: string;
  chat: ChatMessage[];
  order: OrderType;
  newMessageBy: string;
  newDriverMessageBy: string;
}

export type OrderStatus = 'pending' | 'approved' | 'making' | 'ready' | 'delivered' | 'pickedup' | 'rejected' | 'completed' | 'canceled'

export const ORDER_TYPE = {
  takeout: "takeout",
  delivery: "delivery",
  scheduled: "scheduled",
  scheduledPickup: "scheduledpickup"
}


export type ExtraType = {
  priceChange: string;
  name_he: string;
  name: string;
  id: number;
}

export type OptionType = {
  priceChange: string;
  menu_optional: {
    name_he: string;
    name: string;
  };
  name_he: string;
  name: string;
}

export type MultiOptionType = {
  priceChange: string;
  name_he: string;
  name: string;
  qty: number;
}

export type CustomizedType = {
  Options: OptionType[],
  Extras: ExtraType[],
  MultiOptions: MultiOptionType[],
  Notes: string;
}

export type FoodMenuCategory = {
  MenualID: string;
  created_at: Date;
  description: string;
  id: number;
  name: string;
  order: number;
  photo?: StrapiImage;
  published_at: Date;
  restaurant: number | RestaurantType
  updated_at: Date;
}

export type FoodType = {
  Active: boolean;
  Dairy: boolean;
  AddID: string;
  spicy: boolean;
  popular: boolean;
  ExtrasCount: string;
  OptionalsCount: string;
  Delivery: boolean;
  MenualID: string;
  NewProduct: boolean;
  Order: number;
  Takeout: boolean;
  Vegetarian: boolean;
  created_at: string;
  description: string;
  hasNotes: boolean;
  description_he: string;
  id: number;
  imageName?: string;
  imageUrl?: string;
  menu: null,
  Customized: CustomizedType,
  menu_category: FoodMenuCategory,
  name: string;
  name_he: string;
  photo?: StrapiImage,
  preparationTime: null,
  price: number;
  published_at: Date;
  restaurant: RestaurantType;
  soldcount?: number;
  updated_at: Date;
}

export type CartFoodType = FoodType & {
  qty: number;
}

export type CartObjType = {
  restaurant: { [key: string]: CartFoodType[] };
  food: { [key: string]: CartFoodType[] };
  restaurantInfo?: RestaurantType;
  open: boolean;
  shipping?: UserAddressType;
  payment: {
    [key: string]: {
      id: number;
      tip: number;
      amount: number;
      type: "coin" | 'money',
      card: string;
      balance: number;
      restaurant: string;
    }
  };
  notes: string;
  dinner: number;
  orderType: "delivery" | "takeout" | "scheduleddelivery" | "scheduledpickup",
  scheduledDate: string,
  AddedOrderShowAlert: false
}

export type OrderType = {
  address?: string | null;
  created_at: Date;
  curbside?: boolean;
  delivery?: boolean;
  id: number;
  cancel: boolean;
  status: OrderStatus;
  completed: boolean;
  bolepoDriver: boolean;
  driverAssigned: boolean;
  membersclub_balance_history: any;
  name: string;
  orderDate: Date;
  paid: boolean;
  paidWithCoins: boolean;
  phone: string;
  published_at: Date;
  restaurant: RestaurantType;
  scheduled?: boolean;
  takeout?: boolean;
  total: number;
  housedistancetime: number;
  preparationTime: number;
  orderType: "delivery" | "takeout" | "scheduleddelivery" | "scheduledpickup",
  updated_at: Date;
  verificationCode?: string;
  deliveryAddress: any;
  deliveryAddressText?: string;
  chat_agent: ChatType,
  scheduledDate: Date;
  pendingTime: Date,
  deliveryTime: Date,
  deliveredTime?: Date,
  driverAccepted: Date,
  driverName: string;
  driverId: string;
  driverPhone: string;
  approvedTime: Date,
  makingTime?: Date,
  pickedTime?: Date,
  readyTime?: Date,
  cancelTime?: Date,
  driverAssignedTime?: Date,
  duration?: number,
  users_permissions_user: StrapiUserCustomType;
  cartObj: CartObjType;
  driverLocation?: {
    distance: {
      text: string;
      value: number;
    };
    airDistance: {
      text: string;
      value: number;
    };
    duration: {
      text: string;
      value: number;
    };
    end_address: string;
  }
  paymentObj: {
    [key: string]: {
      id: number;
      tip: number;
      type: 'coin' | 'cash',
      amount: number;
      balance: number;
      restaurant: string;
    }
  },
  //For causing re rendering
  updatedAt: number;
}

export type MenuItemType = {
  "id": number;
  "menu": null,
  "name": "Tostadas",
  "description": "chicken, steak or tinga on crispy corn tortilla topped lettuce, sour cream, tomatoes, onions, avocado and cotija cheese",
  "price": 12.5,
  "published_at": "2021-01-01T19:11:55.491Z",
  "created_at": "2021-01-01T19:11:55.497Z",
  "updated_at": "2021-01-04T00:36:34.444Z",
  "restaurant": 38,
  "Takeout": true,
  "Delivery": true,
  "Vegetarian": false,
  "Active": true,
  "Dairy": false,
  "NewProduct": false,
  "MenualID": "921CB079-F5A3-4D6E-B35E-B602AFB03F6D",
  "Order": 18,
  "name_he": "",
  "description_he": "",
  "menu_category": MenuCategory,
  "soldcount": null,
  "imageName": "",
  "imageUrl": "https://menual.azurewebsites.net/items/921CB079-F5A3-4D6E-B35E-B602AFB03F6D.jpg",
  "preparationTime": null,
  "special": null,
  "photo": null
}

export type OrderItemType = {
  "id": 3,
  "order": {
    "id": 2,
    "verificationCode": null,
    "restaurant": 38,
    "takeout": null,
    "scheduled": null,
    "delivery": null,
    "curbside": null,
    "orderDate": "2021-02-28T08:03:49.596Z",
    "published_at": "2021-02-28T08:03:49.596Z",
    "created_at": "2021-02-28T08:03:49.606Z",
    "updated_at": "2021-02-28T08:03:49.619Z",
    "paidWithCoins": false,
    "membersclub_balance_history": null,
    "paid": false,
    "status": string;
    "name": "Juan Jose Lopez",
    "address": "Gabriel Leon Polanco 203",
    "phone": "+523121558080",
    "total": 27.45
  },
  "menu_item": MenuItemType;
  "price": 12.5,
  "quantity": 1,
  "restaurant": {
    "id": 38,
    "name": "Picante",
    "description": null,
    "LocationID": "ce85e10c-7486-40bf-b5b3-63b960142ee9",
    "created_at": "2020-12-22T06:04:05.923Z",
    "updated_at": "2021-01-07T07:45:05.546Z",
    "published_at": "2020-12-22T06:04:05.441Z",
    "address": "",
    "kosher": null,
    "facebook": null,
    "instagram": null,
    "lang": null,
    "telephone": "",
    "color": "#8b0000",
    "colorText": "#FFFFFF",
    "rate": null,
    "location": 1,
    "averageTime": null,
    "averagePrice": null,
    "currency": null,
    "tax": null,
    "logo": null,
    "photos": [],
    "featured": []
  },
  "published_at": "2021-02-28T08:03:49.662Z",
  "created_at": "2021-02-28T08:03:49.675Z",
  "updated_at": "2021-02-28T08:03:49.715Z",
  "extrasCost": 0,
  "optionalsCost": 0,
  "menu_extras": [
    {
      "id": 1763,
      "name": "Steak",
      "priceChange": 0,
      "restaurant": 38,
      "published_at": "2021-01-04T20:34:14.075Z",
      "created_at": "2021-01-04T20:34:14.081Z",
      "updated_at": "2021-01-04T20:34:14.092Z",
      "MenualID": "DDDDBD2A-B5FD-4815-AB38-A0609E1A6B7D",
      "name_he": null,
      "order": 0,
      "menu_item": 2899,
      "MenualOptionParentID": "15726144-0ABD-4150-9E4F-164204B68C93"
    }
  ],
  "menu_optional_items": [{
    "id": 1763,
    "name": "Steak",
    "priceChange": 0,
    "restaurant": 38,
    "menu_optional": 1678,
    "published_at": "2021-01-04T20:34:14.075Z",
    "created_at": "2021-01-04T20:34:14.081Z",
    "updated_at": "2021-01-04T20:34:14.092Z",
    "MenualID": "DDDDBD2A-B5FD-4815-AB38-A0609E1A6B7D",
    "name_he": null,
    "order": 0,
    "menu_item": 2899,
    "MenualOptionParentID": "15726144-0ABD-4150-9E4F-164204B68C93"
  }]
}

type InitiaStateType = {
  data: OrderType[],
  fetching: boolean;
  pendingOrders: OrderType[],
  fetchingPending: boolean;
  newestPendingOrders: OrderType[]
}
const initialState: InitiaStateType = {
  data: [],
  fetching: false,
  pendingOrders: [],
  fetchingPending: false,
  newestPendingOrders: []
};
const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    //Fecth device info
    builder.addCase(getOrders.pending, (state) => {
      state.fetching = true;

    });
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.fetching = false;
      console.log('getOrders.fulfilled', action.payload)
      state.data = [...action.payload]

    });
    builder.addCase(getOrders.rejected, (state, action) => {
      state.fetching = false;

    });
    builder.addCase(getPendingOrders.pending, (state) => {
      state.fetchingPending = true;
    });
    builder.addCase(getPendingOrders.fulfilled, (state, action: PayloadAction<OrderType[]>) => {
      state.fetchingPending = false;
      state.pendingOrders = action.payload;
      state.newestPendingOrders = action.payload;
    });
    builder.addCase(getPendingOrders.rejected, (state, action) => {
      state.fetchingPending = false;
    });
  }

})


export default orderSlice
import React from 'react'
import Switch from "react-switch";
import i18n from '../../../i18n';
import { operationType } from '../Config';
type DayFormType = {
    setDayData: any,
    dayData: operationType,
    dayLabel: string,
    id: string,
}
export const DayForm = ({ setDayData, dayData, dayLabel, id }: DayFormType) => {
    return (
        <div>
            <h6 style={{
                fontWeight : 700
            }}>{dayLabel}</h6>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <label>
                            <span>{i18n.t('is-closed-label')}</span>
                        </label>
                        <Switch
                            onChange={() => {
                                console.log("dayData", dayData);
                                let dayDataCopy = Object.assign({}, dayData)
                                dayDataCopy.isClosed = !dayDataCopy.isClosed
                                setDayData(dayDataCopy)
                            }}
                            checked={dayData.isClosed} />
                    </div>
                </div>
                <div className="col">
                    <label htmlFor={`${dayData.order}OpenTime`} className="form-label">{i18n.t('open-time')}</label>
                    <input
                        disabled={dayData.isClosed === true}
                        type="text"
                        name={`${id || "x"}OpenTime`}
                        onChange={(e) => {
                            console.log("dayData", dayData);
                            let dayDataCopy = Object.assign({}, dayData)
                            dayDataCopy.openTime = e.target.value
                            setDayData(dayDataCopy)
                        }}
                        defaultValue={dayData.openTime} className="form-control" id={`${dayData.order}OpenTime`} />
                </div>
                <div className="col">
                    <label htmlFor={`${id || "x"}CloseTime`} className="form-label">{i18n.t('close-time')}</label>
                    <input
                        disabled={dayData.isClosed === true}
                        onChange={(e) => {
                            console.log("dayData", dayData);
                            let dayDataCopy = Object.assign({}, dayData)
                            dayDataCopy.closeTime = e.target.value
                            setDayData(dayDataCopy)
                        }}
                        value={dayData.closeTime}
                        type="text" name={`${id || "x"}CloseTime`}
                        defaultValue={""}
                        className="form-control"
                        id={`${id || "x"}CloseTime`} />
                </div>
            </div>
        </div>
    )
}


import React from 'react'

const LabelSection = ({ value, label }: { value: string; label: string; }) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      marginTop : 10,
      marginBottom : 10
    }}>
      <div style={{
        flex: 1,
        display: 'flex'
      }}>
        <span style={{
          fontSize : 16
        }}>{label}</span>
      </div>
      <div style={{
        flex: 1,
        display: 'flex'
      }}>
        <span style={{
          fontFamily: 'Bold',
          fontSize : 16
        }}>{value}</span>
      </div>
      
    </div>
  )
}

export default LabelSection
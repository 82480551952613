import React from 'react'
import { useSelector } from 'react-redux'
import i18n from './i18n'
import { RootState } from './configureStore'

const ClosedAlert = () => {

    const restaurant = useSelector((state: RootState) => state.restaurant)
    if(!restaurant.isClosed){
        return null
    }
    return (
        <div  className="alert alert-danger" style={{
            textAlign : 'center'
        }}>
            <h1>{i18n.t("confirm-action-closed")}</h1>
        </div>
    )
}

export default ClosedAlert

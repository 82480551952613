import moment from 'moment'

import React, { useEffect, useState, useReducer, useRef, useCallback, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../configureStore'
import i18n from '../i18n'
import { OrderStatus, OrderType } from '../redux/orderSlice'
import { RestaurantType } from '../redux/restaurantSlice'
import { api } from '../utils/api'
import Constants from '../Constants'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import io from 'socket.io-client'
import dayjs, { Dayjs } from 'dayjs'
import { MabaliColors } from '../utils/Colors'
import { useQuery } from '../utils/utils'
import 'moment/dist/locale/he'
import * as stringSimilarity from 'string-similarity';
import OrdersTableSection from './OrdersTableSection'

import _debounce from 'lodash/debounce';
import { OrdersContext } from '../providers/OrdersProvider'
moment.locale('he')

// Hook
function usePrevious(value: any): any {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

let socket: any = null

let timeoutOrders: any = null

const DatePickerFC = ({ onDateChange }: { onDateChange: (date: Dayjs) => void }) => {
    const [startDate, setStartDate] = useState(new Date());
    useEffect(() => {

        onDateChange(dayjs(startDate))
    }, [startDate])

    return (
        <DatePicker locale={'he'} dateFormat='dd MMMM yyyy ' selected={startDate} onChange={(date: any) => setStartDate(date)} />
    );
};


const Orders = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const order = useSelector((state: RootState) => state.order)
    // const [Orders, setOrders] = useState<OrderType[]>([])

    const prevOrders: OrderType[] = usePrevious(order.data)
    const [SearchText, setSearchText] = useState<string>("");

    const [DriverLocations, setDriverLocations] = useState<any>([])
    const restaurant: RestaurantType = useSelector((state: RootState) => state.restaurant)
    const StatusTypeMap = order.data.reduce((map: { [key: string | number]: OrderStatus }, order) => {
        map[order.id] = order.status;
        return map
    }, {})

    const orderContext = useContext(OrdersContext);



    const [StatusType, setStatusType] = useState<{ [key: string | number]: OrderStatus }>(StatusTypeMap)

    const [DriverConfig, setDriverConfig] = useState<{ minutesForFee: number, minutesForReward: number } | undefined>()
    const query = useQuery();

    const dateParam = query.get('date')
    const [DateSearch, setDateSearch] = useState<Dayjs | null>(dateParam ? dayjs(dateParam) : dayjs())

    useEffect(() => {
        setStatusType(StatusTypeMap)
    }, [order.data]);

    const getDriverConfig = () => {
        api.get('/driver-config')
            .then(({ data }) => {
                setDriverConfig(data)
            })
            .catch(e => {
                console.warn("could not get config", e)
            })
    }

    useEffect(() => {

        getDriverConfig();
    }, []);

    const fetchOrders = useCallback((() => {
        orderContext.fetchOrders(restaurant, DateSearch);
        orderContext.fetchPendingOrders(restaurant);

        if (timeoutOrders) {
            clearTimeout(timeoutOrders)
        }

        timeoutOrders = setTimeout(() => {
            fetchOrders()
        }, 10000)
    }), [restaurant, DateSearch]);


    useEffect(() => {
        if (restaurant && DateSearch) {
            fetchOrders()
        }
    }, [restaurant.id, DateSearch, restaurant, fetchOrders])

    useEffect(() => {

        socket = io(Constants.Socketio)
        return () => {
            socket.disconnect()
            clearTimeout(timeoutOrders)
        }
    }, [])



    const selectOptions: any = {
        "": i18n.t("all"),
        "pending": i18n.t('pending'),
        "approved": i18n.t('status-approved'),
        "making": i18n.t('status-making'),
        "ready": i18n.t('status-ready'),
        "delivered": i18n.t('status-delivered'),
        "pickedup": i18n.t('status-pickedup'),
        "rejected": i18n.t('rejected'),
        "canceled": i18n.t('canceled'),
        "lookingfordriver": i18n.t('status-lookingfordriver'),
    };

    const getOrderStatus = (status: string) => {
        switch (status) {
            case "approved":
                return i18n.t("status-approved")
            case "making":
                return i18n.t("status-making")
            case "ready":
                return i18n.t("status-ready")
            case "delivered":
                return i18n.t("status-delivered")
            case "pickedup":
                return i18n.t("status-pickedup")
            case "rejected":
                return i18n.t("status-rejected")
            case "lookingfordriver":
                return i18n.t("status-lookingfordriver")
            case "canceled":
                return i18n.t("canceled")
            default:
                return i18n.t("status-pending")
        }
    }

    const getOrderType = (status: string) => {
        switch (status) {
            case "delivery":
                return i18n.t("order-type-delivery")
            case "takeout":
                return i18n.t("order-type-takeout")
            case "scheduleddelivery":
                return i18n.t("order-type-scheduledpickup")
            case "scheduledpickup":
                return i18n.t("order-type-scheduleddelivery")
            default:
                return i18n.t("order-type-delivery")
        }
    }


    const [{ driverReducer }, internalDispatch] = useReducer((state: any, action: any) => {
        if (action.type === 'USER_LOCATION') {

            let driverLocation = JSON.parse(action.message)

            //state.driverReducer.push()
            let tempDrivers: any[] = JSON.parse(JSON.stringify(DriverLocations))
            let found: boolean = false
            tempDrivers.forEach((tempDriver) => {

                if (tempDriver.id == driverLocation.id) {
                    tempDriver = driverLocation

                    found = true
                }
            })
            if (!found) {
                tempDrivers.push(driverLocation)
            }
            setDriverLocations(tempDrivers)

            state.driverReducer = tempDrivers
            return state
        }
        return state
    }, { driverReducer: [] })



    useEffect(() => {

        prevOrders && prevOrders.forEach((order) => {
            //console.log("removeOrder", order.id)
            socket.removeListener('/order/' + order.id + '/track')
        })

        order.data.forEach((order: any) => {
            if (order.status == 'approved' || order.status == 'canceled')
                return
            //console.log('/order/' + order.id + '/track')
            socket.on('/order/' + order.id + '/track', (data: any) => {


                internalDispatch({ type: 'USER_LOCATION', message: data })


            })
        })
        setDriverLocations([])
    }, [order.data])


    const onOrderStatusChange = (orderId: string | number, status: OrderStatus) => {
        console.log('orderId', orderId)
        console.log('status', status)
        if (window.confirm('עדכן מצב')) {
            api.put(`/orders/${orderId}/status`, {
                status
            })
                .then(({ data }) => {
                    console.log("updated Successfully", data)
                    orderContext.fetchOrdersCount(restaurant);
                    fetchOrders();
                })
                .catch((e) => {
                    console.warn("error updating")
                })
        }
    }

    const applySearchFilter = useCallback((order: OrderType) => {

        const nameSimilarity = stringSimilarity.compareTwoStrings(order.name, SearchText)
        const firstNameSimilarity = stringSimilarity.compareTwoStrings(order.users_permissions_user.firstName, SearchText)
        const SIMILARITY_TRESHOLD = 0.5


        if (!SearchText
            || `${order.id}` === SearchText
            || firstNameSimilarity >= SIMILARITY_TRESHOLD
            || nameSimilarity >= SIMILARITY_TRESHOLD
        )
            return true;
        return false

    }, [SearchText])

    const ordersInKitchen = useMemo(() => {
        const updatedAt = Date.now();
        return order.data.filter(applySearchFilter)
            .filter(ord => ord.driverAssigned || !ord.bolepoDriver)
            .filter(ord => {
                switch (ord.status) {
                    case 'making':
                    case 'approved':
                    case 'pickedup':
                    case 'ready':
                        return true;
                    default:
                        return false;
                }
            }).map((ord) => {
                return {
                    ...ord,
                    updatedAt,
                }
            })
    }, [order, applySearchFilter]);


    console.log('ordersInKitchen', ordersInKitchen)
    return (
        <div className="container" style={{
            marginTop: 10,

        }}>
            <div style={{
                width: "100%",
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                <div>
                    <button onClick={() => {
                        if (orderContext.orderCount > 0)
                            history.push('/new-orders')
                    }} className='btn' style={{
                        fontFamily: 'Bold',
                        height: 86,
                        width: 302,
                        borderRadius: 10,
                        backgroundColor: MabaliColors.lightBlue
                    }} >
                        {(String)(`${orderContext.orderCount} הזמנות חדשות`)}
                    </button>
                </div>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    marginRight: 20,
                    marginLeft: 20,
                }}>
                    <img src="/images/search-icon.png" style={{
                        height: 30,
                        margin: 5
                    }} alt="Calendar" />
                    <input className='form-control' onChange={(e) => {
                        setSearchText(e.target.value);
                    }} style={{
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderRadius: 0,
                        height: 33,
                        borderBottomWidth: 1,
                        borderBottomColor: MabaliColors.gray,
                        textDecorationLine: 'underline',

                        borderBottom: '1px solid ' + MabaliColors.lightGray

                    }} placeholder='חיפוש הזמנה' />
                </div>

                <div>
                    <div>
                        <span>
                            בחר תאריך
                        </span>
                        <img src="/images/calendar-icon.png" style={{
                            height: 30,
                            margin: 5
                        }} alt="Calendar" />
                    </div>
                    <DatePickerFC onDateChange={setDateSearch} />
                </div>
            </div>



            <OrdersTableSection
                title='הזמנות ממתינות לשליח'
                Orders={order.data.filter(applySearchFilter)
                    .filter(ord => ord.bolepoDriver)
                    .filter(ord => !ord.driverAssigned)
                    .filter(ord => !ord.cancel)
                    // .filter(ord => ord.status == 'pending')
                }

                StatusType={StatusType}
                style={{
                    marginTop: 20
                }}
                selectOptions={selectOptions}
                getOrderStatus={getOrderStatus}
                getOrderType={getOrderType}
                restaurant={restaurant}
                onSave={onOrderStatusChange}
                setStatusType={setStatusType}
                DriverConfig={DriverConfig} />

            <OrdersTableSection
                style={{
                    marginTop: 50
                }}
                title='הזמנות במטבח'
                Orders={ordersInKitchen}
                StatusType={StatusType}
                selectOptions={selectOptions}
                getOrderStatus={getOrderStatus}
                getOrderType={getOrderType}
                restaurant={restaurant}
                onSave={onOrderStatusChange}
                setStatusType={setStatusType}
                DriverConfig={DriverConfig} />

            <OrdersTableSection
                style={{
                    marginTop: 50
                }}
                title='הזמנות שהסתיימו'
                Orders={order.data.filter(applySearchFilter).filter(ord => (ord.status === 'completed' || ord.status === 'delivered'))}
                StatusType={StatusType}
                selectOptions={selectOptions}
                getOrderStatus={getOrderStatus}
                getOrderType={getOrderType}
                restaurant={restaurant}
                onSave={onOrderStatusChange}
                setStatusType={setStatusType}
                DriverConfig={DriverConfig} />



            <style>
                {`
                    .hoverable {
                        cursor:pointer;
                    }
                    .hoverable:hover * {
                        background-color : #333;
                        color : #fff;
                    }
                `}
            </style>
        </div >
    )
}

export default Orders

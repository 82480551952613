import { Dayjs } from 'dayjs';
import qs from 'qs';
import QueryString from 'qs'
import { createContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../configureStore';
import { getOrders, getPendingOrders, OrderType } from '../redux/orderSlice';
import { RestaurantType } from '../redux/restaurantSlice';
import { api } from '../utils/api';

export const OrdersContext = createContext({
  orderCount: 0,
  orders: [] as OrderType[],
  pendingOrders: [] as OrderType[],
  fetchOrdersCount: (restaurant: RestaurantType) => { },
  fetchOrders: (restaurant: RestaurantType, DateSearch: Dayjs | null) => { },
  fetchPendingOrders: (restaurant: RestaurantType) => { },
});



export const OrdersProvider = ({ children }: { children: JSX.Element }) => {
  const auth = useSelector((state: RootState) => state.auth)
  const [ordersCount, setOrdersCount] = useState<number>(0);
  const [orders, setOrders] = useState<OrderType[]>([]);
  const [pendingOrders, setPendingOrders] = useState<OrderType[]>([]);
  const dispatch = useDispatch();

  const fetchOrdersCount = (restaurant: RestaurantType) => {
    console.log('fetchOrdersCount')
    const query = qs.stringify({
      _where: [{ restaurant: restaurant.id }, { status: "pending" }]
    })
    if (auth.jwt)
      api.get(`/orders/count?${query}&cancel=false`)
        .then(({ data }) => {
          setOrdersCount(data)
        })
  }

  const fetchOrders = (restaurant: RestaurantType, DateSearch: Dayjs | null) => {
    const query = QueryString.stringify({
      _where: [{ restaurant: restaurant?.id }],
    });
    let startDate = DateSearch!.startOf('day').format("YYYY-MM-DD HH:mm:ss")
    let endDate = DateSearch!.endOf('day').format("YYYY-MM-DD HH:mm:ss")
    dispatch(getOrders(`orders?${query}&orderDate_gte=${startDate}&orderDate_lte=${endDate}&_sort=created_at:DESC`))




  }

  const fetchPendingOrders = (restaurant: RestaurantType) => {
    dispatch(getPendingOrders({
      restaurant: restaurant.id
    }))
  }

  return <OrdersContext.Provider value={{
    orderCount: ordersCount,
    orders: orders,
    pendingOrders,
    fetchOrdersCount,
    fetchOrders,
    fetchPendingOrders,
  }}>
    {children}
  </OrdersContext.Provider>

}
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../configureStore'
import { Switch, useHistory, useLocation } from "react-router-dom";
import TopBar from './containers/TopBar';
import { getMe, setUserData } from '../redux/authSlice';

import {
    Route
} from "react-router-dom";
import Orders from './Orders';
import NewOrders from './NewOrders';
import OrderChat from './OrderChat/OrderChat';
import Menu from './Menu';
import OrderDetails from './OrderDetails';
import MenuItemDetails from './MenuItemDetails';
import MenuCategories from './MenuCategories';
import Config from './Config';
import PendingOrderAlert from './Config/components/PendingOrderAlert';
import ClosedAlert from '../ClosedAlert';
import dayjs from 'dayjs';
import i18n from '../i18n';
import { clearJwtToken } from '../utils/api';
import OrdersHistory from './OrdersHistory';
import { OrdersProvider } from '../providers/OrdersProvider';

require('dayjs/locale/he')

//@ts-ignore
dayjs.locale(i18n.getLang())

const Main = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const auth = useSelector((state: RootState) => state.auth)
    const location = useLocation()

    useEffect(() => {
        if (auth.jwt) {
            console.log("getMe")
            dispatch(getMe())
        }
    }, [dispatch, auth.jwt])

    useEffect(() => {
        if (location.pathname != "/login" && (!auth.jwt || auth.code === 401)) {
            clearJwtToken()
            dispatch(setUserData({}))
            history.push("/login")
        }

    }, [history, auth.jwt, auth.code])
    return (
        <OrdersProvider>
            <div>

                <TopBar />
                <PendingOrderAlert />
                <ClosedAlert />
                <Switch>
                    <Route exact path="/order/chat/:id" component={OrderChat} />
                    <Route exact path="/new-orders" component={NewOrders} />
                    <Route exact path="/orders" component={Orders} />
                    <Route exact path="/orders-history" component={OrdersHistory} />


                    <Route exact path="/menu" component={MenuCategories} />
                    <Route exact path="/menu/:itemId" component={MenuItemDetails} />
                    <Route exact path="/config" component={Config} />
                    <Route exact path="/orders/:orderId" component={OrderDetails} />
                </Switch>
            </div>
        </OrdersProvider >
    )
}

export default Main

import React, { useState, useEffect } from 'react'
import RestaurantConfig from './components/RestaurantConfig'
import TakeoutHoursConfig from './components/TakeoutHoursConfig'
import DeliveryHoursConfig from './components/DeliveryHoursConfig'
import Switch from "react-switch";
import { getRestaurantOperationTimes, saveOperationTime } from '../../redux/restaurantSlice';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../configureStore'
import { DayForm } from './components/DayForm';
import i18n from '../../i18n'
import CloseNow from './components/CloseNow';
import OperationTimeForm from './OperationTimeForm';
import StoriesMember from './StoriesMember';

export type operationType = {
    isClosed: boolean,
    openTime: string,
    closeTime: string,
    order: number
}
const Config = () => {
    const dispatch = useDispatch();

    const restaurant = useSelector((state: RootState) => state.restaurant)


    useEffect(() => {
        console.log("getRestaurantOperationTimes", restaurant.id);

        dispatch(getRestaurantOperationTimes({ restaurant: restaurant.id }))


    }, [restaurant.id])


    return (
        <div className="container shadow" style={{
            padding: 20
        }}>
            <div className="d-flex align-items-start">
                <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{

                }}>
                    <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">{i18n.t("business-tab")}</button>
                    <button className="nav-link" id="v-pills-operation-times-tab" data-bs-toggle="pill" data-bs-target="#v-pills-operation-times" type="button" role="tab" aria-controls="v-pills-operation-times" aria-selected="false">{i18n.t("operation-time")}</button>
                    <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">{i18n.t("takeout-delivery-pill")}</button>
                    {/* <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">{i18n.t("delivery-pill")}</button> */}
                    <button className="nav-link"
                        id="v-pills-close-now-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-close-now" type="button"
                        role="tab" aria-controls="v-pills-close-now"
                        aria-selected="false">{i18n.t("close-now")}</button>
                    <button className="nav-link"
                        id="v-pills-close-now-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-stories-members" type="button"
                        role="tab" aria-controls="v-pills-stories-members"
                        aria-selected="false">{i18n.t("manager-members")}</button>
                    {/* <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Settings</button> */}
                </div>
                <div className="tab-content" id="v-pills-tabContent">
                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <RestaurantConfig />
                    </div>
                    <div className="tab-pane fade" id="v-pills-operation-times" role="tabpanel" aria-labelledby="v-pills-operation-times-tab">
                        <OperationTimeForm restaurant={restaurant} />
                    </div>
                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                        <TakeoutHoursConfig />
                    </div>
                    {/* <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <DeliveryHoursConfig />
                    </div> */}
                    <div className="tab-pane fade" id="v-pills-close-now" role="tabpanel" aria-labelledby="v-pills-close-now-tab">
                        <CloseNow />
                    </div>
                    <div className="tab-pane fade" id="v-pills-stories-members" role="tabpanel" aria-labelledby="v-pills-close-now-tab">
                        <StoriesMember />
                    </div>
                    {/* <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">...</div> */}
                </div>
            </div>
        </div>
    )
}

export default Config

import dayjs from 'dayjs'
import React, { useState } from 'react'
import i18n from '../../i18n'
import { OrderItemType, OrderType } from '../../redux/orderSlice'
import numeral from 'numeral'

const ItemStickers = ({ orderItems, Order, OrderPayments }: { orderItems: Array<OrderItemType>, Order: OrderType | null, OrderPayments: any }) => {
    const [showNotes, setShowNotes] = useState(false)
    const panelText = (string: any, ...args: any) => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }} className="fw-bold text-center">
                    {/*@ts-ignore*/}
                    {i18n.t(string).format("")}
                </span>
                {args}
            </span>)
    }
    return (
        <div style={{ direction: 'rtl', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <table style={{ textAlign: 'center' }} className="table-ticket table table-bordered">
                <tbody>
                {
                    orderItems != null && orderItems.map((item: OrderItemType, index: any) => {
                        let menuItem: any = item.menu_item
                        return <tr key={index} style={{ border: '1px solid', padding: 10 }}>
                                <td>
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} className="">
                                        <div>
                                            {/*@ts-ignore*/}
                                            <span><span style={{ fontWeight: 'bold' }}>{i18n.t("order-details-order-id").format("")}</span> {Order?.id} </span>
                                            {/*@ts-ignore*/}
                                            <span><span style={{ fontWeight: 'bold' }}>{i18n.t("item-dots")}</span> {menuItem["name_" + i18n.getLang()] || menuItem.name} </span>
                                        </div>
                                        <div>
                                            {/*@ts-ignore*/}
                                            {item.menu_extras.map((extra: any, index) => {
                                                return <span key={index}>
                                                    {index == 0 && <span style={{ fontWeight: 'bold' }}>
                                                        {i18n.t("extras-dots")}
                                                    </span>}
                                                    {/*@ts-ignore*/}
                                                    <span>{extra["name_" + i18n.getLang()] || extra.name}
                                                        <span>
                                                            {/*@ts-ignore*/}
                                                            {Order?.restaurant.currency || "$"}{numeral(extra.priceChange).format("0,0.0")}
                                                        </span>
                                                    </span>
                                                </span>
                                            })}
                                        </div>
                                        <div>
                                            {/*@ts-ignore*/}
                                            <span>
                                                {/*@ts-ignore*/}
                                                {Order?.cartObj.restaurant[Order.restaurant.id].map((itemCustom: any, index: number) => {
                                                    if (itemCustom.id != item.menu_item.id) {
                                                        return null
                                                    }
                                                    let Notes = []
                                                    itemCustom?.Customized?.MultiOptions.forEach((multi: any, index: number) => {
                                                        Notes.push(<div key={index}>
                                                            {index == 0 && <span style={{ fontWeight: 'bold' }}>{i18n.t("notes-and-selections-dots")}</span>}
                                                            <span style={{
                                                                fontFamily: "Bold"
                                                            }}>({multi.qty})</span> - <span style={{
                                                                fontWeight: 700
                                                            }}>
                                                                {/*@ts-ignore*/}
                                                            </span>{multi["name_" + i18n.getLang()] || multi.name}</div>)
                                                    })
                                                    if (itemCustom.Customized?.Notes?.length > 0) {
                                                        Notes?.push(<li key={Notes.length}>
                                                            <span>{itemCustom.Customized?.Notes}</span>
                                                        </li>)
                                                    }
                                                    return Notes
                                                })}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                      
                    })
                }
                </tbody>
            </table >
            <style>
                {`
                    .table-ticket, th, td {
                        border: 1px solid black;
                        border-collapse: collapse;
                  }
                `}
            </style>
        </div >
    )
}

export default ItemStickers

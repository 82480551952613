import dayjs from 'dayjs'
import numeral from 'numeral'
import React, { useState } from 'react'
import i18n from '../../i18n'
import { MenuItemType, OrderItemType, OrderType } from '../../redux/orderSlice'
import { Color } from '../../utils/Colors'
import BusinessCoin from '../../assets/images/business-coin.png'
import BolepoCoin from '../../assets/images/bolepo-coin.png'
const MainTicket = ({ orderItems, Order, OrderPayments }: { orderItems: Array<OrderItemType>, Order: OrderType | null, OrderPayments: any }) => {
    const [renderSummaryRows, setrenderSummaryRows] = useState(false)
    const getOrderType = (order: OrderType | any) => {
        if (order.delivery) {
            return i18n.t("order-type-delivery")
        } else if (order.delivery) {
            return i18n.t("order-type-takeout")
        } else if (order.scheduled) {
            return i18n.t("order-type-scheduled")
        }
    }
    const panelText = (string: any, ...args: any) => {
        return (
            <span>
                <span style={{ fontSize: 10, fontWeight: 'bold' }} className="fw-bold text-center">
                    {/*@ts-ignore*/}
                    {i18n.t(string).format("")}
                </span>
                {args}
            </span>)
    }
    const CardPayment = ({ text, value }: { text: any, value: any }) => {
        return <li className="list-group-item d-flex justify-content-between lh-condensed">
            <div className="d-flex flex-row align-items-center">
                <img src={"/images/24756.png"} alt="Credit card" style={{
                    height: 20,
                    color: Color.bolepoBlue,
                    marginLeft: 10,
                    marginRight: 10
                }} />
                <h6 className="my-0">****{text}</h6>
            </div>
            <span className="text-muted">{value}</span>
        </li>
    }
    const CoinPayment = ({ text, value, bolepo }: { text: any, value: any, bolepo: any }) => {
        return <li className="list-group-item d-flex justify-content-between lh-condensed">
            <div className="d-flex flex-row align-items-center">
                <img style={{ width: 30, margin: 5 }} src={bolepo ? BolepoCoin : BusinessCoin} />
                <h6 className="my-0">{text}</h6>
            </div>
            <span className="text-muted">{value}</span>
        </li>
    }



    return (
        <div style={{ fontSize: 10, height: "auto", borderStyle: "dotted", borderRight: 0, borderLeft: 0, paddingTop: 30, paddingBottom: 20 }}>
            <div style={{ direction: 'rtl', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }} className="text-center d-flex justify-content-center flex-column">
                    {/*@ts-ignore*/}
                    <span className="fw-bold text-center" style={{ fontSize: 10, fontWeight: 'bold' }}>{i18n.t("ticket-title").format(Order?.restaurant?.name)}</span>
                    {/*@ts-ignore*/}
                    <span className="fw-bold text-center" style={{ fontSize: 10, fontWeight: 'bold' }}>{i18n.t("ticket-approved-title").format(Order?.restaurant?.name, "MAIN")}</span>
                    {/*@ts-ignore*/}
                    <span><span className="fw-bold text-center" style={{ fontSize: 10, fontWeight: 'bold' }}>{i18n.t("date-and-time")}</span>{Order.created_at ? dayjs(Order?.created_at).format("DD/MM/YYYY HH:mm") : i18n.t("not-yet")}</span>
                    {/*@ts-ignore*/}
                    <span><span className="fw-bold text-center" style={{ fontSize: 10, fontWeight: 'bold' }}>{i18n.t("order-details-order-id").format("")}</span>{Order?.id} <span className="fw-bold text-center" style={{ fontSize: 10, fontWeight: 'bold' }}>{i18n.t("order-details-order-type").format("")}</span>{getOrderType(Order)}</span>
                    {/*@ts-ignore*/}
                    <span><span className="fw-bold text-center" style={{ fontSize: 10, fontWeight: 'bold' }}>{i18n.t("order-details-order-customer").format("")}</span>{(Order.users_permissions_user.firstName + " " + Order.users_permissions_user.lastName)}</span>
                    {/*@ts-ignore*/}
                    {panelText("order-details-order-phone", (Order?.users_permissions_user?.phone || i18n.t("not-specified")))}
                    {/*@ts-ignore*/}
                    {Order.deliveryAddressText && panelText("order-details-order-address", (Order.deliveryAddressText))}
                    {/*@ts-ignore*/}
                    {panelText("order-details-order-notes", (Order.notes || i18n.t("not-specified")))}

                </div>
                <table className="table-ticket table table-bordered">
                    <thead>
                        <tr>
                            <th style={{ fontSize: 10 }}>{i18n.t('item-label')}</th>
                            <th style={{ fontSize: 10 }}>{i18n.t('extras')}</th>
                            <th style={{ fontSize: 10 }}>{i18n.t('notes-and-selections')}</th>
                            <th style={{ fontSize: 10 }}>{i18n.t('total-price')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderItems != null && orderItems.map((item: OrderItemType, index: any) => {
                                let menuItem: any = item.menu_item
                                let extraRows = null

                                let trRows = []

                                trRows.push(<tr key={index}>
                                    {/*@ts-ignore*/}
                                    <td>{menuItem["name_" + i18n.getLang()] || menuItem.name}</td>
                                    <td>
                                        {
                                            <ul>
                                                {item.menu_extras.map((extra: any, key) => {
                                                    {/*@ts-ignore*/ }
                                                    return <li style={{ fontSize: 10 }}>{extra["name_" + i18n.getLang()] || extra.name} <span>{Order?.restaurant.currency || "$"}{numeral(extra.priceChange).format("0,0.0")}</span></li>
                                                })}
                                            </ul>
                                        }
                                    </td>
                                    <td>{
                                        <ul style={{ fontSize: 10 }}>
                                            {/*@ts-ignore*/}
                                            {Order?.cartObj.restaurant[Order.restaurant.id].map((itemCustom: any, key) => {

                                                // console.log("itemCustom.id", itemCustom)
                                                // console.log("item.id", item)

                                                if (itemCustom.id != item.menu_item.id) {
                                                    return null
                                                }
                                                let Notes = []

                                                itemCustom?.Customized?.MultiOptions.forEach((multi: any) => {

                                                    Notes.push(<li key={Notes.length}>
                                                        <span style={{
                                                            fontFamily: "Bold"
                                                        }}>({multi.qty})</span> - <span style={{
                                                            fontWeight: 700
                                                        }}>
                                                            {/*@ts-ignore*/}
                                                        </span>{multi["name_" + i18n.getLang()] || multi.name}
                                                    </li>)
                                                })
                                                if (itemCustom.Customized?.Notes?.length > 0) {
                                                    Notes?.push(<li key={Notes.length}>
                                                        <span>{itemCustom.Customized?.Notes}</span>
                                                    </li>)
                                                }
                                                return Notes

                                            })}
                                        </ul>
                                    }</td>
                                    <td><span>{Order?.restaurant.currency || "₪"}{numeral(menuItem.price).format("0,0.0")}</span></td>
                                </tr>)
                                if (orderItems.length == (index + 1)) {
                                    trRows.push(<tr key={100} style={{ borderTop: "2px solid" }}>
                                        <td><span style={{ fontSize: 10, fontWeight: 'bold' }} >{i18n.t("delivery-fee")}</span></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>)
                                    trRows.push(<tr key={200}>
                                        <td><span style={{ fontSize: 10, fontWeight: 'bold' }} >{i18n.t("online-tip")}</span></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>)
                                    trRows.push(<tr key={300}>
                                        <td><span style={{ fontSize: 10, fontWeight: 'bold' }} >{i18n.t("subtotal")}</span></td>
                                        <td></td>
                                        <td></td>
                                        <td>{Order?.restaurant.currency || "$"}{numeral(Order?.total).format("0,0.0")}</td>
                                    </tr>);
                                }

                                return trRows


                            })

                        }
                    </tbody>
                </table>
                <div style={{ marginTop: 10, textAlign: 'center' }}>
                    {panelText("paid-with")}
                    {/* <CardPayment key={index} text={method.payment.cardnumber} value={method.amount} /> */}
                    {OrderPayments.map((method: any, index: any) => {
                        if (!method.payment) {
                            return null
                        }
                        if (method.type == "card")
                            return <span>{"Card "}<span style={{ fontSize: 10, fontWeight: 'bold' }}>{"****" + method.payment.cardnumber}</span></span>
                        if (method.type == "coin" && method.payment.restaurant)
                            return <span>{method.payment.restaurant ? "Bolepo Coins" : "Business Coins"}<span style={{ fontSize: 10, fontWeight: 'bold' }}>{method.payment.restaurant.name}</span>{method.amount}</span>
                        return null
                    })}
                </div>
                <style>
                    {`
                    .table-ticket, th, td {
                        border: 1px solid black;
                        border-collapse: collapse;
                  }
                `}
                </style>
            </div>
        </div>
    )
}

export default MainTicket

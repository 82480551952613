import dayjs from 'dayjs'
import React from 'react'
import i18n from '../../i18n'
import { OrderItemType, OrderType } from '../../redux/orderSlice'
import { Color } from '../../utils/Colors'
import BusinessCoin from '../../assets/images/business-coin.png'
import BolepoCoin from '../../assets/images/bolepo-coin.png'
const DriverTicket = ({ orderItems, Order, OrderPayments }: { orderItems: Array<OrderItemType>, Order: OrderType | null, OrderPayments: any }) => {
    const getOrderType = (order: OrderType | any) => {
        if (order.delivery) {
            return i18n.t("order-type-delivery")
        } else if (order.delivery) {
            return i18n.t("order-type-takeout")
        } else if (order.scheduled) {
            return i18n.t("order-type-scheduled")
        }
    }
    const panelText = (string: any, ...args: any) => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }} className="fw-bold text-center">
                    {/*@ts-ignore*/}
                    {i18n.t(string).format("")}
                </span>
                {args}
            </span>)
    }
    const CardPayment = ({ text, value }: { text: any, value: any }) => {
        return <li className="list-group-item d-flex justify-content-between lh-condensed">
            <div className="d-flex flex-row align-items-center">
                <img src={"/images/24756.png"} alt="Credit card" style={{
                    height: 20,
                    color: Color.bolepoBlue,
                    marginLeft: 10,
                    marginRight: 10
                }} />
                <h6 className="my-0">****{text}</h6>
            </div>
            <span className="text-muted">{value}</span>
        </li>
    }
    const CoinPayment = ({ text, value, bolepo }: { text: any, value: any, bolepo: any }) => {
        return <li className="list-group-item d-flex justify-content-between lh-condensed">
            <div className="d-flex flex-row align-items-center">
                <img style={{ width: 30, margin: 5 }} src={bolepo ? BolepoCoin : BusinessCoin} />
                <h6 className="my-0">{text}</h6>
            </div>
            <span className="text-muted">{value}</span>
        </li>
    }

    return (
        <div style={{ fontSize: 10, height: 'auto', paddingTop: 30, paddingBottom: 20, direction: 'rtl', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }} className="text-center d-flex justify-content-center flex-column">
                {/*@ts-ignore*/}
                <span className="fw-bold text-center" style={{ fontWeight: 'bold' }}>{i18n.t("ticket-title").format(Order?.restaurant?.name)}</span>
                {/*@ts-ignore*/}
                <span className="fw-bold text-center" style={{ fontWeight: 'bold' }}>{i18n.t("ticket-approved-title").format(Order?.restaurant?.name, "DELIVERY")}</span>
                {/*@ts-ignore*/}
                <span><span className="fw-bold text-center" style={{ fontWeight: 'bold' }}>{i18n.t("date-and-time")}</span>{Order.created_at ? dayjs(Order?.created_at).format("DD/MM/YYYY HH:mm") : i18n.t("not-yet")}</span>
                {/*@ts-ignore*/}
                <span><span className="fw-bold text-center" style={{ fontWeight: 'bold' }}>{i18n.t("order-details-order-id").format("")}</span>{Order?.id}</span>
                {/*@ts-ignore*/}
                <span><span className="fw-bold text-center" style={{ fontWeight: 'bold' }}>{i18n.t("order-details-order-customer").format("")}</span>{(Order.users_permissions_user.firstName + " " + Order.users_permissions_user.lastName)}</span>
                {/*@ts-ignore*/}
                {panelText("order-details-order-phone", (Order?.users_permissions_user?.phone || i18n.t("not-specified")))}
                {/*@ts-ignore*/}
                {Order.deliveryAddressText && panelText("order-details-order-address", (Order.deliveryAddressText))}
                {/*@ts-ignore*/}
                {panelText("order-details-order-notes", (Order.notes || i18n.t("not-specified")))}
            </div>
            <table style={{ textAlign: 'center' }} className="table-ticket table table-bordered">
                <thead>
                    <tr>
                        <th>{i18n.t('item-label')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        orderItems != null && orderItems.map((item: OrderItemType, index: any) => {
                            let menuItem: any = item.menu_item
                            return <tr key={index}>
                                {/*@ts-ignore*/}
                                <td>{menuItem["name_" + i18n.getLang()] || menuItem.name}</td>
                            </tr>

                        })
                    }
                </tbody>
            </table>
            <style>
                {`
                    .table-ticket, th, td {
                        border: 1px solid black;
                        border-collapse: collapse;
                  }
                `}
            </style>
        </div>
    )
}

export default DriverTicket

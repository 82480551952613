import dayjs from 'dayjs'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { RootState } from '../configureStore'
import i18n from '../i18n'
import { OrderStatus, OrderType } from '../redux/orderSlice'
import { RestaurantType } from '../redux/restaurantSlice'
import { Color, MabaliColors } from '../utils/Colors'


const OrdersTableSection = ({
    Orders,
    selectOptions,
    StatusType,
    DriverConfig,
    getOrderType,
    getOrderStatus,
    restaurant,
    setStatusType,
    title,
    style,
    viewOnly,
    onSave
}:
    {
        Orders: OrderType[];
        selectOptions: any;
        StatusType: any;
        DriverConfig: any;
        getOrderType: (value: string) => string;
        getOrderStatus: (value: string) => string;
        restaurant: RestaurantType;
        setStatusType: (value: any) => void;
        title: string;
        style?: React.CSSProperties;
        viewOnly?: boolean;
        onSave?: (orderId: string | number, status: OrderStatus) => void;
    }) => {
    const history = useHistory()
    const changeStatus = (orderId: string | number, status: string) => {
        const tempStatus = { ...StatusType }
        tempStatus[orderId] = status;
        setStatusType(tempStatus)
    }


    return (<div style={style}>
        <span style={{
            fontSize: 28,
            fontFamily: 'Bold',
            color: MabaliColors.pink
        }}>{title}</span>
        <table className="table">
            <thead>
                <tr style={{
                    fontFamily: 'Bold',
                    fontSize: 18,

                }}>
                    <th >
                        {/* orderId */}
                        מספר הזמנה
                    </th>
                    <th>
                        {/* Client name */}
                        שם מלא
                    </th>
                    <th>
                        {/* Phone */}
                        טלפון
                    </th>
                    <th>
                        {/* Preparation time */}
                        זמן הכנה
                    </th>
                    <th>
                        {/* Preparation time */}
                        זמן הגעה
                    </th>
                    <th>
                        {/* Driver type */}
                        מי השליח
                    </th>
                    <th>
                        {/* order status */}
                        סטטוס הזמנה
                        {/* <select className="form-select" onChange={(e) => setStatusType(e.target.value)} aria-label="Default select example">
                            {Object.keys(selectOptions).map((key, index) => {
                                let status = selectOptions[key]
                                return <option key={index} value={key}>{status}</option>
                            })}
                        </select> */}
                    </th>
                    <th>
                        {/*@ts-ignore*/}
                        {i18n.t("order-chat").format("")}
                    </th>
                    <th>
                        {/*@ts-ignore*/}
                        {i18n.t("order-type").format("")}
                    </th>

                    {/* <th>
                        {i18n.t("order-total")}
                    </th>
                    <th>
                        {i18n.t("date")}
                    </th>*/}
                    <th>
                        {i18n.t("driver-chat")}
                    </th>
                    {/*
                    <th>
                        {i18n.t("driver-timer").format("")}
                    </th> */}
                </tr>
            </thead>
            <tbody>
                {Orders.map((order: OrderType) => {

                    let className = ""
                    let arrivalTime;
                    console.log('order.deliveredTime', order.deliveredTime)
                    if (order.deliveredTime) {
                        arrivalTime = dayjs(order.deliveredTime).diff(dayjs(order.driverAssignedTime), 'minute')
                    }



                    if (!arrivalTime && order.driverAssignedTime) {
                        // Converting from seconds to minutes
                        arrivalTime = (((order.duration ?? 10) / 60) + 3) - dayjs().diff((order.driverAssignedTime), 'minute')
                    }

                    if (!arrivalTime) {
                        arrivalTime = 0
                    }

                    switch (order.status) {
                        case "canceled":
                            className = "table-secondary"
                            break;
                        case "pending":
                            className = "table-warning"
                            break;
                        case "completed":
                            className = "table-success"
                            break;
                    }

                    if (order.completed) {

                        className = "table-success"

                    }
                    const deliveryTime = order.deliveredTime ? dayjs(order.deliveredTime) : dayjs()
                    let driverTime: any = order.driverAccepted ? deliveryTime.diff(
                        (dayjs(order.driverAccepted)), 'minute') : "N/A"

                    if (!order.driverAccepted && order.bolepoDriver) {
                        //No driver assigned
                        driverTime = i18n.t('looking-for-driver')
                    }


                    return <tr className={className} key={order.id} style={{
                        "cursor": "pointer"
                    }} onClick={() => {
                        history.push("/orders/" + order.id, order)
                    }}>
                        <td>
                            {order.id}
                        </td>
                        <td>
                            {order.name}
                        </td>
                        <td>
                            {order.phone}
                        </td>
                        <td>
                            <span>
                                דקות {dayjs(order.readyTime || order.pickedTime || order.cancelTime).diff(order.makingTime, 'minute')}
                                (דקות {order.preparationTime})
                            </span>
                        </td>
                        <td>
                            <span style={{
                                color: arrivalTime < 0 ? 'red' : 'black',
                            }}>
                                {arrivalTime}
                                (דקות {Math.ceil((order.duration ?? 10) / 60)})
                            </span>
                        </td>
                        <td>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                                <span>{(i18n.t(order.bolepoDriver ? "bolepo-driver" : "business-driver"))}</span>
                                {order.bolepoDriver || true ? <span style={{
                                    fontWeight: 600,

                                }}>{order.status === 'making' ? order.driverLocation?.duration.text : null}</span> : null}
                            </div>
                        </td>
                        <td style={{
                            display: 'flex',

                        }} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                            {/* {getOrderStatus(order.status)} */}

                            {(!viewOnly && order.status !== StatusType[order.id]) ? <button onClick={() => {
                                onSave && onSave(order.id, StatusType[order.id]);
                            }} >{i18n.t('save')}</button> : null}
                            <select className="form-select" defaultValue={order.status} onChange={(e) => changeStatus(order.id, e.target.value)} aria-label="Default select example">
                                {Object.keys(selectOptions).map((key, index) => {
                                    let status = selectOptions[key]
                                    return <option key={index} value={key}>{status}</option>
                                })}
                            </select>



                        </td>
                        <td style={{
                            position: 'relative'
                        }}>
                            <Button disabled={!order.chat_agent} style={{
                                opacity: !order.chat_agent ? 0.3 : 1
                            }} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push("/order/chat/" + order.chat_agent.id)
                            }} >
                                {i18n.t("chat")}
                            </Button>
                            {order.chat_agent?.newMessageBy && order.chat_agent?.newMessageBy != `${restaurant.id}` ? <div style={{
                                backgroundColor: 'red',
                                width: 20,
                                height: 20,
                                borderRadius: 10,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                top: 5,
                                left: 5
                            }} >
                                <span style={{
                                    color: 'white'
                                }}>1</span>
                            </div> : null}
                        </td>
                        <td>
                            {getOrderType(order.orderType)}
                        </td>

                        {/* <td>
                            ₪{order.total}
                        </td>
                        <td>
                            {dayjs(order.orderDate).fromNow()}
                        </td> */}
                        <td style={{
                            position: 'relative'
                        }}>
                            <Button color="success" style={{
                                position: 'relative'
                            }} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push("/driver/chat/" + order.id + '?showClose=true')
                            }} >
                                {i18n.t("chat")}

                            </Button>
                            {order.chat_agent?.newDriverMessageBy && order.chat_agent?.newDriverMessageBy !== `${restaurant.id}` ? <div style={{
                                backgroundColor: 'red',
                                width: 20,
                                height: 20,
                                borderRadius: 10,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                top: 5,
                                left: 5
                            }} >
                                <span style={{
                                    color: 'white'
                                }}>1</span>
                            </div> : null}

                        </td>
                        {/* <td>
                            <span style={{
                                color: "#fff",
                                fontSize: 20,
                                backgroundColor: getTimeColor(driverTime),
                                padding: 5,
                                borderRadius: 4
                            }} >
                                {driverTime}
                            </span>
                        </td> */}
                    </tr>
                })}
            </tbody>
        </table>
        <style>
            {`
                th {
                    border-bottom: none;
                }
            `}
        </style>
    </div>
    )
}

export default OrdersTableSection
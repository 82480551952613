import React, { useState } from 'react'
import { MabaliColors } from '../../utils/Colors'

export enum DriverType {
  business = 'business',
  bolepo = 'bolepo'
}

const DriverSelection = ({ onPress }: { onPress: (driverType: DriverType) => void }) => {

  const [SelectedDriver, setSelectedDriver] = useState<DriverType>(DriverType.business);

  const DriverTypes = [{
    type: DriverType.business,
    title: 'שליח מסעדה'
  }, {
    type: DriverType.bolepo,
    title: 'שליח מבאלי'
  }]

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 2
    }}>

      {DriverTypes.map((driverType, index) => {
        const Selected = driverType.type == SelectedDriver
        return <div key={index + 100} onClick={() => {
          setSelectedDriver(driverType.type)
          onPress(driverType.type)
        }} style={{
          flex: 1,
          display: 'flex',
          border: `1px solid ${MabaliColors.pink}`,
          height: 65,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: Selected ? MabaliColors.pink : 'transparent',
          marginLeft: 1,
          marginRight: 1

        }}>
          <span style={{
            fontFamily: Selected ? 'Bold' : 'Regular'
          }}>{driverType.title}</span>
        </div>
      })}

    </div>
  )
}

export default DriverSelection